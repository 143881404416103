import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import MenuItem from './MenuItem'
import actions from '../../../../store/combineActions'

const mapStateToProps = state => ({
})

const mapDispatchToProps = dispatch => ({
  saveMenuTypeId: (type) => dispatch(actions.core.saveMenuTypeId(type)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuItem))
