import styled from 'styled-components'
import { Button as ButtonAntd } from 'antd'

import { convertColor } from '../../../utils'

export const Button = styled(ButtonAntd)`
  width: ${({ _width }) => _width};
  height: ${({ _height }) => _height};
  min-width: 12rem;
  border-radius: 0;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
  font-family: OpenSans;
  margin-top: ${({ _margintop }) => _margintop};
  margin-right: ${({ _marginright }) => _marginright};
  margin-bottom: ${({ _marginbottom }) => _marginbottom};
  margin-left: ${({ _marginleft }) => _marginleft};

  border-color: ${({ theme, ActionButtonsTextColor }) => ActionButtonsTextColor ? convertColor(ActionButtonsTextColor) : theme.colors.blue};
  border-width: .2rem;
  color: ${({ theme, ActionButtonsTextColor }) => ActionButtonsTextColor ? convertColor(ActionButtonsTextColor) : theme.colors.blue};
  background-color: ${({ theme, ActionButtonsBackgroundColor }) => ActionButtonsBackgroundColor ? convertColor(ActionButtonsBackgroundColor) : theme.colors.white};

  &:hover,
  &:focus {
    border-color: ${({ theme, ActionButtonsTextColor }) => ActionButtonsTextColor ? convertColor(ActionButtonsTextColor) : theme.colors.blue};
    color: ${({ theme, ActionButtonsTextColor }) => ActionButtonsTextColor ? convertColor(ActionButtonsTextColor) : theme.colors.blue};
    background-color: ${({ theme, ActionButtonsBackgroundColor }) => ActionButtonsBackgroundColor ? convertColor(ActionButtonsBackgroundColor) : theme.colors.white};
    opacity: .7;
  }

  [dir='rtl'] & {
    font-family: 'OpenSansHebrew';
  }

  ${props => props._noBorderRight && `
    border-right-width: .1rem;
  `}

  ${props => props._noBorderLeft && `
    border-left-width: .1rem;
  `}

  ${props => props._textCenter && `
    padding-right: 0;
    padding-left: 0;
    text-align: center;
  `}

  &.ant-btn-primary {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme, ActionButtonsTextColor }) => ActionButtonsTextColor ? convertColor(ActionButtonsTextColor) : theme.colors.blue};
  }
`
