import React from 'react'
import { Form as FormAntd } from 'antd'
import { useIntl } from 'react-intl'

import { Textarea } from '../..'

export default ({
  name = 'Message'
}) => {
  const intl = useIntl()

  return (
    <FormAntd.Item
      label={intl.formatMessage(
        {
          id: 'controls_label_message',
          defaultMessage: 'Message'
        },
      ) + ' *'}
      rules={[{ required: true, message: '' }]}
      name={name}
    >
      <Textarea />
    </FormAntd.Item>
  )
}
