// import { message } from 'antd'

import { api } from '../../services'
import {
  CORE_GET_PAGE_INFO,
  CORE_CHANGE_FETCHING,
  CORE_GET_PICKUP_POINTS,
  CORE_SET_TITLE,
  CORE_SET_TITLE_DEFAULT,
  CORE_SAVE_TYPE_MENU,
  CORE_SEND_PAYMENT,
  CORE_SHOW_FEEDBACK_MESSAGE,
} from './types'
import history from '../../history'
import { authenticate } from '../user/action'
import { getFromStore } from '..'

export const getPageInfo = (tid, pid, id) => async dispatch => {
  try {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: true
    })

    const result = await api.core.getPageInfo(tid, pid, id)

    dispatch({
      type: CORE_GET_PAGE_INFO,
      payload: result?.data
    })
  } catch(err) {
    if (err?.response?.status === 401) {
      dispatch(authenticate())
    }

    if (err?.response?.data?.Message === 'The appid claim was not found') {
      return setTimeout(() => {
        dispatch(getPageInfo(tid, pid, id))
        history.go(0)
      }, 2000)
    }
    else {
      return history.push('/')
    }

  } finally {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: false
    })
  }
}

export const setDeliveryDate = (tid, pid, data) => async dispatch => {
  try {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: true
    })

    await api.core.setDeliveryDate(tid, pid, data)

  } catch(err) {
  } finally {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: false
    })

    history.push(`/${tid}/${pid}`)
  }
}

export const getPickupPoints = (tid, pid, filter) => async dispatch => {
  try {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: true
    })

    const result = await api.core.getPickupPoints(tid, pid, filter)

    dispatch({
      type: CORE_GET_PICKUP_POINTS,
      payload: result?.data
    })
  } catch(err) {

  } finally {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: false
    })

    // history.goBack()
  }
}

export const setTitle = (title) => dispatch => {
  dispatch({
    type: CORE_SET_TITLE,
    payload: title
  })
}

export const setTitleDefault = (title) => dispatch => {
  dispatch({
    type: CORE_SET_TITLE_DEFAULT,
    payload: title
  })
}

export const setPickupPoint = (tid, pid, data) => async dispatch => {
  try {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: true
    })

    await api.core.setPickupPoint(tid, pid, data)

  } catch(err) {
  } finally {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: false
    })

    history.push(`/${tid}/${pid}`)
  }
}

export const setDeliveryDeliverToNeighbor = (tid, pid, data) => async dispatch => {
  try {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: true
    })

    await api.core.setDeliveryDeliverToNeighbor(tid, pid, data)

  } catch(err) {
  } finally {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: false
    })

    history.push(`/${tid}/${pid}`)
  }
}

export const setAddress = (tid, pid, data) => async dispatch => {
  try {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: true
    })

    await api.core.setAddress(tid, pid, data)

  } catch(err) {
  } finally {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: false
    })

    history.push(`/${tid}/${pid}`)
  }
}

export const setDeliveryLeaveOutside = (tid, pid, data) => async dispatch => {
  try {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: true
    })

    await api.core.setDeliveryLeaveOutside(tid, pid, data)

  } catch(err) {
  } finally {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: false
    })

    history.push(`/${tid}/${pid}`)
  }
}

export const setDeliveryStoreForVacation = (tid, pid, data) => async dispatch => {
  try {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: true
    })

    await api.core.setDeliveryStoreForVacation(tid, pid, data)

  } catch(err) {
  } finally {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: false
    })

    history.push(`/${tid}/${pid}`)
  }
}

export const setDeliveryCourierMessage = (tid, pid, data) => async dispatch => {
  try {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: true
    })

    await api.core.setDeliveryCourierMessage(tid, pid, data)

  } catch(err) {
  } finally {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: false
    })

    history.push(`/${tid}/${pid}`)
  }
}

export const setInvoiceContactDetails = (tid, pid, data) => async dispatch => {
  try {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: true
    })

    await api.core.setInvoiceContactDetails(tid, pid, data)

    const defaultCoreState = getFromStore().core

    dispatch({
      type: CORE_SEND_PAYMENT,
      payload: { ...defaultCoreState, DeliveryDetails: { ...defaultCoreState.DeliveryDetails, PaymentRequiresMailInvoiceDetails: false } }
    })

  } catch(err) {
  } finally {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: false
    })

    // history.push(`/${tid}/${pid}`)
  }
}

export const submitFeedbackForm = (tid, pid, data) => async dispatch => {
  try {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: true
    })

    await api.core.submitFeedbackForm(tid, pid, data)

    dispatch({
      type: CORE_SHOW_FEEDBACK_MESSAGE,
      payload: true
    })

  } catch(err) {
  } finally {
    dispatch({
      type: CORE_CHANGE_FETCHING,
      payload: false
    })

    history.push(`/${tid}/${pid}`)
  }
}

export const saveMenuTypeId = (type) => dispatch => {
  dispatch({
    type: CORE_SAVE_TYPE_MENU,
    payload: type
  })
}
