import styled from 'styled-components'

import { convertColor } from '../../../../utils'

export const DateItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.4rem 0 1.5rem;
  margin: 0;
  transition: ${({ theme }) => theme.transition};
  border-bottom: .1rem solid ${({ theme }) => theme.colors.border};
  border-top: ${({ _borderTop, theme }) => _borderTop ? `.1rem solid ${theme.colors.border}` : 'none'};
  cursor: pointer;

  &:hover, &:active {
    opacity: .8;
  }

  ${props => props._paddingLeft && `
    padding-left: 1.2rem;
  `}
`

DateItem.Icon = styled.span`
  font-family: IconFont;
  font-size: 2.5rem;
  position: absolute;
  color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '${({ _day }) => _day}';
    position: absolute;
    font-size: 1.3rem;
  }
`

DateItem.Text = styled.div`
  flex: 1;
  padding: 0 1rem 0 4.3rem;
  font-size: ${({ _smallText }) => _smallText ? '1.1rem' : '1.4rem'};
  line-height: 1.4rem;
  color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
`

DateItem.Arrow = styled.div`
  width: 2rem;
  transform: translate(5px, 2px);
  fill: #979797;
  display: ${({ _hide }) => _hide ? 'none' : 'block'};

  [dir='rtl'] & {
    transform: translate(-5px, 2px) scaleX(-1);
  }
`
