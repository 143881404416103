import React, { useContext } from 'react'

import { DeliveryDetailsItem } from './styles'
import { ThemeContext } from '../../../../utils'

export default ({
  icon = '&#xe907;',
  title = '',
  text = ''
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  return (
    <DeliveryDetailsItem
      ContentIconsColor={ContentIconsColor}
      ContentTextColor={ContentTextColor}
    >
      <div
        className='icon'
        dangerouslySetInnerHTML={{ __html: icon }}
      />
      <div className='info'>
        <div
          className='title'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <div
          className='text'
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </DeliveryDetailsItem>
  )
}
