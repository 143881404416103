import styled from 'styled-components'
import { Select as SelectAntd } from 'antd'

import { convertColor } from '../../../utils'

export const Select = styled(SelectAntd)`
  height: 4rem !important;
  /* padding: 1.1rem; */
  font-family: 'OpenSans';
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
  border-radius: .5rem;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #979797;
  overflow: hidden;

  [dir='rtl'] & {
    font-family: 'OpenSansHebrew';
  }

  &:hover {
    border-color: #979797;
    box-shadow: 0px 0px 0px 1px #979797;
  }

  &:focus, &:active {
    border-color: #979797;
    box-shadow: 0px 0px 0px 1px #979797;
    box-shadow: none !important;
  }

  & .ant-select-arrow {
    transform: rotate(-90deg);
    height: 15px;
    top: 50%;

    [dir='rtl'] & {
      transform: rotate(90deg);
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }

  & .ant-select-selector {
    padding: 1rem 1.1rem !important;
    height: 100% !important;
    min-width: 9rem;
    border-color: unset !important;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2) !important;
    border: unset !important;

    &:hover, &:active, &:focus {
      border-color: unset !important;
      box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0px 0px 0px 1px #979797 !important;
    }
  }

  & .ant-select-selection-item {
    line-height: 1.6rem !important;
    padding: 0;
  }

  & .ant-select-item-option-selected {
    background-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue} !important;
  }
`
Select.Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  border: solid 1px #979797;
  padding: 0 1.5rem;
  background: #fff;

  ${({ _pickup }) => _pickup && `
    margin-top: 1.5rem;
  `}

  ${({ _leave }) => _leave && `
    
  `}
`

Select.Item = styled.div`
  display: flex;
  align-items: center;
  padding: 1.2rem 1.5rem;
  border-bottom: solid 1px #e4e0e0;
  font-size: 1.6rem;
  line-height: 1.8rem;
  cursor: pointer;
  color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : '#46474c'};

  .icon {
    width: 1.08rem;
    margin-right: 1.2rem;
  }

  ${({ theme, ContentIconsColor, _active }) => _active && `
    color: ${ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    font-weight: 600;
  `}
  /* font-weight: 600; */
`
