import React, { useState, useContext } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import moment from 'moment'

import { StoreDuringVacation } from './styles'
import { ChangeDelivery, Item } from '../../blocks'
import { ThemeContext } from '../../../utils'
import history from '../../../history'

export default ({
  core: {
    HeaderText,
    AvailableDates = []
  } = {},
  core,
  tid,
  pid,
  id,
  setDeliveryStoreForVacation
}) => {
  const [selectedDate, changeDate] = useState(false)

  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const _confirm = (data = false) => {
    const Date = selectedDate || data
    setDeliveryStoreForVacation(tid, pid, { Date })
  }

  const onSelect = (date) => {
    changeDate(date)
    history.push(`/${tid}/${pid}/${id}/confirm`)
  }

  React.useEffect(() => {
    document.body.style.overflow = 'auto'
    window.scrollTo(0, 0)

    // return () => {
    //   document.body.style.overflow = 'hidden'
    //   window.scrollTo(0, 0)
    // }
  }, [])

  return (
    <Route
      render={({ location, match: { params: { tid, pid, id } } }) => (
        <Switch location={location}>
          <Route
            exact
            path={`/:tid/:pid/${id}`}
            component={() => (
              <StoreDuringVacation>
                <StoreDuringVacation.Text
                  ContentIconsColor={ContentIconsColor}
                  ContentTextColor={ContentTextColor}
                >
                  {HeaderText && <span
                    className='title'
                    dangerouslySetInnerHTML={{__html: HeaderText}}
                  />}
                </StoreDuringVacation.Text>

                {AvailableDates.map((item, key) => (
                  <Item.Date
                    key={key}
                    borderTop={key === 0}
                    day={moment(item).format('D')}
                    ContentTextColor={ContentTextColor}
                    ContentIconsColor={ContentIconsColor}
                    onClick={() => onSelect(item)}
                  >{`${moment(item).format('dddd') + ', ' + moment(item).format('D/M/YYYY')}`}</Item.Date>
                ))}

              </StoreDuringVacation>
            )}
          />
          <Route path={`/:tid/:pid/${id}/confirm`} component={() => selectedDate ? <ChangeDelivery.Confirm
            core={core}
            selectedDate={selectedDate}
            confirm={_confirm}
            changeDate={changeDate}
          /> : <Redirect to={`/${tid}/${pid}/${id}`} />} />
        </Switch>
      )}
    />
  )
}

