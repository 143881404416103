import React, { useContext } from 'react'

import { DateItem } from './styles'
import { ArrowIcon } from '../../../../assets/svg'
import { ThemeContext } from '../../../../utils'

export default ({
  children,
  column = false,
  paddingLeft = false,
  borderTop = false,
  smallText = false,
  onClick,
  day = '',
  ...props
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  return (
    <DateItem
      _column={column}
      _paddingLeft={paddingLeft}
      _borderTop={borderTop}
      onClick={onClick}
      {...props}
    >
      <DateItem.Icon
        ContentIconsColor={ContentIconsColor}
        _day={day}
        {...props}
      >&#xe907;</DateItem.Icon>
      <DateItem.Text
        _column={column}
        _smallText={smallText}
        ContentTextColor={ContentTextColor}
        dangerouslySetInnerHTML={{ __html: children }}
        {...props}
      />
      <DateItem.Arrow>
        <ArrowIcon />
      </DateItem.Arrow>
    </DateItem>
  )
}
