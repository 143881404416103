import { connect } from 'react-redux'

import Spinner from './Spinner'

const mapStateToProps = state => ({
  isFetching: state.core.isFetching,
  core: state.core,
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Spinner)

