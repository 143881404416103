import React from 'react'
import { Form as FormAntd } from 'antd'
import { useIntl } from 'react-intl'

import { Input } from '../..'

export default ({
  name = ['Address', 'AptRoom']
}) => {
  const intl = useIntl()

  return (
    <FormAntd.Item
      label={intl.formatMessage(
        {
          id: 'controls_label_apt',
          defaultMessage: 'Apt. No.'
        },
      )}
      name={name}
      className='in-33'
    >
      <Input />
    </FormAntd.Item>
  )
}
