import React, { useContext } from 'react'

import { PickupItem } from './styles'
import { ArrowIcon } from '../../../../assets/svg'
import { convertDistance, ThemeContext } from '../../../../utils'

const TYPE_ICON = {
  1: '&#xe918',
  2: '&#xe912',
  3: '&#xe901',
}

export default ({
  borderTop = false,
  Name = '',
  Distance = '',
  TypeID = 1,
  Address = {},
  ...props
}) => {
  const { ContentIconsColor, ContentTextColor, Language } = useContext(ThemeContext)

  return (
    <PickupItem.Container>
      <PickupItem
        _borderTop={borderTop}
        {...props}
      >
        <PickupItem.Icon
          ContentIconsColor={ContentIconsColor}
          dangerouslySetInnerHTML={{ __html: TYPE_ICON[TypeID] }}
        />
        <PickupItem.Text
          ContentTextColor={ContentTextColor}
          ContentIconsColor={ContentIconsColor}
        >
          <span className='name'>{Name}</span>
          <span className='address'>{Address?.City} {Address?.StreetName} {Address?.StreetNumber}</span>
          <span className='distance'>{convertDistance(Distance || 0, Language)}</span>
        </PickupItem.Text>
        <PickupItem.Arrow>
          <ArrowIcon />
        </PickupItem.Arrow>
      </PickupItem>
    </PickupItem.Container>
  )
}
