import styled from 'styled-components'
import { Rate, Slider } from 'antd'

import { convertColor } from '../../../../utils'

export const Feedback = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2rem;
`

Feedback.Title = styled.div`
  margin-top: 1.7rem;
  font-size: 1.6rem;
  line-height: 1.8rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
`

Feedback.Rate = styled(Rate)`
  .ant-rate-star:not(:last-child) {
      margin-right: 1.5rem;
      margin-top: 1rem;
  }

  svg path:first-child {
    fill: #f5f5f5 !important;
  }

  svg path:last-child {
    fill: black !important;
  }

  .ant-rate-star-full {
    svg path:first-child {
      fill: #fcc832 !important;
    }
  }

  margin-bottom: 3rem;
`

Feedback.SliderItem = styled.div`
  width: 100%;
  padding: 0 0 1rem;
  display: flex;
  flex-direction: column;
`

Feedback.SliderTitle = styled.div`
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  font-weight: 600;
  padding-bottom: 1.4rem;
  color: ${({ theme }) => theme.colors.gray};
`

Feedback.Slider = styled(Slider)`
  &.ant-slider {
    height: 12px;
    margin: 10px 6px 10px;
    padding: 4px 0;

    position: relative;
    margin-bottom: 4px;

    &::after {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
      position: absolute;
      position: absolute;
      top: 0;
      left: -5px;
      z-index: 1;
    }

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
      position: absolute;
      position: absolute;
      top: 0;
      right: -5px;
      z-index: 1;
    }
  }

  .ant-slider-handle, .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    width: 24px;
    height: 24px;
    border-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue} !important;
    margin-top: -11px;
    position: relative;
    box-shadow: ${({ theme }) => theme.boxShadow};
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
      position: absolute;
      /* top: 50%; */
      /* left: 50%; */
      /* transform: translate(-50%, -50%); */
    }

    &::after {
      content: '';
      position: absolute;
      width: 200%;
      height: 200%;
    }
  }

  .ant-slider-rail, .ant-slider-step, .ant-slider-track {
    background-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue} !important;
    height: 2px;
  }

  .ant-slider-mark {
    top: 14px;
  }
`

Feedback.SliderTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  line-height: 1.4rem;
  color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
  padding-bottom: 1.7rem;
  position: relative;

  &::before {
    content: '';
    width: calc(100% + 6rem);
    height: .1rem;
    bottom: 0;
    left: -3rem;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.border};
  }
`
