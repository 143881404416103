import styled from 'styled-components'

import { convertColor } from '../../../utils'

export const DeliveryStatus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

DeliveryStatus.Sender = styled.div`
  display: flex;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
  margin-bottom: 2.4rem;

  .icon {
    font-family: IconFont;
    margin-right: 1.75rem;
    font-size: 2rem;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .name {
    font-weight: 600;
  }

  .number {

  }
`

DeliveryStatus.Step = styled.div`
  display: flex;
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
`

DeliveryStatus.StepItem = styled.div`
  width: 25%;
  /* max-width: 6.3rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    width: 50%;
    height: .2rem;
    background-color: ${({ theme }) => theme.colors.lightGray};
    position: absolute;
    top: .6rem;
    left: 0;
    display: ${({ _first }) => _first ? 'none' : 'block'};

    ${({ _done, ContentIconsColor, theme }) => _done && `
      background-color: ${ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    `}
  }

  &::after {
    content: '';
    width: 50%;
    height: .2rem;
    background-color: ${({ theme }) => theme.colors.lightGray};
    position: absolute;
    top: .6rem;
    right: 0;
    display: ${({ _last }) => _last ? 'none' : 'block'};

    ${({ _done, ContentIconsColor, theme }) => _done && `
      background-color: ${ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    `}

    ${({ _active, theme }) => _active && `
      background-color: ${theme.colors.lightGray};
    `}
  }

  .dot {
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.lightGray};
    margin-bottom: 1rem;
    z-index: 1;

    ${({ _done, ContentIconsColor, theme }) => _done && `
      background-color: ${ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    `}
  }

  .status {
    text-align: center;
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding: 0 .25rem;

    ${({ _active, theme }) => _active && `
      color: ${theme.colors.gray};
      font-weight: 600;
    `}
  }
`
