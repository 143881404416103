import styled from 'styled-components'

import { convertColor } from '../../../utils'

export const DeliveryNeighbor = styled.div`
  padding: 0 .6rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  display: block;
  padding-bottom: ${({ _paddingBottom }) => _paddingBottom ? `${_paddingBottom + 10}px` : '4rem' };
`

DeliveryNeighbor.Text = styled.div`
  display: block;

  .title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.black};
    padding: 0 0 .5rem;
  }

  .text {
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};

    &--color {
      font-weight: 600;
      color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    }
  }
`
