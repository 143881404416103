import styled from 'styled-components'

export const DevSettings = styled.div`
  width: 4rem;
  height: 4rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: calc(100vh - 15rem);
  right: 1rem;
  border-radius: 50%;
  z-index: 99999;
`

DevSettings.ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

DevSettings.Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  .text {
    width: 10rem;
  }
`

DevSettings.Desc = styled.div`
  text-align: center;
  font-size: 1.1rem;
  color: #69696c;
  margin-top: 2rem;
`
