import React, { useContext } from 'react'

import { Layout, Header } from '../../components/layout'
import { Page404 } from '../../components/views'
import { LangContext } from '../../utils'

export default () => {
  const lang = window.navigator.language.slice(0, 2)

  const languages = useContext(LangContext)
  return (
    <React.Fragment>
      <Layout
        home={false}
      >
        <Header
          home={false}
          defaultTitle={languages?.[lang]?.['404_title']}
          noIcons={true}
        />
        <Page404 />
      </Layout>
    </React.Fragment>
  )
}
