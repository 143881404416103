import jwt_decode from 'jwt-decode'

export const jwtDecote = (token) => {
  const decode = jwt_decode(token)

  return decode?.['cognito:groups'] || ['agent']
}

export const isAunthenticate = (token) => {
  if (token) {
    const decode = jwt_decode(token)
    const isExp = decode?.exp > Date.now() / 1000

    return isExp
  }

  return false
}
