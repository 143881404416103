import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { ChangeAddress } from './styles'
import { Form } from '../../blocks'
import { ThemeContext } from '../../../utils'

export default ({
  tid,
  pid,
  core: {
    HeaderText,
    DeliveryDetails: {
      Address = {}
    } = {}
  },
  core,
  setAddress
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()

  const callbackFormData = data => setAddress(tid, pid, data)

  // React.useEffect(() => {
  //   document.body.classList.add('feed')

  //   return () => document.body.classList.remove('feed')
  // }, [])

  React.useEffect(() => {
    document.body.classList.add('feed')
    document.body.style.overflow = 'hidden'
    window.scrollTo(0, 0)

    return () => {
      document.body.classList.remove('feed')
      document.body.style.overflow = 'auto'
      window.scrollTo(0, 0)
    }
  }, [])

  const [paddingBottom, setPaddingBottom] = React.useState(40)
  const createRef = r => {
    setPaddingBottom(r?.querySelector('.contact-btn-container')?.clientHeight || 4)
  }

  return (
    <ChangeAddress _paddingBottom={paddingBottom} ref={createRef} >
      <ChangeAddress.Text
        ContentIconsColor={ContentIconsColor}
        ContentTextColor={ContentTextColor}
      >
        {HeaderText && <span
          className='title'
          dangerouslySetInnerHTML={{__html: HeaderText}}
        />}
        <div
          className='text'
          dangerouslySetInnerHTML={{__html: intl.formatMessage(
            {
              id: 'change_address_label',
              defaultMessage: 'Current drop off address'
            },
          )}}
        />
        <div className='text text--color'>{Address?.City || ''} {Address?.StreetName || ''} {Address?.StreetNumber || ''}</div>
      </ChangeAddress.Text>
      <Form.AddressChange
        callbackFormData={callbackFormData}
        core={core}
      />

    </ChangeAddress>
  )
}
