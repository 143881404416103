import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { Layout, Header } from '../../components/layout'
import { Spinner } from '../../components/blocks'
import {
  SelectOption,
  ChangeAddress,
  DeliveryNeighbor,
  ChangeDeliveryDate,
  LeaveOutside,
  SelectPickupPoint,
  FindLocker,
  SelectPudo,
  SelectStore,
  StoreDuringVacation,
  ProcessPayment,
  ContactUs,
  ContactCourier,
} from '../../components/views'

const getPage = (type = 1, tid, pid) => {
  switch (type) {
    case 1:
      return SelectOption
    case 3:
      return ChangeAddress
    case 4:
      return DeliveryNeighbor
    case 5:
      return ChangeDeliveryDate
    case 6:
      return LeaveOutside
    case 7:
      return SelectPickupPoint
    case 8:
      return FindLocker
    case 9:
      return SelectPudo
    case 10:
      return SelectStore
    case 11:
      return StoreDuringVacation
    case 12:
      return ContactCourier
    case 13:
      return ProcessPayment
    case 14:
      return ContactUs
    default:
      return () => <Redirect to={`/${pid}/${tid}`} />
  }
}

export default ({
  getPageInfo,
  tid,
  pid,
  id,
  isFetching,
  getPickupPoints,
  setPickupPoint,
  core: {
    DeliveryDetails = {},
    MenuTypeID,
  } = {},
  core,
  ...rest
}) => {
  const [filters, changeFilters] = useState({ type: null })
  const [tabActive, changeTab] = useState(1)
  const [addressPlaceholder, changeAddressPlaceholder] = useState(``)
  const [lastPlaceholder, setLastPlaceholder] = useState('')

  const _changeFilters = (filter) => {
    if (filter.noReload) {
      delete filter.noReload
      const newFilters = {...filters, ...filter}
      changeFilters(newFilters)

      return null
    }
    const newFilters = {...filters, ...filter}
    changeFilters(newFilters)
    getPickupPoints(tid, pid, newFilters)
  }

  const onSelect = (data) => {
    setPickupPoint(tid, pid, data)
  }

  useEffect(() => {
    setLastPlaceholder(`${DeliveryDetails?.Address?.City} ${DeliveryDetails?.Address?.StreetName} ${DeliveryDetails?.Address?.StreetNumber}`)
  }, [DeliveryDetails])

  useEffect(() => {
    getPageInfo(tid, pid, id)
  }, [getPageInfo, pid, tid, id])

  const Page = getPage(MenuTypeID, pid, tid)
  return (
    <Spinner
      isActive={isFetching}
      size='large'
    >
      <Layout
        home={false}
      >
        <Header
          home={false}
          core={core}
          {...rest}
        />
        <Page
          tid={tid}
          pid={pid}
          id={id}
          filters={filters}
          callbackFilter={_changeFilters}
          tabActive={tabActive}
          changeTab={changeTab}
          addressPlaceholder={addressPlaceholder}
          changeAddressPlaceholder={changeAddressPlaceholder}
          lastPlaceholder={lastPlaceholder}
          setLastPlaceholder={setLastPlaceholder}
          onSelect={onSelect}
          core={core}
          {...rest}
        />
      </Layout>
    </Spinner>
  )
}
