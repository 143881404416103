import styled from 'styled-components'

import { convertColor } from '../../../../utils'

export const Map = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 13rem;
  margin-top: ${({ _marginTop }) => _marginTop};
  margin-bottom: ${({ _marginBottom }) => _marginBottom};
`

Map.Container = styled.div`
  height: 100%;

  .crime-marker {
    background: none;
    border: none;
    color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    transform: translate(-50%, -100%);
    width: 2.9rem;
    height: 4rem;

    &--home {
      height: 3.5rem;
    }
  }
`
