import styled from 'styled-components'

import { convertColor } from '../../../utils'

export const StepsDelivery = styled.div`
  display: flex;
  flex-direction: column;
`

StepsDelivery.StepContainer = styled.div`
  height: 1rem;
  display: flex;
`

StepsDelivery.Step = styled.div`
  width: ${({ _hide }) => _hide ? '1rem' : '2.8rem'};
  height: 1rem;
  display: flex;
  align-items: center;

  .circle {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: ${({ theme, _done, ContentIconsColor }) => _done ? (ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue) : theme.colors.lightGray};
  }

  .line {
    display: ${({ _hide }) => _hide ? 'none' : 'block'};
    width: 1.8rem;
    height: .1rem;
    background-color: ${({ theme, _done, ContentIconsColor }) => _done ? (ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue) : theme.colors.lightGray};
  }
`

StepsDelivery.Status = styled.div`
  padding-top: .4rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
`
