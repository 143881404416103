import update from 'immutability-helper'

import {
  CORE_GET_PAGE_INFO,
  CORE_CHANGE_FETCHING,
  CORE_GET_PICKUP_POINTS,
  CORE_SET_TITLE,
  CORE_SET_TITLE_DEFAULT,
  CORE_SAVE_TYPE_MENU,
  CORE_SEND_PAYMENT,
  CORE_SHOW_FEEDBACK_MESSAGE,
} from './types'

const initState = {
  isFetching: false,
  MenuTypeID: null,
  feedbackMessage: false,
}

function reducer (state = initState, action) {
  switch (action.type) {
    case CORE_GET_PAGE_INFO:
      return update(state, { $set: { ...state, ...action.payload } })

    case CORE_SEND_PAYMENT:
      return update(state, { $set: { ...state, ...action.payload } })

    case CORE_CHANGE_FETCHING:
      return update(state, { isFetching: { $set: action.payload } })

    case CORE_GET_PICKUP_POINTS:
      return update(state, { PickupPoints: { $set: action.payload } })

    case CORE_SET_TITLE:
      return update(state, { Title: { $set: action.payload } })

    case CORE_SET_TITLE_DEFAULT:
      return update(state, { TitleDefault: { $set: action.payload } })

    case CORE_SAVE_TYPE_MENU:
      return update(state, { MenuTypeID: { $set: action.payload } })

    case CORE_SHOW_FEEDBACK_MESSAGE:
      return update(state, { feedbackMessage: { $set: action.payload } })

    default:
      return state
  }
}

export default reducer
