import React from 'react'
import { Form as FormAntd } from 'antd'
import { useIntl } from 'react-intl'

import { Input } from '../..'

export default ({
  name = 'FullName'
}) => {
  const intl = useIntl()

  return (
    <FormAntd.Item
      label={intl.formatMessage(
        {
          id: 'controls_label_full_name',
          defaultMessage: 'Full name'
        },
      ) + ' *'}
      name={name}
      rules={[{ required: true, message: '' }]}
    >
      <Input  />
    </FormAntd.Item>
  )
}
