import styled from 'styled-components'
import { Input as InputAntd } from 'antd'

import { convertColor } from '../../../utils'

export const Textarea = styled(InputAntd.TextArea)`
  width: 100%;
  min-height: 14rem !important;
  border-radius: .5rem;
  border-color: #979797;

  &:hover, &:active {
    border-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    box-shadow: none;
  }
`

export const Text = styled.div`
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 1.3rem;
`
