import styled from 'styled-components'
import { convertColor } from '../../../utils'

export const InfoRectangle = styled.div`
  width: 100%;
  padding: .6rem 1rem 1rem 1rem;
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  @media screen and (min-width: 386px) {
    padding: 1.1rem 1.4rem;
  }

  span {
    font-weight: 600;
  }
`
