import React from "react";
import { useIntl } from "react-intl";

const Footer = () => {
  const intl = useIntl();

  return (
    <a
      href={intl.formatMessage({
        id: "footer_link",
        defaultMessage: "https://www.applicat.com/platform/customer-experience/",
      })}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "inherit",
        marginTop: "auto",
        padding: "2rem 0 2rem 0",
      }}
    >
      <img
        style={{ width: "11rem", height: "auto" }}
        src="/footer-logo.svg"
        alt="Applicat"
      />
    </a>
  );
};

export default Footer;
