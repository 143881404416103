import styled from 'styled-components'

export const Rate = styled.div`
  display: flex;
  justify-content: center;
  /* margin-top: 1.8rem; */
  margin-bottom: 3rem;
`

Rate.Container = styled.div`
`

Rate.Star = styled.img`
  margin-right: 1.5rem;

  ${props => props._noMargin && `
    margin-right: 0;
  `}
`
