import styled from 'styled-components'

export const Page404 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 1.3rem;
`

Page404.Message = styled.div`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.black};
  padding-top: 1.7rem;
  text-align: center;
  width: 26rem;
`
