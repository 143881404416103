import React, { useContext } from 'react'

import { Input } from './styles'
import { ThemeContext } from '../../../utils'

export default ({ ...props }) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  return ( <Input ContentIconsColor={ContentIconsColor} ContentTextColor={ContentTextColor} {...props} /> )
}
