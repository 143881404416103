import React, { useContext } from 'react'

import { ContactCourier } from './styles'
import { Form } from '../../blocks'
import { ThemeContext } from '../../../utils'

export default ({
  tid,
  pid,
  core: {
    HeaderText,
    DeliveryDetails: {
      ScheduledDate = '2020-11-03T00:00:00.000'
    } = {},
  },
  core,
  setDeliveryCourierMessage
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const callbackFormData = data => setDeliveryCourierMessage(tid, pid, data)

  React.useEffect(() => {
    document.body.classList.add('feed')
    document.body.style.overflow = 'hidden'
    window.scrollTo(0, 0)

    return () => {
      document.body.classList.remove('feed')
      document.body.style.overflow = 'auto'
      window.scrollTo(0, 0)
    }
  }, [])

  const [paddingBottom, setPaddingBottom] = React.useState(40)
  const createRef = r => {
    setPaddingBottom(r?.querySelector('.contact-btn-container')?.clientHeight || 4)
  }

  return (
    <ContactCourier _paddingBottom={paddingBottom} ref={createRef} >
      <ContactCourier.Text
        ContentIconsColor={ContentIconsColor}
        ContentTextColor={ContentTextColor}
      >
        {HeaderText && <span
          className='title'
          dangerouslySetInnerHTML={{__html: HeaderText}}
        />}
      </ContactCourier.Text>
      <Form.ContactCourier
        callbackFormData={callbackFormData}
        core={core}
      />
    </ContactCourier>
  )
}
