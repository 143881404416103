import styled from 'styled-components'

import { convertColor } from '../../../../utils'

export const Map = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 24.7rem;
  margin-top: ${({ _marginTop }) => _marginTop};
  margin-bottom: ${({ _marginBottom }) => _marginBottom};
`

Map.Container = styled.div`
  height: 17.7rem;

  .crime-marker {
    background: none;
    border: none;
    color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    transform: translate(-50%, -100%);
    width: 3.8rem;
    height: 4.5rem;

    &--home {
      height: 3rem;
      width: 2.7rem;
    }
  }
`

Map.Text = styled.div`
  padding-top: 1rem;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};

  span {
    color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
  }
`
