import styled from 'styled-components'

import { convertColor } from '../../../../utils'

export const DeliveryDetailsItem = styled.div`
  border-bottom: .1rem solid ${({ theme }) => theme.colors.border};
  padding: 1rem 0;
  display: flex;
  align-items: center;

  .icon {
    font-family: IconFont;
    font-size: 2rem;
    color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    margin-right: 1.7rem;
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
  }

  .text {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 600;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};

    span:last-child {
      margin-left: 1rem;
    }

    .small {
      font-size: 1.2rem;
    }
  }
`
