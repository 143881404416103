import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import SelectOption from './SelectOption'
import actions from '../../store/combineActions'

const mapStateToProps = state => ({
  isFetching: state.core.isFetching,
  core: state.core,
})

const mapDispatchToProps = dispatch => ({
  getPageInfo: (tid, pid, id) => dispatch(actions.core.getPageInfo(tid, pid, id)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SelectOption))

