import styled from 'styled-components'

import { convertColor } from '../../../../utils'

export const MapPickup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${({ _titleSize }) => _titleSize ? `calc(100vh - 34.2rem - ${_titleSize}px)` : 'calc(100vh - 34.2rem)'};
  /* height: 30rem; */
`

MapPickup.Container = styled.div`
  height: 100%;

  .cluster-marker {
    color: #fff;
    background: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    border-radius: 50%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: .1rem solid #fff;
    box-shadow: 0px 0px 3px 2px ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 600;
    transform: translate(-50%, -100%);
  }

  .crime-marker {
    background: none;
    border: none;
    color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    transform: translate(-50%, -3.5rem);
    width: 2.9rem;
    height: 4rem;

    &--home {
      height: 3.5rem;
    }

    &--selected {
      stroke: black;
      stroke-width: .2rem;
    }
  }

  .crime-marker img {
    width: 2.5rem;
  }
`
