import React, { useContext } from 'react'

import { Textarea, Text } from './styles'
import { ThemeContext } from '../../../utils'

export default ({
  text = false,
  ...props
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  return (
    <>
      {text && <Text>{text}</Text>}
      <Textarea
        ContentIconsColor={ContentIconsColor}
        ContentTextColor={ContentTextColor}
        {...props}
      />
    </>
  )
}
