import React, { useState, useContext } from 'react'
import { useIntl } from 'react-intl'

import { LeaveOutside } from './styles'
import { Form } from '../../blocks'
import { ThemeContext } from '../../../utils'

export default ({
  tid,
  pid,
  core: {
    HeaderText,
    DeliveryDetails: {
      Address = {}
    } = {}
  },
  core,
  setDeliveryLeaveOutside
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()

  const callbackFormData = data => setDeliveryLeaveOutside(tid, pid, data)

  React.useEffect(() => {
    document.body.classList.add('feed')
    document.body.style.overflow = 'hidden'
    window.scrollTo(0, 0)

    return () => {
      document.body.classList.remove('feed')
      document.body.style.overflow = 'auto'
      window.scrollTo(0, 0)
    }
  }, [])

  const [paddingBottom, setPaddingBottom] = useState(40)
  const createRef = r => {
    setPaddingBottom(r?.querySelector('.contact-btn-container')?.clientHeight || 4)
  }

  return (
    <LeaveOutside _paddingBottom={paddingBottom} ref={createRef} >
      <LeaveOutside.Text
        ContentIconsColor={ContentIconsColor}
        ContentTextColor={ContentTextColor}
      >
        {HeaderText && <span
          className='title'
          dangerouslySetInnerHTML={{__html: HeaderText}}
        />}
      </LeaveOutside.Text>
      <LeaveOutside.Text
        ContentIconsColor={ContentIconsColor}
        ContentTextColor={ContentTextColor}
      >
        <div className={`text text--note ${HeaderText && 'text--separator'}`}>
          <div
            className='text text--color'
            dangerouslySetInnerHTML={{__html: intl.formatMessage(
              {
                id: 'change_drop_address_label',
                defaultMessage: 'Drop off address'
              },
            )}}
          />
          <div className='text text--color text--bold'>{Address?.City || ''} {Address?.StreetName || ''} {Address?.StreetNumber || ''}</div>
          <div className='text text--color' style={{ fontSize: '1.2rem' }}>
            {Address?.Entrance && (
              <React.Fragment>Entrence: <span className='text text--color text--bold'>{Address?.Entrance}</span></React.Fragment>
            )}
            {Address?.FloorNumber && (
              <React.Fragment>Floor: <span className='text text--color text--bold'>{Address?.FloorNumber}</span></React.Fragment>
            )}
          </div>
        </div>
      </LeaveOutside.Text>
      <Form.LeaveOutside
        callbackFormData={callbackFormData}
        core={core}
      />
    </LeaveOutside>
  )
}
