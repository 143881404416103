import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { FeedbackMessage } from './styles'
import { ThemeContext } from '../../../utils'

export default () => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()

  return (
    <FeedbackMessage
      ActionButtonsTextColor={ContentIconsColor}
      ActionButtonsBackgroundColor={ContentTextColor}
    >
      {intl.formatMessage(
        {
          id: 'feedback_message',
          defaultMessage: 'Thanks for your feedback !'
        },
      )}
    </FeedbackMessage>
  )
}
