import styled from 'styled-components'
import { convertColor } from '../../../utils'

export const Header = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  height: ${({ _home }) => _home ? '5rem' : '4rem'};
  position: fixed;
  top: 0;
  transform: translateX(-1rem);
  background-color: ${({ theme, HeaderBackgroundColor }) => HeaderBackgroundColor ? convertColor(HeaderBackgroundColor) : theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxShadow};
  z-index: 999;

  @media ${({theme})=> theme.media.mobile} {
   max-width: 100%;
   left: 1rem;
  }
`

Header.Container = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
  height: 100%;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: .7rem 1rem;
`

Header.Logo = styled.img`
  /* width: 6.1rem; */
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 800;
  font-style: italic;
  color: ${({ theme }) => theme.colors.white};
  margin-right: 2rem;
  position: absolute;
`

Header.Link = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  color: ${({ theme, HeaderTextColor }) => HeaderTextColor ? convertColor(HeaderTextColor) : theme.colors.gray};
  text-align: center;
  width: 100%;

  ${props => !props._home && `
    text-align: center;
    font-size: 1.6rem;
    width: 100%;
  `}
`

Header.Icon = styled.div`
  width: 3rem;
  height: 3rem;
  position: absolute;
  transition: ${({ theme }) => theme.transition};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  [dir='rtl'] & {
    transform: scaleX(-1);
    display: flex;
    justify-content: flex-end;
  }

  &:hover {
    opacity: .8;
  }
`
