import React from 'react'

import { Rate } from './styles'
import star from '../../../assets/images/star.svg'
import starYellow from '../../../assets/images/yellow-star.svg'

const STARS = Array.from({ length: 5 }, () => undefined)

export default ({
  rate = 2
}) => {
  return (
    <Rate>
      <Rate.Container>
        {STARS.map((item, key) => (
          <Rate.Star
            key={key}
            src={key + 1 <= Math.round(rate) ? starYellow : star}
            _noMargin={key + 1 === STARS.length}
          />
        ))}
      </Rate.Container>
    </Rate>
  )
}
