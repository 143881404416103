import React, { useState, useContext, useEffect } from 'react'
import { Form as FormAntd } from 'antd'
import { useIntl } from 'react-intl'

import { Form } from './styles'
import { EntrenceCode, Comment, ButtonControl } from './internal'
import { Select } from '..'
import { convertColor, ThemeContext } from '../../../utils'
import { ArrowIconSelect, CheckIcon } from '../../../assets/svg'

export default ({
  callbackFormData,
  core: {
    LocationSelectionOptions = [],
    FooterText
  },
}) => {
  const [SelectedLocation, SetSelectedLocation] = useState('')
  const [showError, changeShowError] = useState(false)

  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()

  const [form] = FormAntd.useForm()

  const setLocation = value => {
    const formValues = form.getFieldValue()

    SetSelectedLocation(value)
    form.setFieldsValue({...formValues, SelectedLocation: value})
  }

  const onFinish = values => {
    callbackFormData(values)
  }

  const onFinishFailed = errorInfo => {
    changeShowError(true)
  }

  useEffect(() => {
    document.body.classList.add('leaveOutside')

    return () => document.body.classList.remove('leaveOutside')
  }, [])

  return (
    <Form
      ContentIconsColor={ContentIconsColor}
      ContentTextColor={ContentTextColor}
      onClick={() => changeShowError(false)}
    >
      <FormAntd
        name='basic'
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        defaultValue={{
          SelectedLocation
        }}
      >

        <FormAntd.Item
          label={intl.formatMessage(
            {
              id: 'controls_label_pick_option',
              defaultMessage: 'Select an option'
            },
          ) + ' *'}
          rules={[{ required: true, message: '' }]}
          name='SelectedLocation'
          value={SelectedLocation}
        >
          <Select
            ContentIconsColor={ContentIconsColor}
            ContentTextColor={ContentTextColor}
            value={SelectedLocation}
            suffixIcon={<ArrowIconSelect />}
            dropdownRender={menu => {
              return (
              <Select.Container _leave>
                {menu.props.options.map((item) => (
                  <Select.Item
                    key={item.key}
                    ContentIconsColor={ContentIconsColor}
                    ContentTextColor={ContentTextColor}
                    onClick={() => {
                      setLocation(item.value)
                      menu.props.onToggleOpen()
                    }}
                  >
                    <span className='icon' style={{ color: item.value === SelectedLocation ? convertColor(ContentIconsColor) : convertColor(ContentTextColor) }}>{item.value === SelectedLocation && <CheckIcon />}</span>
                    <span style={{ color: item.value === SelectedLocation ? convertColor(ContentIconsColor) : convertColor(ContentTextColor) }}>{item.children}</span>
                  </Select.Item>))}
              </Select.Container>
              )
            }}
          >
            {LocationSelectionOptions.map((item, key) => <Select.Option key={key} value={item.ID}>{intl.formatMessage(
              {
                id: item.Text,
                defaultMessage: item.Text
              },
            )}</Select.Option>)}
          </Select>
        </FormAntd.Item>

        <Form.Item>
          <EntrenceCode
            name='EntryCode'
          />
        </Form.Item>

        <Form.Item>
          <Comment
            name='Remarks'
          />
        </Form.Item>

        {FooterText && <span
          className='title title--bottom'
          dangerouslySetInnerHTML={{__html: FooterText}}
        />}

        <div className='contact-btn-container'>

          {showError && <div className='error-message-form'>{
            intl.formatMessage(
              {
                id: 'message_failed_form',
                defaultMessage: '* Some info is missing'
              },
            )
          }</div>}

          <ButtonControl />
        </div>
      </FormAntd>
    </Form>
  )
}
