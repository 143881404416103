import React, { useState, useContext, useEffect } from 'react'
import { Form as FormAntd } from 'antd'
import { useIntl } from 'react-intl'

import { Form } from './styles'
import { Email, FullName, ButtonControl } from './internal'
import { ThemeContext } from '../../../utils'


export default ({
  callbackFormData,
}) => {
  const [showError, changeShowError] = useState(false)

  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()


  const onFinish = values => {
    callbackFormData(values)
  }

  const onFinishFailed = errorInfo => {
    changeShowError(true)
  }

  useEffect(() => {
    document.body.classList.add('feed')

    return () => document.body.classList.remove('feed')
  }, [])

  return (
    <Form
      ContentIconsColor={ContentIconsColor}
      ContentTextColor={ContentTextColor}
      onClick={() => changeShowError(false)}
    >
      <FormAntd
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div style={{ marginTop: '2rem' }}>
          <Email
            name='Email'
          />
        </div>
        <div style={{ marginTop: '1rem' }}>
          <FullName
            name='FullName'
          />
        </div>

        <span
          className='title'
          style={{ marginTop: '1rem', marginBottom: '2rem', fontSize: '1.4rem' }}
          dangerouslySetInnerHTML={{ __html: intl.formatMessage(
            {
              id: 'message_approve_payment',
              defaultMessage: 'By pressing ‘<b>Approve</b>’ , you confirm your acceptance of reciving the invoice and future invoices by e-mail.'
            },
          )}}
        />

        <div className='contact-btn-container'>
          {showError && <div className='error-message-form'>{
            intl.formatMessage(
              {
                id: 'message_failed_form',
                defaultMessage: '* Some info is missing'
              },
            )
          }</div>}

          <ButtonControl />
        </div>
      </FormAntd>
    </Form>
  )
}
