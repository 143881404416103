import styled from 'styled-components'

import { convertColor } from '../../../utils'

export const Separator = styled.div`
  display: flex;
  margin-top: ${({ _marginTop }) => _marginTop};
  margin-bottom: ${({ _marginBottom }) => _marginBottom};
  position: relative;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: ${({ theme, _color }) => _color ? convertColor(_color) : theme.colors.black};
  padding-bottom: 1.4rem;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 2rem);
    height: .1rem;
    background-color: ${({ theme }) => theme.colors.border};
    bottom: 0;
    left: -1rem;
  }
`