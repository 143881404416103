import React from 'react'
import { useIntl } from 'react-intl'

import { Form } from '../styles'
import { Button } from '../..'
import history from '../../../../history'

export default ({ ...props }) => {
  const intl = useIntl()

  return (
    <Form.Buttons {...props}>
      <Button
        // loading={isUpdating}
        htmlType='submit'
        text={intl.formatMessage(
          {
            id: 'controls_label_approve',
            defaultMessage: 'Approve'
          },
        )}
        type='primary'
      />
      <Button
        htmlType='button'
        text={intl.formatMessage(
          {
            id: 'controls_label_cancel',
            defaultMessage: 'Cancel'
          },
        )}
        type='default'
        onClick={() => history.goBack()}
      />
    </Form.Buttons>
  )
}
