import styled from 'styled-components'
import { Input as InputAntd } from 'antd'

import { convertColor } from '../../../utils'

export const Input = styled(InputAntd)`
  height: 4rem !important;
  padding: 1.1rem;
  font-family: 'OpenSans';
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
  border-radius: .5rem;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
  border: solid 1px #979797;

  [dir='rtl'] & {
    font-family: 'OpenSansHebrew';
  }

  &:hover {
    border-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
  }

  &:focus, &:active {
    border-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    box-shadow: none !important;
  }
`
