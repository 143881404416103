import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'

import { Layout, Header } from '../../components/layout'
import { Spinner } from '../../components/blocks'
import { SelectOption } from '../../components/views'

export default ({
  getPageInfo,
  tid,
  pid,
  isFetching,
  core: {
    Menu = false,
  } = {},
  core,
  ...rest
}) => {
  const intl = useIntl()

  useEffect(() => {
    getPageInfo(tid, pid)
  }, [getPageInfo, pid, tid])

  return (
    <Spinner
      isActive={isFetching}
      size='large'
    >
      <Layout
        home={false}
      >
        <Header
          home={false}
          {...rest}
          defaultTitle={intl.formatMessage(
            {
              id: 'main_menu_selection_page_header',
              defaultMessage: 'Select an option'
            }
          )}
        />
        <SelectOption
          {...rest}
          core={core}
        />
      </Layout>
    </Spinner>
  )
}
