import React, { useContext } from 'react'
// import { useIntl } from 'react-intl'

import { InfoRectangle } from './styles'
import { ThemeContext } from '../../../utils'

export default ({
  core: {
    MainMessage = ''
  } = {},
}) => {
  const { ContentIconsColor } = useContext(ThemeContext)

  // const intl = useIntl()

  return (
    <InfoRectangle
      ContentIconsColor={ContentIconsColor}
      dangerouslySetInnerHTML={{ __html: MainMessage }}
      // dangerouslySetInnerHTML={{__html: intl.formatMessage(
      //   {
      //     id: 'Delivery time',
      //     defaultMessage: 'Delivery time'
      //   },
      // )}}
    />
  )
}
