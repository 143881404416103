import React, { useContext } from "react";
import { useIntl } from "react-intl";

import history from "../../../history";
import { Header } from "./styles";
import { ThemeContext } from "../../../utils";

import { ArrowHeaderIcon } from "../../../assets/svg";

export default ({
  home = true,
  core: { Title = "Customer Service" } = {},
  defaultTitle = null,
  noIcons = null,
}) => {
  const { HeaderTextColor, HeaderBackgroundColor, Logo } =
    useContext(ThemeContext);

  const intl = useIntl();

  const onLink = () => !home && history.goBack();

  return (
    <Header _home={home} HeaderBackgroundColor={HeaderBackgroundColor}>
      <Header.Container>
        {home && Logo && <Header.Logo src={Logo} onClick={onLink} />}
        {!noIcons && !home && (
          <Header.Icon onClick={onLink}>
            <ArrowHeaderIcon />
          </Header.Icon>
        )}
        <Header.Link _home={home} HeaderTextColor={HeaderTextColor}>
          {defaultTitle
            ? intl.formatMessage({
                id: defaultTitle,
                defaultMessage: defaultTitle,
              })
            : intl.formatMessage({
                id: Title || ' ',
                defaultMessage: Title,
              })}
        </Header.Link>
      </Header.Container>
    </Header>
  );
};
