import React, { useState, Fragment, useContext, useRef } from 'react'
import { Form as FormAntd } from 'antd'
import { useIntl } from 'react-intl'

import { PlacesAutocomplete } from '../..'
import { ThemeContext } from '../../../../utils'

export default ({
  searchStreetParam,
  address,
  setAddress,
  onChangeStreet,
  isDisabledCity,
  setDisabledStreen,
  setNumber,
  city,
  name = ['Address', 'StreetName']
}) => {
  const [clear, setClear] = useState(false)

  const refInput = useRef(null)

  const intl = useIntl()

  const { Language } = useContext(ThemeContext)

  const onInputChange = value => {
    setAddress(value)
    setNumber('')
    setDisabledStreen(false)
  }

  const clearCallback = () => {
    setAddress('')
    setNumber('')
    setDisabledStreen(false)

    if (refInput.current) {
      refInput.current.focus()
    }
  }

  return (
    <Fragment>
      <FormAntd.Item
        label={intl.formatMessage(
          {
            id: 'controls_label_street',
            defaultMessage: 'Street'
          },
        ) + ' *'}
        name={name}
        className={`in-street-name`}
      >
        <PlacesAutocomplete
          withSessionToken={true}
          apiKey={`${process.env.REACT_APP_GOOGLE_KEY}&language=${Language}`}
          _width='100%'
          autocompletionRequest={{
            bounds: [{ lat: searchStreetParam?.latitude, lng: searchStreetParam?.longitude }],
            types: ['address'],
            country: searchStreetParam?.countryCode
          }}
          clearIcon={address.length > 0 && clear}
          clearCallback={clearCallback}
          selectProps={{
            ref: refInput,
            isDisabled: !isDisabledCity,
            placeholder: address,
            inputValue: address,
            defaultInputValue: address,
            onChange: data => {
              onChangeStreet(data)
            },
            onFocus: () => setClear(true),
            onBlur: () => setTimeout(() => setClear(false), 100),
            onInputChange: (v, { action }) => {
              if (action === 'input-change') {
                onInputChange(v)
                setClear(true)
              }
            },
            noOptionsMessage: () => '',
            filterOption: ({label, value, data}) => {
              if (!!data.value.terms.find(i => i.value === city) && data.value.types.includes('route')) {
                return true
              }
              return false
            },
            formatOptionLabel: v => {
              return v.value.structured_formatting.main_text
            },
            styles: {
              indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none !important',
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                zIndex: 2,
                padding: 0,
                height: '100%',
              }),
              input: (provided, state) => ({
                ...provided,
                margin: 0,
                padding: 0,
                lineHeight: '3.8rem',
              }),
              menu: (provided, state) => {
                const isOptions = state.options
                  .map(i => i.value)
                  .reduce((acc, val) => {
                    if (val.terms.find(i => i.value === city)) {
                      return [...acc, val]
                    }
                    return acc
                  }, [])

                return {
                  ...provided,
                  display: isOptions <= 0 ? 'none' : 'block',
                }
              },
              indicatorsContainer: (provided, state) => ({
                ...provided,
                display: 'none !important',
              }),
              singleValue: (provided, state) => ({
                ...provided,
                opacity: 0,
              }),
            }
          }}
        />
      </FormAntd.Item>
      {/* <div className={`bung ${activePanel === 'street' && 'bung--visible'}`} /> */}
    </Fragment>
  )
}
