// import { message } from 'antd'

// import { api } from '../../services'
import {
  USER_AUTHENTICATE,
} from './types'
// import { getFromStore } from '..'
// import history from '../../history'
import { isAunthenticate } from '../../utils/jwt'
import config from '../../constants/config'

export const checkAuthenticate = () => dispatch => {
  const token = localStorage.getItem('accessToken')
  const isAuth = token && isAunthenticate(token)

  if (isAuth) {
    dispatch({
      type: USER_AUTHENTICATE,
      payload: true
    })
  } else {
    localStorage.removeItem('accessToken')

    dispatch({
      type: USER_AUTHENTICATE,
      payload: false
    })

    dispatch(authenticate())
  }
}

export const authenticate = () => async dispatch => {
  try {
    const req = await fetch(`https://hormigatest.applicat.com/api/AADController/CXWebToken`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(config.oauth2)
    })

    const result = await req.json()

    if (!result.error) {
      localStorage.setItem('accessToken', JSON.stringify(result.access_token))
    } else {
      dispatch(checkAuthenticate())
    }

    dispatch({
      type: USER_AUTHENTICATE,
      payload: true
    })
  } catch(err) {
    dispatch({
      type: USER_AUTHENTICATE,
      payload: false
    })

    dispatch(checkAuthenticate())
  } finally {
  }
}
