import styled from 'styled-components'

export const Layout = styled.div`
  width: 100vw;
  /* min-height: 100vh; */
  overflow-y: auto;
  background-color: ${({ theme }) => theme.colors.darkWhite};
  padding-top: ${({ _home }) => _home ? '6rem' : '4rem'};
  position: relative;

  ${({ _home }) => _home && ({
    height: 'inherit',
  }) }
`

Layout.Container = styled.div`
  max-width: ${({ theme }) => theme.maxWidth};
  width: 100%;
  /* min-height: calc(100vh - 5rem); */
  background-color: ${({ theme }) => theme.colors.darkWhite};
  margin: 0 auto;
  padding: 0 1rem 1rem;
  position: relative;

  /*  */
  display: flex;
  flex-direction: column;

  ${({ _home }) => _home && ({
    height: 'inherit',
  }) }
`
