import React, { Fragment, useState } from 'react'
import { Form as AntdForm } from 'antd'
import { useIntl } from 'react-intl'

import { DeliveryDetails, InfoRectangle, Separator, Item, MoreOptionsBlock, Map, Button, Form, Textarea, FeedbackMessage } from '../../blocks'

export default ({
  core: {
    AppConfiguration = {},
    DeliveryDetails: DeliveryDetailsData = {},
    EnableTwoColumnsForMenu = true,
    Menu = [],
    MapData,
    FeedbackForm,
    feedbackMessage,
  } = {},
  core,
  tid,
  pid,
  submitFeedbackForm,
  ...props
}) => {
  const [rate, changeRate] = useState(0)
  const [sliders, changeSliders] = useState([])

  const intl = useIntl()

  const onFinishFeedback = values => {
    const data = {
      ...values,
      Items: sliders.map(item => ({ ID: item.ID, Value: item.value || 0 }))
    }

    submitFeedbackForm(tid, pid, data)
  }

  return (
    <Fragment>
      <DeliveryDetails
        core={core}
        tid={tid}
        pid={pid}
        {...props}
      />
      <InfoRectangle
        core={core}
      />

      {
        (!MapData && !FeedbackForm) && (
          <Fragment>
            <div style={EnableTwoColumnsForMenu ? { display: 'flex', flexWrap: 'wrap', padding: '0 0' } : {}}>
              {Menu.map((item, key) => (
                item.MenuTypeID === 2
                  ? (
                    <Separator
                      key={key}
                      color={AppConfiguration?.ContentTextColor}
                      text={item.Text}
                      _marginTop='1.7rem'
                      style={{ width: '100%' }}
                    />
                  )
                  : (
                    <Item.Menu
                      key={key}
                      icon={item.Icon}
                      iconColor={AppConfiguration?.ContentIconsColor}
                      textColor={AppConfiguration?.ContentTextColor}
                      id={item.ID}
                      menuTypeID={item.MenuTypeID}
                      link={item.Link}

                      {...(Menu[key - 1]?.DisplayIndex === 1 ? {
                        smallText: EnableTwoColumnsForMenu
                      } : {
                        column: EnableTwoColumnsForMenu,
                        paddingLeft: EnableTwoColumnsForMenu && key % 2 === 0,
                        borderRight: EnableTwoColumnsForMenu && key % 2 !== 0,
                        smallText: EnableTwoColumnsForMenu
                      })}
                    >
                      {item.Text}
                    </Item.Menu>
                  )
              ))}
            </div>
          </Fragment>
        )
      }

      {
        MapData && (
          <Fragment>
            <MoreOptionsBlock
              marginTop='1.38rem'
              menu={Menu}
              iconColor={AppConfiguration?.ContentIconsColor}
              textColor={AppConfiguration?.ContentTextColor}
              {...props}
            />
            <Map.Home
              marginTop='1.4rem'
              language={AppConfiguration?.Language || 'en'}
              core={core}
            />
          </Fragment>
        )
      }

      {
        FeedbackForm && (
          feedbackMessage
          ? (
            <FeedbackMessage />
          )
          : (
            <AntdForm
              onFinish={onFinishFeedback}
            >
              <Form.Feedback
                defaultRate={rate}
                callbackRate={changeRate}
                callbackSliders={changeSliders}
                core={core}
              />
              <div style={{ padding: '0 .6rem' }}>
                <AntdForm.Item
                  name='UserComment'
                >
                  <Textarea
                    text={intl.formatMessage(
                      {
                        id: 'feedback_label_comment',
                        defaultMessage: 'Do you have any other comments?'
                      },
                    )}
                    borderColor={AppConfiguration?.ContentIconsColor}
                    core={core}
                  />
                </AntdForm.Item>
              </div>
              <div style={{ padding: '0 .3rem' }}>
                <Button
                  htmlType="submit"
                  text={intl.formatMessage(
                      {
                        id: 'feedback_label_send',
                        defaultMessage: 'Do you have any other comments?'
                      },
                    )}
                  textColor={AppConfiguration?.ActionButtonsTextColor}
                  backgroundColor={AppConfiguration?.ActionButtonsBackgroundColor}
                  marginTop='1.8rem'
                />
              </div>
            </AntdForm>
          )
        )
      }
    </Fragment>
  )
}
