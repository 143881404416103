import React, { useState, Fragment, useContext, useRef } from 'react'
import { Form as FormAntd } from 'antd'
import { useIntl } from 'react-intl'

import { PlacesAutocomplete } from '../..'
import icon from '../../../../assets/images/pin.svg'
import { ThemeContext } from '../../../../utils'

export default ({
  activePanel,
  city,
  setCity,
  setAddress,
  setNumber,
  onChangeCity,
  setDisabledCity,
  setDisabledStreen,
  name = ['Address', 'City']
}) => {
  const [clear, setClear] = useState(false)

  const refInput = useRef(null)

  const intl = useIntl()

  const { Language, Country } = useContext(ThemeContext)

  const onInputChange = value => {
    setCity(value)
    setAddress('')
    setNumber('')
    setDisabledCity(false)
    setDisabledStreen(false)
  }

  const clearCallback = () => {
    setCity('')
    setAddress('')
    setNumber('')
    setDisabledCity(false)
    setDisabledStreen(false)

    if (refInput.current) {
      refInput.current.focus()
    }
  }

  return (
    <Fragment>
      <FormAntd.Item
        label={intl.formatMessage(
          {
            id: 'controls_label_city',
            defaultMessage: 'City'
          },
        ) + ' *'}
        name={name}
      >
        <PlacesAutocomplete
          tabindex="-1"
          withSessionToken={true}
          apiKey={`${process.env.REACT_APP_GOOGLE_KEY}&language=${Language}`}
          autocompletionRequest={{
            types: ['(cities)'],
            componentRestrictions: { country: Country || [] }
          }}
          _width='100%'
          clearIcon={city.length > 0 && clear}
          clearCallback={clearCallback}
          selectProps={{
            ref: refInput,
            placeholder: city,
            inputValue: city,
            defaultInputValue: city,
            isClearable: true,
            onChange: data => {
              onChangeCity(data)
            },
            onFocus: () => setClear(true),
            onBlur: () => setTimeout(() => setClear(false), 100),
            onInputChange: (v, { action }) => {
              if (action === 'input-change') {
                onInputChange(v)
                setClear(true)
              }
            },
            noOptionsMessage: () => '',
            formatOptionLabel: v => {
              return v.value.structured_formatting.main_text
            },
            components: {
              Option: (props) => {
                const {
                  children,
                  innerRef,
                  innerProps,
                } = props
                const search_regexp = new RegExp(city, 'ig')
                const newSrt = String(children).replace(search_regexp, `<span class='input-option__text-bold'>${city}</span>`)

                return <div className='input-option' ref={innerRef} {...innerProps}>
                  <img className='input-option__icon' src={icon} alt='pin icon' />
                  <div className='input-option__text'
                    dangerouslySetInnerHTML={{ __html: newSrt }}
                  />
                </div>
              },
            },
            styles: {
              indicatorSeparator: (provided, state) => ({
                ...provided,
                display: 'none !important',
              }),
              valueContainer: (provided, state) => ({
                ...provided,
                zIndex: 2,
                padding: 0,
                height: '100%',
              }),
              input: (provided, state) => ({
                ...provided,
                margin: 0,
                padding: 0,
                lineHeight: '3.8rem',
                width: '100%',
              }),
              menu: (provided, state) => ({
                ...provided,
                display: state.options <= 0 ? 'none' : 'block'
              }),
              option: (styles) => {
                return {
                  ...styles,
                  paddingLeft: '5rem',
                  position: 'relative',
                }
              },
              indicatorsContainer: (provided, state) => ({
                ...provided,
                display: 'none !important',
              }),
              singleValue: (provided, state) => ({
                ...provided,
                opacity: 0,
              }),
            },
          }}
        />
      </FormAntd.Item>
      <div className={`bung ${activePanel === 'city' && 'bung--visible'}`} />
    </Fragment>
  )
}
