import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'

import history from '../../../history'
import { MoreOptionsBlock } from './styles'
import { ThemeContext } from '../../../utils'

import { ArrowIcon, PlusIcon } from '../../../assets/svg'

const MORE_ITEM = {
  Text: 'More options',
  IconFile: PlusIcon,
  Icon: ''
}

export default ({
  menu = [],
  marginTop = 0,
  marginBottom = 0,
  match: {
    params: {
      tid = '1',
      pid = '1'
    } = {}
  } = {},
  ...props
}) => {
  const [menuArr, changeMenu] = useState([])

  const intl = useIntl()

  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const _changeMenu = () => {
    changeMenu([...new Array(...menu.filter(item => item.MenuTypeID !== 2)).splice(0, 3), MORE_ITEM])
  }

  useEffect(() => {
    _changeMenu()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu])

  return (
    <MoreOptionsBlock
      _marginTop={marginTop}
      _marginBottom={marginBottom}
      onClick={() => history.push(`/${tid}/${pid}/selectOption`)}
    >
      <MoreOptionsBlock.Link
        to='#'
        ContentIconsColor={ContentIconsColor}
        ContentTextColor={ContentTextColor}
      >
        <span
          dangerouslySetInnerHTML={{__html: intl.formatMessage(
            {
              id: 'menu_more_option_label',
              defaultMessage: 'You may perform the following actions'
            }
          )}}
        />
        <MoreOptionsBlock.LinkIcon><ArrowIcon /></MoreOptionsBlock.LinkIcon>
      </MoreOptionsBlock.Link>
      <MoreOptionsBlock.ActionContainer>
        {menuArr.map((item, key) => (
          <MoreOptionsBlock.Action
            key={key}
            ContentIconsColor={ContentIconsColor}
            ContentTextColor={ContentTextColor}
          >
            <span dangerouslySetInnerHTML={{ __html: intl.formatMessage(
              {
                id: item.Text,
                defaultMessage: item.Text
              }
            )}}/>
            <div className='icon'>{item.Icon}</div>
            {item.Text === 'More options' && <div className='icon'><PlusIcon /></div>}
          </MoreOptionsBlock.Action>
        ))}
      </MoreOptionsBlock.ActionContainer>
    </MoreOptionsBlock>
  )
}
