import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import Home from './Home'
import actions from '../../store/combineActions'

const mapStateToProps = state => ({
  isFetching: state.core.isFetching,
  feedbackMessage: state.core.feedbackMessage,
  core: state.core,
})

const mapDispatchToProps = dispatch => ({
  getPageInfo: (tid, pid, id) => dispatch(actions.core.getPageInfo(tid, pid, id)),
  submitFeedbackForm: (tid, pid, data) => dispatch(actions.core.submitFeedbackForm(tid, pid, data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home))

