import Date from './Date'
import Menu from './Menu'
import Opening from './Opening'
import Pickup from './Pickup'
import DeliveryDetails from './DeliveryDetails'

export default {
  Date,
  Menu,
  Opening,
  Pickup,
  DeliveryDetails,
}
