import AddressChange from './AddressChange'
import AddressNeighbor from './AddressNeighbor'
import LeaveOutside from './LeaveOutside'
import ContactCourier from './ContactCourier'
import ProcessPayment from './ProcessPayment'
import Feedback from './Feedback'

export default {
  AddressChange,
  AddressNeighbor,
  LeaveOutside,
  ContactCourier,
  ProcessPayment,
  Feedback,
}
