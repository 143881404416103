import React, { useEffect } from 'react'

import { Layout, Header } from '../../components/layout'
import { Spinner } from '../../components/blocks'
import { Details } from '../../components/views'

export default ({
  getPageInfo,
  tid,
  pid,
  isFetching,
  core: {
    Menu = false,
  } = {},
  core,
  ...rest
}) => {
  useEffect(() => {
    if (!Menu) {
      getPageInfo(tid, pid)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPageInfo, pid, tid])

  return (
    <Spinner
      isActive={isFetching}
      size='large'
    >
      <Layout
        home={false}
      >
        <Header
          home={false}
          defaultTitle='delivery_details_page_title'
          core={core}
          {...rest}
        />
        <Details
          {...rest}
          core={core}
        />
      </Layout>
    </Spinner>
  )
}
