import React, { useContext } from 'react'
import moment from 'moment'
import { useIntl } from 'react-intl'

import { ChangeDelivery } from './styles'
import { Item } from '..'
import { ThemeContext } from '../../../utils'
import history from '../../../history'

export default ({
  core: {
    AvailableDates = [],
    HeaderText = '',
    DeliveryDetails: {
      ScheduledDate = '2020-11-03T00:00:00.000'
    } = {},
  } = {},
  tid,
  pid,
  id,
  changeDate = () => console.log('@components/blocks/ChangeDelivery/List () => changeDate')
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()

  const onChange = date => {
    changeDate(date)
    history.push(`/${tid}/${pid}/${id}/confirm`)
  }

  return (
    <ChangeDelivery>
      <ChangeDelivery.Text
        className='title'
        _marginBottom='1.2rem'
        ContentTextColor={ContentTextColor}
        ContentIconsColor={ContentIconsColor}
        dangerouslySetInnerHTML={{__html: HeaderText}}
      />
      <ChangeDelivery.Text
        className='date'
        _marginBottom='1.8rem'
        ContentTextColor={ContentTextColor}
        ContentIconsColor={ContentIconsColor}
      >
        <div
          style={{ display: 'initial' }}
          dangerouslySetInnerHTML={{__html: intl.formatMessage(
            {
              id: 'change_date_label',
              defaultMessage: 'Current date:'
            },
          )}}
        />  {moment(ScheduledDate).format('dddd')}, <span>{moment(ScheduledDate).format('D.M.YYYY')}</span></ChangeDelivery.Text>
      {AvailableDates.map((item, key) => (
        <span
          onClick={() => onChange(item)}
          key={key}
        >
          <Item.Date
            borderTop={key === 0}
            day={moment(item).format('D')}
            ContentTextColor={ContentTextColor}
            ContentIconsColor={ContentIconsColor}
          >{`${moment(item).format('dddd') + ', ' + moment(item).format('D/M/YYYY')}`}</Item.Date>
        </span>
      ))}
    </ChangeDelivery>
  )
}
