import React, { useContext } from 'react'

import { ContactUs } from './styles'
import { ThemeContext } from '../../../utils'

export default ({
  tid,
  pid,
  core: {
    HeaderText,
  },
  setDeliveryStoreForVacation
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  return (
    <ContactUs>
      <ContactUs.Text
        ContentIconsColor={ContentIconsColor}
        ContentTextColor={ContentTextColor}
      >
        {HeaderText && <span
          className='title'
          dangerouslySetInnerHTML={{__html: HeaderText}}
        />}
        <div className='text'>{'Dear Customer, you may contact us via one of the following Channels.'}</div>
      </ContactUs.Text>

    </ContactUs>
  )
}
