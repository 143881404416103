import styled from 'styled-components'

import { convertColor } from '../../../utils'

export const FeedbackMessage = styled.div`
  margin: 4.4rem auto;
  width: 20rem;
  text-align: center;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-weight: 600;
  color: ${({ theme, ActionButtonsTextColor }) => ActionButtonsTextColor ? convertColor(ActionButtonsTextColor) : theme.colors.blue};
`
