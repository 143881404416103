import { createGlobalStyle } from 'styled-components'

import 'antd/dist/antd.css'

const zoom = localStorage.getItem('zoom')

const devicePixelRatio = window.devicePixelRatio === 3

if (devicePixelRatio || zoom === 'true') {
  document.documentElement.style.fontSize = '72.5%'
} else {
  document.documentElement.style.fontSize = '62.5%'
}

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'OpenSans';
    font-weight: 300;
    src: url(./fonts/OpenSans/OpenSans-Light.ttf) format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 400;
    src: url(./fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 600;
    src: url(./fonts/OpenSans/OpenSans-SemiBold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 700;
    src: url(./fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'OpenSans';
    font-weight: 800;
    src: url(./fonts/OpenSans/OpenSans-ExtraBold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'IconFont';
    src: url(./fonts/icons.ttf) format('truetype');
  }

  /* OpenSansHebrew */
  @font-face {
    font-family: 'OpenSansHebrew';
    font-weight: 300;
    src: url(./fonts/OpenSansHebrew/OpenSansHebrew-Light.ttf) format('truetype');
  }

  @font-face {
    font-family: 'OpenSansHebrew';
    font-weight: 400;
    src: url(./fonts/OpenSansHebrew/OpenSansHebrew-Regular.ttf) format('truetype');
  }

  @font-face {
    font-family: 'OpenSansHebrew';
    font-weight: 700;
    src: url(./fonts/OpenSansHebrew/OpenSansHebrew-Bold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'OpenSansHebrew';
    font-weight: 800;
    src: url(./fonts/OpenSansHebrew/OpenSansHebrew-ExtraBold.ttf) format('truetype');
  }

  @font-face {
    font-family: 'IconFont';
    src: url(./fonts/icons.ttf) format('truetype');
  }

  #root {
    height: 100%;
  }

  html {
    font-size: 62.5%;
  }

  body {
    position: relative;
    font: 1.4rem/1.4 'OpenSans';
    font-family: 'OpenSans';
    margin: 0;
    font-weight: 400;
    color: #666666;
    background-color: #f5f5f5;
    overflow-x: hidden;
  }

  * {
    box-sizing: border-box;
    touch-action: manipulation;
  }

  a {
    text-decoration: none;
    color: #07aaef;

    &:hover {
      color: #07aaef;
      opacity: .9;
    }
  }

  input {
    outline: none;
  }

  p {
    margin: 0;
  }

  .OpenSansHebrew {
    font-family: 'OpenSansHebrew';
  }

  ::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  .splash-screen {
    position: fixed;
    z-index: 999;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @-ms-viewport{ width: device-width; }

  .ant-select-dropdown {
    width: calc(100vw - 2.5rem) !important;
    max-width: ${({ theme }) => theme.maxWidth} !important;
    /* left: 0 !important; */
    padding: 0 !important;
    border-radius: 0 !important;
    background: transparent;
    box-shadow: unset;
    border: unset;

    ${window.innerWidth < 450
      ? `
        left: 1.3rem !important;
      `
      : `
        left: 50% !important;
        transform: translateX(-50%) !important;
      `
    }
  }

  .leaveOutside {
    & .ant-select-dropdown {
      width: auto !important;
      transition: unset !important;

      ${window.innerWidth < 450 &&
        `
          // transform: translateX(.3rem) !important;
          transition: unset !important;

          [dir='rtl'] & {
            transition: unset !important;
            // transform: translateX(-.3rem) !important;
            left: unset !important;
            // right: -.3rem;
          }
        `
      }
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: unset !important;
  }

  .ant-spin-container {
    overflow: hidden;
  }

  .no-scroll {
    overflow: hidden;
    background-color: #e2e2e2;
  }

  .error-message-form {
    margin-top: 2rem;
    color: #eb0a0a;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .title--bottom {
    margin-top: 2rem !important;
  }

  .feed {
    #root {
      height: calc(100vh - 11rem);
    }

    .ant-spin-nested-loading, .ant-spin-container, .sc-bdfBwQ, .sc-gsTCUz, .sc-gInsOo {
      height: 100%;
    }

    .sc-kYrkKh, .sc-ehSCib, .sc-fHuLdG, .sc-dFJsGO, .sc-biBrSq {
      flex: 1;
    }

    .ant-form {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .contact-btn-container {
      /* flex: 1; */
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: fixed;
      bottom: 1.5rem;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
      max-width: 48rem;
      padding: 0 1.5rem;
      background-color: #f5f5f5;
    }
  }

  .contact-btn-container {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    bottom: 1.5rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 48rem;
    padding: 0 1.5rem;
    background-color: #f5f5f5;
    z-index: 10;
    border-top: .1rem solid #d3d2d2;
  }

  .ant-spin-nested-loading {
    height: inherit;
  }

  .ant-spin-container {
    height: inherit;
  }
`
