import React, { useContext } from 'react'

import { Button } from './styles'
import { ThemeContext } from '../../../utils'

export default ({
  text = 'Button',
  type = 'primary',
  htmlType = 'submit',
  width = '100%',
  height = '4rem',
  marginTop = '0rem',
  marginRight = '0rem',
  marginBottom = '0rem',
  marginLeft = '0rem',
  noBorderRight = false,
  noBorderLeft = false,
  textCenter = false,
  ...rest
}) => {
  const { ActionButtonsBackgroundColor, ActionButtonsTextColor } = useContext(ThemeContext)

  return (
    <Button
      type={type}
      htmlType={htmlType}
      _width={width}
      _height={height}
      _margintop={marginTop}
      _marginright={marginRight}
      _marginbottom={marginBottom}
      _marginleft={marginLeft}
      _noBorderRight={noBorderRight}
      _noBorderLeft={noBorderLeft}
      _textCenter={textCenter}
      ActionButtonsTextColor={ActionButtonsTextColor}
      ActionButtonsBackgroundColor={ActionButtonsBackgroundColor}
      {...rest}
    >
      {text}
    </Button>
  )
}
