import React, { useState, useContext } from 'react'
import { Form as FormAntd } from 'antd'
import { geocodeByPlaceId } from 'react-google-places-autocomplete'
import { useIntl } from 'react-intl'

import { Form } from './styles'
import { FirstName, LastName, City, StreetName, StreetNumber, Entrance, FloorNumber, AptRoom, PhoneNumber, ButtonControl } from './internal'
import { ThemeContext } from '../../../utils'


export default ({
  callbackFormData,
  core: {
    FooterText
  },
}) => {
  const [activePanel, setActivePanel] = useState(false)
  const [searchStreetParam, setSearchStreetParam] = useState(false)
  const [postalCode, setPostalCode] = useState('')
  const [city, setCity] = useState('')
  const [address, setAddress] = useState('')
  const [number, setNumber] = useState('')
  const [geo, setGeo] = useState(false)
  const [showError, changeShowError] = useState(false)

    // disabled fields
    const [isDisabledCity, setDisabledCity] = useState(false)
    const [isDisabledStreen, setDisabledStreen] = useState(false)

  const intl = useIntl()

  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const onChangeCity = (data, e) => {
    geocodeByPlaceId(data?.value?.place_id)
      .then(results => {
        setCity(results[0].address_components.find(i => i.types.includes('locality'))?.long_name)
        setPostalCode(results[0].address_components.find(i => i.types.includes('postal_code'))?.long_name)
        setDisabledCity(results[0]?.geometry)
        setGeo({
          GeoLatitude: results[0].geometry.location.lat(),
          GeoLongitude: results[0].geometry.location.lng()
        })
        setAddress('')
        setNumber('')
        setSearchStreetParam({
          countryCode: results[0].address_components.find(i => i.types.includes('country'))?.short_name,
          country: results[0].address_components.find(i => i.types.includes('country'))?.long_name,
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng()
        })
      })
      .catch(error => setSearchStreetParam(false))
  }

  const onChangeStreet = (data) => {
    geocodeByPlaceId(data?.value?.place_id)
      .then(results => {
        setAddress(results[0].address_components.find(i => i.types.includes('route'))?.long_name)
        setPostalCode(results[0].address_components.find(i => i.types.includes('postal_code'))?.long_name)
        setDisabledStreen(results[0]?.geometry)
        setGeo({
          GeoLatitude: results[0].geometry.location.lat(),
          GeoLongitude: results[0].geometry.location.lng()
        })
        setNumber('')
      })
      .catch(error => setSearchStreetParam(false))
  }

  const onChangeNumber = (data) => {
    geocodeByPlaceId(data?.value?.place_id)
      .then(results => {
        setNumber(results[0].address_components.find(i => i.types.includes('street_number'))?.long_name)
        setPostalCode(results[0].address_components.find(i => i.types.includes('postal_code'))?.long_name)

        setGeo({
          GeoLatitude: results[0].geometry.location.lat(),
          GeoLongitude: results[0].geometry.location.lng()
        })
      })
      .catch(error => setSearchStreetParam(false))
  }

  const onFinish = values => {
    if (city === '' || address === '' || number === '' || !geo) {
      return onFinishFailed()
    }

    const data = {
      ...values,
      Address: {
        ...values?.Address,
        ZipCode: postalCode,
        City: city,
        StreetName: address,
        StreetNumber: number,
        State: searchStreetParam?.country,
        ...geo
      }
    }

    callbackFormData(data)
  }

  const onFinishFailed = errorInfo => {
    changeShowError(true)
  }

  return (
    <Form
      ContentIconsColor={ContentIconsColor}
      ContentTextColor={ContentTextColor}
      onClick={() => changeShowError(false)}
    >
      <FormAntd
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          _flex
        >
          <FirstName />
          <LastName />
        </Form.Item>

        <Form.Item
          _isActive={activePanel === 'city'}
        >
          <City
            activePanel={activePanel}
            city={city}
            setActivePanel={setActivePanel}
            setCity={setCity}
            onChangeCity={onChangeCity}
            setDisabledCity={setDisabledCity}
            setDisabledStreen={setDisabledStreen}
            setAddress={setAddress}
            setNumber={setNumber}
          />
        </Form.Item>

        <Form.Item
          _flex
          _isActive={activePanel === 'street'}
        >
          <StreetName
            activePanel={activePanel}
            searchStreetParam={searchStreetParam}
            address={address}
            city={city}
            isDisabledCity={isDisabledCity}
            setDisabledStreen={setDisabledStreen}
            setNumber={setNumber}
            setActivePanel={setActivePanel}
            setAddress={setAddress}
            onChangeStreet={onChangeStreet}
            name='StreetName'
          />
          <StreetNumber
            searchStreetParam={searchStreetParam}
            address={address}
            number={number}
            city={city}
            isDisabledStreen={isDisabledStreen}
            setActivePanel={setActivePanel}
            setNumber={setNumber}
            onChangeNumber={onChangeNumber}
          />
        </Form.Item>

        <Form.Item _flex>
          <Entrance />
          <FloorNumber />
          <AptRoom />
        </Form.Item>

        <Form.Item>
          <PhoneNumber />
        </Form.Item>

        {FooterText && <span
          className='title title--bottom'
          dangerouslySetInnerHTML={{__html: FooterText}}
        />}

        <div className='contact-btn-container'>

          {showError && <div className='error-message-form'>{
            intl.formatMessage(
              {
                id: 'message_failed_form',
                defaultMessage: '* Some info is missing'
              },
            )
          }</div>}

          <ButtonControl />
        </div>
      </FormAntd>
    </Form>
  )
}
