import React, { Fragment, useContext } from 'react'
import GoogleMap from 'google-map-react'

import { Map } from './styles'
import { ThemeContext } from '../../../../utils'

export default ({
  zoom = 10,
  language = 'en',
  marginTop = 0,
  marginBottom = 0,
  places = [],
  ...props
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds()

    places.forEach((place) => {
      var myLatLng = new maps.LatLng(place.lat, place.lng)
      // new maps.Marker({
      //   position: myLatLng,
      //   map: map,
      //   icon:{
      //     size: new maps.Size(70, 86), //marker image size
      //     origin: new maps.Point(0, 0), // marker origin
      //     anchor: new maps.Point(35, 86), // X-axis value (35, half of marker width) and 86 is Y-axis value (height of the marker)
      //     zIndex: 0
      //   }})
      bounds.extend(myLatLng)
    })

    return bounds
  }

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
      });
    });
  }

  const apiIsLoaded = (map, maps, places) => {
    if (map) {
      const bounds = getMapBounds(map, maps, places);
      map.fitBounds(bounds);
      map.setCenter(bounds.getCenter())
      bindResizeListener(map, maps, bounds);
    }
  }

  return (
    <Fragment>
      <Map
        _marginTop={marginTop}
        _marginBottom={marginBottom}
      >
        <Map.Container
          ContentIconsColor={ContentIconsColor}
          ContentTextColor={ContentTextColor}
          {...props}
        >
          <GoogleMap
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_KEY,
              language: language,
            }}
            defaultZoom={zoom}
            defaultCenter={[1, 1]}

            options={{
              zoomControl: false,
              fullscreenControl: false,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, places)}
          >
            {places.map(({ lat, lng, Icon, address }, key) =>
              <div
              key={key}
                lat={lat}
                lng={lng}
              >
                <div className={`crime-marker ${address && 'crime-marker--home'}`}>
                  <Icon />
                </div>
              </div>)}
          </GoogleMap>
        </Map.Container>
      </Map>
    </Fragment>
  )
}
