import { createContext, useContext } from 'react'

export const convertColor = (color) => `#${color}`.replace(/#(..)(......)/, '#$2$1')

export const convertDistance = (data, lang) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const languages = useContext(LangContext)
  let distance = parseFloat(data)

  if (distance < 1000) {
    return `${distance.toFixed(0)} ${languages?.[lang]?.['pickup_point_distance_m']}`
  } else {
    distance = distance / 1000

    return `${parseFloat(distance.toFixed(1)).toLocaleString('en')} ${languages?.[lang]?.['pickup_point_distance_km']}`
  }
}

export const ThemeContext = createContext()

export const LangContext = createContext()

export const TidPidContext = createContext()
