import React, { Fragment, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

export default ({ component: Component, computedMatch: { params = {}, path } = {}, ...rest }) => {
  const { tid, pid, id } = params

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [path])

  return (!!tid && !!pid)
    ? <Route {...rest} render={props => (
      <Fragment>
        <Component
          tid={tid}
          pid={pid}
          id={id}
          {...props}
        />
      </Fragment>
    )} /> : <Redirect to='/404' />
}
