import React, { Fragment, useEffect, useState, useContext } from 'react'
import GoogleMap from 'google-map-react'

import { Map } from './styles'
import { Button } from '../..'
import { ThemeContext } from '../../../../utils'

import { TrackPin, AddressPin } from '../../../../assets/svg'

export default ({
  core: {
    DeliveryDetails: {
      Address
    } = {},
    MapData = {},
  } = {},
  zoom = 10,
  language = 'en',
  marginTop = 0,
  marginBottom = 0,
}) => {
  const [places, setPlaces] = useState([])

  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  useEffect(() => {
    let pl = []

    if (MapData && MapData.DriverLatitude && MapData.DriverLongitude) {
      pl = [...pl, {
        lat: MapData?.DriverLatitude,
        lng: MapData?.DriverLongitude,
        Icon: TrackPin
      }]
    }

    if (Address && Address.GeoLatitude && Address.GeoLongitude) {
      pl = [...pl, {
        lat: Address?.GeoLatitude,
        lng: Address?.GeoLongitude,
        Icon: AddressPin,
        address: true
      }]
    }

    setPlaces(pl)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Address, MapData])

  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds()

    places.forEach((place) => {
      var myLatLng = new maps.LatLng(place.lat, place.lng)
      // new maps.Marker({
      //   position: myLatLng,
      //   map: map,
      //   icon:{
      //     size: new maps.Size(70, 86), //marker image size
      //     origin: new maps.Point(0, 0), // marker origin
      //     anchor: new maps.Point(35, 86), // X-axis value (35, half of marker width) and 86 is Y-axis value (height of the marker)
      //     zIndex: 0
      //   }})
      bounds.extend(myLatLng)
    })

    return bounds
  }

  // const bindResizeListener = (map, maps, bounds) => {
  //   maps.event.addDomListenerOnce(map, 'idle', () => {
  //     maps.event.addDomListener(window, 'resize', () => {
  //       map.fitBounds(bounds)
  //       map.setZoom(map.getZoom() - 1)
  //     });
  //   });
  // }

  const apiIsLoaded = (map, maps, places) => {
    const bounds = getMapBounds(map, maps, places)
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter())
    map.setZoom(map.getZoom() - 1)
    // bindResizeListener(map, maps, bounds)
  }

  return (
    <Fragment>
      <Map
        _marginTop={marginTop}
        _marginBottom={marginBottom}
      >
        <Map.Container ContentIconsColor={ContentIconsColor}>
          <GoogleMap
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_KEY,
              language: language,
            }}
            defaultZoom={zoom}
            defaultCenter={[1, 1]}

            options={{
              zoomControl: false,
              fullscreenControl: false,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, places)}
          >
            {places.map(({ lat, lng, Icon, address }, key) => <div
              className={`crime-marker ${address && 'crime-marker--home'}`}
              key={key}
              lat={lat}
              lng={lng}
            ><Icon /></div>)}
          </GoogleMap>
        </Map.Container>
        <Map.Text
          ContentTextColor={ContentTextColor}
          ContentIconsColor={ContentIconsColor}
          dangerouslySetInnerHTML={{ __html: MapData?.MapMessage }}
        />
      </Map>
      <div style={{ display: 'flex' }}>
        {MapData?.ContactUsButtonPhone && <Button
          type='default'
          text='Customer Support'
          noBorderRight={MapData?.ContactUsButtonPhone && MapData?.ShowContactUsButton}
          textCenter={true}
        />}
        {MapData?.ShowContactUsButton && <Button
          type='default'
          text='Contact Courier'
          noBorderLeft={MapData?.ShowContactUsButton && MapData?.ContactUsButtonPhone}
          textCenter={true}
        />}
      </div>
    </Fragment>
  )
}
