import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { message } from 'antd'
// import { IntlProvider } from 'react-intl'

import { theme } from './assets/theme'
import store from './store'
import App from './Router'
import { TidPidContext } from './utils'

message.config({
  duration: 4,
  maxCount: 1,
  rtl: true,
})

render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      {/* <IntlProvider locale={'en'} messages={{}}> */}
        <TidPidContext.Provider value={window.location.pathname.split('/').filter(i => i !== '')}>
          <App />
        </TidPidContext.Provider>
      {/* </IntlProvider> */}
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)
