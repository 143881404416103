import styled from 'styled-components'

import { convertColor } from '../../../utils'

export const ContactCourier = styled.div`
  padding: 0 .6rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  display: block;
  padding-bottom: ${({ _paddingBottom }) => _paddingBottom ? `${_paddingBottom + 10}px` : '4rem' };
`

ContactCourier.Text = styled.div`
  display: block;

  .title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.black};
    padding: 0 0 .5rem;
  }

  .text {
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};

    &--color {
      color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    }

    &--color-black {
      color: ${({ theme }) => theme.colors.black};
    }

    &--bold {
      font-weight: 600;
    }

    &--note {
      font-size: 1.4rem;
      line-height: 2.1rem;
      color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};

      span {
        font-weight: 600;
      }
    }
  }
`
