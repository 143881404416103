import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

import reducers from './combineReducers'

const middlewares = [thunk]

/**
 * Middlewares only for dev mode
 */
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger)
}

const store = createStore(reducers, applyMiddleware(...middlewares))

export default store

export const getFromStore = () => {
  return store.getState()
}
