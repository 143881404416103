import React, { useContext, useEffect } from 'react'
import moment from 'moment'
import { useIntl } from 'react-intl'

import { ChangeDelivery } from './styles'
import { Button } from '..'
import { ThemeContext } from '../../../utils'
import history from '../../../history'

export default ({
  core: {
    FooterText = '',
  } = {},
  selectedDate = '2020-11-03T00:00:00.000',
  changeDate,
  confirm
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()

  const goBack = () => {
    history.goBack()
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    window.scrollTo(0, 0)

    return () => document.body.style.overflow = 'auto'
  }, [])

  return (
    <ChangeDelivery>
      <ChangeDelivery.Text
        className='text'
        _marginTop='13rem'
        ContentTextColor={ContentTextColor}
        ContentIconsColor={ContentIconsColor}
      >{intl.formatMessage(
        {
          id: 'change_delivery_confirm',
          defaultMessage: 'The delivery date will be updated to'
        },
      )}<span>{moment(selectedDate).format('DD/M/YYYY')}</span></ChangeDelivery.Text>

      <div style={{ flex: 1, display: 'flex', alignItems: 'flex-end', paddingBottom: '3rem' }}>
        <ChangeDelivery.Text
          className='date'
          _marginBottom='1.8rem'
          ContentTextColor={ContentTextColor}
          ContentIconsColor={ContentIconsColor}
          dangerouslySetInnerHTML={{__html: FooterText}}
        />
      </div>

      <div className='contact-btn-container'>
        <div style={{ display: 'flex', paddingTop: '1rem' }}>
          <Button
            type='primary'
            text={intl.formatMessage(
              {
                id: 'controls_label_approve',
                defaultMessage: 'Approve'
              },
            )}
            noBorderRight={true}
            textCenter={true}
            onClick={confirm}
          />
          <Button
            type='default'
            text={intl.formatMessage(
              {
                id: 'controls_label_cancel',
                defaultMessage: 'Cancel'
              },
            )}
            noBorderLeft={true}
            textCenter={true}
            onClick={goBack}
          />
        </div>
      </div>
    </ChangeDelivery>
  )
}
