import styled from 'styled-components'

import { convertColor } from '../../../utils'

export const ChangeDelivery = styled.div`
  padding: 1rem .6rem 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: calc(100vh - ${window.devicePixelRatio === 3 ? '17rem' : '13rem'});
`

ChangeDelivery.Text = styled.div`
  margin-top: ${({ _marginTop = 0 }) => _marginTop};
  margin-bottom: ${({ _marginBottom = 0 }) => _marginBottom};

  &.title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.black};
  }

  &.date {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 600;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
  }

  &.text {
    text-align: center;
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};

     span {
      font-weight: 600;
     }
  }

  span {
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.blue};
  }
`
