import React, { Fragment, useState, useRef, useContext, memo } from 'react'
import GoogleMap from 'google-map-react'
import useSupercluster from 'use-supercluster'

import { MapPickup } from './styles'
import { ThemeContext } from '../../../../utils'

import { StorePin, PudoPin, LockerPin, HomePin } from '../../../../assets/svg'

const CATEGORY = {
  1: 'Store',
  2: 'PUDO',
  3: 'Locker',
}

const CATEGORY_ICON = {
  1: <StorePin />,
  2: <PudoPin />,
  3: <LockerPin />,
}

export default memo(({
  points: places,
  language = 'en',
  marginTop = 0,
  marginBottom = 0,
  homeLocation = null,
  setShowSelectPanel,
  titleSize,
  ...props
}) => {
  const [points] = useState(
    places.map((item) => ({
      type: 'Feature',
      typeId: item.TypeID,
      id: item.ID,
      properties: {
        cluster: false,
        crimeId: item.ID,
        category: CATEGORY[item.TypeID]
      },
      geometry: {
        type: 'Point',
        coordinates: [item?.Address?.GeoLongitude, item?.Address?.GeoLatitude],
      },
    }))
  )
  const [selectedPoint, setSelectedPoint] = useState(false)
  const [bounds, setBounds] = useState(null)
  const [zoom, setZoom] = useState(10)
  const mapRef = useRef()

  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  })

  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds()

    places.forEach((place) => {
      var myLatLng = new maps.LatLng(place.lat, place.lng)
      // new maps.Marker({
      //   position: myLatLng,
      //   map: map,
      //   icon:{
      //     size: new maps.Size(70, 86), //marker image size
      //     origin: new maps.Point(0, 0), // marker origin
      //     anchor: new maps.Point(35, 86), // X-axis value (35, half of marker width) and 86 is Y-axis value (height of the marker)
      //     zIndex: 0
      //   }})
      bounds.extend(myLatLng)
    })

    return bounds
  }

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds)
      })
    })
  }

  const apiIsLoaded = (map, maps, places) => {
    const bounds = getMapBounds(map, maps, places)
    map.fitBounds(bounds)
    bindResizeListener(map, maps, bounds)
  }

  return (
    <Fragment>
      <MapPickup
        _marginTop={marginTop}
        _marginBottom={marginBottom}
        _titleSize={titleSize}
      >
        <MapPickup.Container
          ContentIconsColor={ContentIconsColor}
          ContentTextColor={ContentTextColor}
          {...props}
        >
          <GoogleMap
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_KEY,
              language: language,
            }}
            defaultZoom={zoom}
            defaultCenter={{
              lat: 1,
              lng: 1,
            }}
            options={{
              zoomControl: false,
              fullscreenControl: false,
            }}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              apiIsLoaded(
                map,
                maps,
                [
                  ...places.map((item) => ({
                    lat: item.Address.GeoLatitude,
                    lng: item.Address.GeoLongitude,
                  })),
                  {
                    lat: homeLocation?.lat,
                    lng: homeLocation?.lng,
                  }
                ]
              )
              mapRef.current = map
            }}
            onChange={({ zoom, bounds }) => {
              setZoom(zoom)
              setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat,
              ])
            }}
          >
            {clusters.map((cluster) => {
              const [longitude, latitude] = cluster.geometry.coordinates
              const {
                cluster: isCluster,
                point_count: pointCount,
              } = cluster.properties

              if (isCluster) {
                return (
                  <div
                    key={`cluster-${cluster.id}`}
                    lat={latitude}
                    lng={longitude}
                  >
                    <div
                      className='cluster-marker'
                      style={{
                        width: `${20 + (pointCount / points.length) * 20}px`,
                        height: `${20 + (pointCount / points.length) * 20}px`,
                      }}
                      onClick={() => {
                        const expansionZoom = Math.min(
                          supercluster.getClusterExpansionZoom(cluster.id),
                          20
                        )
                        mapRef.current.setZoom(expansionZoom)
                        mapRef.current.panTo({ lat: latitude, lng: longitude })
                      }}
                    >
                      {pointCount}
                    </div>
                  </div>
                )
              }

              return (
                <div
                  key={`crime-${cluster.properties.crimeId}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className={`crime-marker ${cluster.id === selectedPoint && 'crime-marker--selected'}`}
                    onClick={() => {
                      setShowSelectPanel(places.find(i => i.ID === cluster.id))
                      // window.scrollTo(0, document.body.scrollHeight)
                      setSelectedPoint(cluster.id === selectedPoint ? false : cluster.id)
                    }}
                  >
                    {CATEGORY_ICON[cluster.typeId]}
                  </div>
                </div>
              )
            })}
            {homeLocation && (
               <div
                lat={homeLocation?.lat}
                lng={homeLocation?.lng}
              >
                <div className='crime-marker crime-marker--home'>
                  <HomePin />
                </div>
              </div>
            )}
          </GoogleMap>
        </MapPickup.Container>
      </MapPickup>
    </Fragment>
  )
})
