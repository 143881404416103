import styled from 'styled-components'

import { convertColor } from '../../../utils'

export const ChangeAddress = styled.div`
  padding: 0 .6rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  display: block;
  padding-bottom: ${({ _paddingBottom }) => _paddingBottom ? `${_paddingBottom + 10}px` : '4rem' };
`

ChangeAddress.Text = styled.div`
  display: block;

  .title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.black};
    padding: 0 0 .5rem;
  }

  .text {
    font-size: 1.4rem;
    line-height: 2.1rem;
    color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.gray};

    &--color {
      font-weight: 600;
      color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    }

    &--note {
      font-size: 1.2rem;
      line-height: 1.8rem;
      color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
      padding-top: 1rem;
      margin-top: 1rem;
      border-top: .1rem solid #46474c;

      span {
        font-weight: 600;
      }
    }
  }
`
