import React, { Fragment } from 'react'

import { ProcessPayment } from './styles'
import { Form } from '../../blocks'

export default ({
  tid,
  pid,
  core: {
    DeliveryDetails: {
      PaymentRequiresMailInvoiceDetails
    },
    HeaderOrLink,
    Menu,
  },
  core,
  setInvoiceContactDetails
}) => {
  const callbackFormData = data => setInvoiceContactDetails(tid, pid, data)
  const link = Menu?.find(i => i.MenuTypeID === 13)?.Link

  React.useEffect(() => {
    document.body.classList.add('feed')
    document.body.style.overflow = 'hidden'
    window.scrollTo(0, 0)

    return () => {
      document.body.classList.remove('feed')
      document.body.style.overflow = 'auto'
      window.scrollTo(0, 0)
    }
  }, [])

  const [paddingBottom, setPaddingBottom] = React.useState(40)
  const createRef = r => {
    setPaddingBottom(r?.querySelector('.contact-btn-container')?.clientHeight || 4)
  }

  return (
    <ProcessPayment _paddingBottom={paddingBottom} ref={createRef} >
      {
        !PaymentRequiresMailInvoiceDetails
        ? (
          <ProcessPayment.Iframe src={link}></ProcessPayment.Iframe>
        )
        : (
          <Fragment>
            <Form.ProcessPayment
              callbackFormData={callbackFormData}
              core={core}
            />
          </Fragment>
        )
      }

    </ProcessPayment>
  )
}
