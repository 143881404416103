import update from 'immutability-helper'

import {
  USER_AUTHENTICATE,
} from './types'

const initState = {
  isAuthenticated: null,
}

function reducer (state = initState, action) {
  switch (action.type) {
    case USER_AUTHENTICATE:
      return update(state, { isAuthenticated: { $set: action.payload } })

    default:
      return state
  }
}

export default reducer
