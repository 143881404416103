import React, { useEffect } from "react";

import { Layout, Header, Footer } from "../../components/layout";
import { Spinner } from "../../components/blocks";
import { Home } from "../../components/views";

export default ({ getPageInfo, tid, pid, isFetching, ...rest }) => {
  useEffect(() => {
    getPageInfo(tid, pid);
  }, [getPageInfo, pid, tid]);

  return (
    <Spinner isActive={isFetching} size="large" style={{ height: 'inherit' }}>
      <Layout home={true}>
        <Header home={true} {...rest} />
        <Home tid={tid} pid={pid} {...rest} />

        <Footer />
      </Layout>
    </Spinner>
  );
};
