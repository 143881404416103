import React, { useContext } from 'react'

import { OpeningItem } from './styles'
import { ThemeContext } from '../../../../utils'

export default ({
  data = {}
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  return (
    <OpeningItem
      ContentIconsColor={ContentIconsColor}
      ContentTextColor={ContentTextColor}
    >
      <div className='day'>{data?.DaysText}</div>
      <div className='times'>
        {data?.Times.map((item, key) => (
          <span key={key}>{item.TimesText}</span>
        ))}
      </div>
    </OpeningItem>
  )
}
