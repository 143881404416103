import React, { useState, useEffect, useContext, useRef } from "react";
import { useIntl } from "react-intl";
import { geocodeByPlaceId } from "react-google-places-autocomplete";

import { PickupPoint } from "./styles";
import { PlacesAutocomplete, Select, Tabs, Button, Item, Map } from "..";
import { CheckIcon, ArrowIconSelect } from "../../../assets/svg";
import history from "../../../history";
import { convertDistance, ThemeContext } from "../../../utils";

const FILTER_TYPE = {
  1: "Store",
  2: "PUDO",
  3: "Locker",
};

export default ({
  callbackFilter = () =>
    console.log("@components/block/PickupPoint callbackFilter"),
  lastPlaceholder,
  setLastPlaceholder,
  filters = {},
  tabActive,
  changeTab,
  setSelectedPoint,
  setTitle,
  setTitleDefault,
  link,
  core: {
    HeaderText,
    AppConfiguration = {},
    PickupPoints = [],
    DeliveryDetails = {},
    Title,
    TitleDefault,
    MenuTypeID,
  } = {},
}) => {
  const [showFilter, setShowFilter] = useState([]);
  const [showSelectPanel, setShowSelectPanel] = useState(false);
  const [clearIcon, setClearIcon] = useState(false);
  const [addressPlaceholder, changeAddressPlaceholder] =
    useState(lastPlaceholder);

  const intl = useIntl();

  const { ContentIconsColor, ContentTextColor, Language, Country } =
    useContext(ThemeContext);

  const refTitle = useRef();
  const refInput = useRef();

  const _setShowSelectPanel = (point) => {
    setShowSelectPanel(showSelectPanel?.ID === point.ID ? false : point);
  };

  const _setFilterType = (type) => {
    callbackFilter({ type });
  };

  const _setSelectedPoint = (data) => {
    setSelectedPoint(data);
    setTitle("Pickup point details");
    history.push(`${link}/details`);
  };

  const _setFilterLocation = (data) => {
    geocodeByPlaceId(data.value.place_id)
      .then((results) => {
        callbackFilter({
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng(),
          // noReload: false,
        });

        changeAddressPlaceholder(data.label);
        setLastPlaceholder(data.label);
      })
      .catch((error) => console.error(error));
  };

  const clearCallback = () => {
    changeAddressPlaceholder("");
    // setLastPlaceholder('')

    if (refInput.current) {
      refInput.current.focus();
    }
  };

  useEffect(() => {
    if (MenuTypeID !== 8 && MenuTypeID !== 9 && MenuTypeID !== 10) {
      setShowFilter(
        PickupPoints.reduce((acc, item) => {
          let typeId = item.TypeID;
          let isType = !acc.includes(typeId);

          if (isType) {
            return [...acc, typeId];
          }

          return acc;
        }, [])
      );

    }

    if (Title !== "Pickup point details") {
      setTitleDefault(Title);
    } else {
      setTitle(TitleDefault);
    }
  }, [
    MenuTypeID,
    PickupPoints,
    Title,
    TitleDefault,
    setTitle,
    setTitleDefault,
  ]);

  return (
    <PickupPoint _titleSize={refTitle.current?.clientHeight}>
      {/* FILTERS */}
      {HeaderText && (
        <span
          className="title"
          ref={refTitle}
          dangerouslySetInnerHTML={{ __html: HeaderText }}
        />
      )}
      <PickupPoint.FormContainer>
        <PickupPoint.FormItem
          style={{
            width: `${showFilter.length >= 1 ? "calc(100% - 9.2rem)" : "100%"}`,
          }}
        >
          <PickupPoint.FormLabel ContentTextColor={ContentTextColor}>
            {intl.formatMessage({
              id: "Address *",
              defaultMessage: "Address *",
            })}
          </PickupPoint.FormLabel>
          <PlacesAutocomplete
            _width={showFilter.length >= 1 ? false : "100%"}
            // _padding
            withSessionToken={true}
            apiKey={`${process.env.REACT_APP_GOOGLE_KEY}&language=${Language}`}
            autocompletionRequest={{
              types: ["address"],
              componentRestrictions: { country: Country || [] },
            }}
            ContentIconsColor={ContentIconsColor}
            ContentTextColor={ContentTextColor}
            clearIcon={addressPlaceholder.length > 0 && clearIcon}
            clearCallback={clearCallback}
            selectProps={{
              classNamePrefix: "react-select",
              ref: refInput,
              onFocus: () => setClearIcon(true),
              onBlur: () => {
                setTimeout(() => setClearIcon(false), 100);
                window.scrollTo(0, 1);
              },
              placeholder: addressPlaceholder,
              inputValue: addressPlaceholder,
              defaultInputValue: addressPlaceholder,
              onInputChange: (v, { action }) => {
                if (action === "input-change") {
                  changeAddressPlaceholder(v);
                  setClearIcon(true);
                }
              },
              onChange: _setFilterLocation,
              components: () => <React.Fragment />,
              styles: {
                valueContainer: (provided, state) => ({
                  ...provided,
                  zIndex: 2,
                  padding: 0,
                  height: "100%",
                }),
                menu: (provided, state) => ({
                  ...provided,
                  width: "calc(100vw - 2.5rem)",
                  maxWidth: "45.5rem",
                  borderRadius: 0,
                  boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.3)",
                  border: "solid 1px #979797",
                  top: "4.9rem",
                  display: state.options <= 0 ? "none" : "block",
                }),
                input: (provided, state) => ({
                  ...provided,
                  margin: 0,
                  padding: 0,
                  lineHeight: "3.8rem",
                  width: "100%",
                }),
                singleValue: (provided, state) => ({
                  ...provided,
                  opacity: 0,
                }),
                indicatorsContainer: (provided, state) => ({
                  ...provided,
                  display: "none !important",
                }),
              },
            }}
          />
        </PickupPoint.FormItem>
        {(filters?.type || showFilter.length > 1) && (
          <PickupPoint.FormItem style={{ maxWidth: "9rem" }}>
            <PickupPoint.FormLabel ContentTextColor={ContentTextColor}>
              {intl.formatMessage({
                id: "controls_label_type",
                defaultMessage: "Type",
              })}
            </PickupPoint.FormLabel>
            <Select
              ContentIconsColor={ContentIconsColor}
              ContentTextColor={ContentTextColor}
              value={filters?.type}
              suffixIcon={<ArrowIconSelect />}
              dropdownRender={(menu) => {
                return (
                  <Select.Container _pickup>
                    {menu.props.options.map((item) => (
                      <Select.Item
                        key={item.key}
                        _active={item.value === filters?.type}
                        ContentIconsColor={ContentIconsColor}
                        ContentTextColor={ContentTextColor}
                        onClick={() => {
                          _setFilterType(item.value);
                          menu.props.onToggleOpen();
                        }}
                      >
                        <span className="icon">
                          {item.value === filters?.type && <CheckIcon />}
                        </span>
                        <span>{item.children}</span>
                      </Select.Item>
                    ))}
                  </Select.Container>
                );
              }}
            >
              <Select.Option value={null}>
                {intl.formatMessage({
                  id: "controls_label_all",
                  defaultMessage: "All",
                })}
              </Select.Option>
              {showFilter.map((item, key) => (
                <Select.Option key={key + item} value={item}>
                  {intl.formatMessage({
                    id: FILTER_TYPE[item],
                    defaultMessage: FILTER_TYPE[item],
                  })}
                </Select.Option>
              ))}
            </Select>
          </PickupPoint.FormItem>
        )}
      </PickupPoint.FormContainer>

      <Tabs
        defaultActiveKey={tabActive}
        onChange={(key) => changeTab(key)}
        ContentIconsColor={ContentIconsColor}
        ContentTextColor={ContentTextColor}
      >
        {/* LIST */}
        <Tabs.TabPane
          tab={intl.formatMessage({
            id: "controls_label_list_view",
            defaultMessage: "List View",
          })}
          key="1"
        >
          <PickupPoint.ListContainer
            _titleSize={refTitle.current?.clientHeight}
          >
            {PickupPoints.map((item, key) => (
              <Item.Pickup
                key={key}
                onClick={() => _setSelectedPoint(item)}
                {...item}
              />
            ))}
          </PickupPoint.ListContainer>
        </Tabs.TabPane>

        {/* MAP */}
        <Tabs.TabPane
          tab={intl.formatMessage({
            id: "controls_label_map_view",
            defaultMessage: "Map View",
          })}
          key="2"
        >
          <Map.Pickup
            points={PickupPoints}
            setShowSelectPanel={_setShowSelectPanel}
            titleSize={refTitle.current?.clientHeight}
            homeLocation={{
              lat: filters?.latitude || DeliveryDetails?.Address?.GeoLatitude,
              lng: filters?.longitude || DeliveryDetails?.Address?.GeoLongitude,
            }}
            language={AppConfiguration?.Language}
          />
        </Tabs.TabPane>
      </Tabs>

      {/* BUTTONS */}
      <PickupPoint.ButtonPanel>
        <Button
          text={intl.formatMessage({
            id: "controls_label_cancel",
            defaultMessage: "Cancel",
          })}
          type="default"
          onClick={() => history.goBack()}
        />

        {/* SELECT PANEL */}
        <PickupPoint.SelectPanel
          _show={Number(tabActive) === 2 && !!showSelectPanel}
          ContentIconsColor={ContentIconsColor}
        >
          <PickupPoint.SelectPanelText
            ContentTextColor={ContentTextColor}
            ContentIconsColor={ContentIconsColor}
          >
            <span className="name">{showSelectPanel?.Name}</span>
            <span className="address">
              {showSelectPanel?.Address?.City}{" "}
              {showSelectPanel?.Address?.StreetName}{" "}
              {showSelectPanel?.Address?.StreetNumber}
            </span>
            <span className="distance">
              {convertDistance(showSelectPanel?.Distance || 0, Language)}
            </span>
          </PickupPoint.SelectPanelText>

          <div
            className="button"
            onClick={() => _setSelectedPoint(showSelectPanel)}
          >
            {intl.formatMessage({
              id: "controls_label_select",
              defaultMessage: "Select",
            })}
          </div>
        </PickupPoint.SelectPanel>
      </PickupPoint.ButtonPanel>
    </PickupPoint>
  );
};
