import React, { useContext } from 'react'

import { Page404 } from './styles'
import { Illustration } from '../../../assets/svg'
import { LangContext } from '../../../utils'

export default () => {
  const languages = useContext(LangContext)

  const lang = window.navigator.language.slice(0, 2)

  lang === 'en' ? document.body.classList.remove('OpenSansHebrew') : document.body.classList.add('OpenSansHebrew')
  return (
    <Page404>
      <Illustration />
      <Page404.Message>{languages?.[lang]?.['404_message']}</Page404.Message>
    </Page404>
  )
}
