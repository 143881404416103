import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { convertColor } from '../../../utils'

export const Delivery = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

Delivery.Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ _marginBottom }) => _marginBottom || '1rem'};;

  .col {
    width: 50%;
    display: flex;
    flex-direction: column;

    &:first-child {
      padding-right: .5rem;
    }

    &:last-child {
      text-align: right;
      padding-left: .5rem;
    }

    &--delivery {
      width: unset;
      flex: 1;
    }
  }
`

Delivery.Text = styled.div`
  font-size: ${({ _size }) => _size || '1.2rem'};
  line-height: 1.8rem;
  color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
  width: ${({ _width }) => _width || 'unset'};

  ${props => props._bold && `
    font-weight: 600;
  `}

  @media screen and (min-width: 386px) {
    width: 100%;
  }
`

Delivery.Link = styled(Link)`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 600;
  color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
`
