import React, { useEffect, useState } from "react";
import { Switch, Router, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { StyleSheetManager } from "styled-components";
import rtlcss from "stylis-rtlcss";
import { IntlProvider } from "react-intl";
import moment from "moment";
import { ConfigProvider } from "antd";
import "moment/locale/he";
import "moment/locale/es";
import "moment/locale/el";

import { GlobalStyle } from "./assets";
import { Home, Page404, SelectOption, Details, ViewComponent } from "./pages";
import { Spinner, CheckRoute } from "./components/blocks";
import history from "./history";
import actions from "./store/combineActions";
import { ThemeContext, LangContext } from "./utils";

const RoutersApp = ({
  checkAuthenticate,
  isAuthenticated = false,
  core: {
    AppConfiguration: { Language = "en" } = {},
    AppConfiguration = {},
  } = {},
}) => {
  const [locale, setLocale] = useState("en");
  const [locales, setLocales] = useState({});

  moment.locale(Language);

  useEffect(() => {
    checkAuthenticate();
  }, [checkAuthenticate]);

  useEffect(() => {
    Language === "he"
      ? document.body.classList.add("OpenSansHebrew")
      : document.body.classList.remove("OpenSansHebrew");

    setLocale(Language);
    localStorage.setItem("lastLanguage", Language);
  }, [Language]);

  useEffect(() => {
    fetch("/en.json")
      .then((response) => response.json())
      .then((json) => setLocales(locales => ({ ...locales, en: json })));

    fetch("/el.json")
      .then((response) => response.json())
      .then((json) => setLocales(locales => ({ ...locales, el: json })));

    fetch("/es.json")
      .then((response) => response.json())
      .then((json) => setLocales(locales => ({ ...locales, es: json })));

    fetch("/he.json")
      .then((response) => response.json())
      .then((json) => setLocales(locales => ({ ...locales, he: json })));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(locales);

  return isAuthenticated !== null ? (
    <StyleSheetManager
      {...(Language === "he" ? { stylisPlugins: [rtlcss] } : {})}
    >
      <ConfigProvider direction={Language === "he" ? "rtl" : "ltr"}>
        <React.Fragment>
          <Helmet
            htmlAttributes={{
              lang: Language,
              dir: Language === "he" ? "rtl" : "ltr", // rtl
            }}
          />
          <GlobalStyle />
          <ThemeContext.Provider value={AppConfiguration}>
            <LangContext.Provider value={locales}>
              <IntlProvider locale={Language} messages={locales[locale]}>
                <Router history={history}>
                  <Switch>
                    <CheckRoute
                      path="/:tid/:pid/selectOption"
                      component={SelectOption}
                    />
                    <CheckRoute path="/:tid/:pid/details" component={Details} />
                    <CheckRoute
                      path="/:tid/:pid/:id"
                      component={ViewComponent}
                    />
                    <CheckRoute path="/:tid/:pid" exact component={Home} />
                    <Route path="*" component={Page404} />
                  </Switch>
                </Router>
              </IntlProvider>
            </LangContext.Provider>
          </ThemeContext.Provider>
        </React.Fragment>
      </ConfigProvider>
    </StyleSheetManager>
  ) : (
    <div style={styleSplashScreen}>
      <Spinner isActive={true} size="large" />
    </div>
  );
};

const styleSplashScreen = {
  position: "fixed",
  zIndex: 999,
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.user.isAuthenticated,
  core: state.core,
});

const mapDispatchToProps = (dispatch) => ({
  checkAuthenticate: () => dispatch(actions.user.checkAuthenticate()),
});

export default connect(mapStateToProps, mapDispatchToProps)(RoutersApp);
