import queryString from 'query-string'

import http from './http'

export const getPageInfo = (tid, pid, id) => {
  return http({
    url: `/GetPage/${tid}/${pid}${id ? `/${id}` : ''}`,
    method: 'GET',
  })
}

export const setDeliveryDate = (tid, pid, data) => {
  return http({
    url: `/SetDeliveryDate/${tid}/${pid}`,
    method: 'POST',
    data
  })
}

export const getPickupPoints = (tid, pid, filters) => {
  return http({
    url: `/GetPickupPoints/${tid}/${pid}?${queryString.stringify(filters)}`,
    method: 'GET',
  })
}

export const setPickupPoint = (tid, pid, data) => {
  return http({
    url: `/SetPickupPoint/${tid}/${pid}`,
    method: 'POST',
    data
  })
}

export const setDeliveryDeliverToNeighbor = (tid, pid, data) => {
  return http({
    url: `/SetDeliveryDeliverToNeighbor/${tid}/${pid}`,
    method: 'POST',
    data
  })
}

export const setAddress = (tid, pid, data) => {
  return http({
    url: `/SetAddress/${tid}/${pid}`,
    method: 'POST',
    data
  })
}

export const setDeliveryLeaveOutside = (tid, pid, data) => {
  return http({
    url: `/SetDeliveryLeaveOutside/${tid}/${pid}`,
    method: 'POST',
    data
  })
}

export const setDeliveryStoreForVacation = (tid, pid, data) => {
  return http({
    url: `/SetDeliveryStoreForVacation/${tid}/${pid}`,
    method: 'POST',
    data
  })
}

export const setDeliveryCourierMessage = (tid, pid, data) => {
  return http({
    url: `/SetDeliveryCourierMessage/${tid}/${pid}`,
    method: 'POST',
    data
  })
}

export const setInvoiceContactDetails = (tid, pid, data) => {
  return http({
    url: `/SetInvoiceContactDetails/${tid}/${pid}`,
    method: 'POST',
    data
  })
}

export const submitFeedbackForm = (tid, pid, data) => {
  return http({
    url: `/SubmitFeedbackForm/${tid}/${pid}`,
    method: 'POST',
    data
  })
}

export const reqPost = data => {
  return http({
    url: 'https://login.windows.net/hormigatest.onmicrosoft.com/oauth2/v2.0/token',
    method: 'POST',
    data
  })
}
