import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
import { useIntl } from 'react-intl'

import { Delivery } from './styles'
import { StepsDelivery } from '..'
import { ThemeContext } from '../../../utils'

const UPDATE_MESSAGES = {
  3: 'Drop-off address was updated',
  4: 'A neighbor was selected',
  5: 'Delivery date was updated',
  6: 'Leaving outside was selected',
  7: 'A pickup point was selected',
  8: 'A locker for pickup was selected',
  9: 'A PUDO for pickup was selected',
  10: 'A store for pickup was selected',
  11: 'Storing during vacation',
  12: 'Payment status was updated',
}

export default ({
  core: {
    DeliveryDetails = {},
    Menu = [],
  } = {},
  core,
  tid,
  pid
}) => {
  const [message, setMessage] = useState([])

  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()

  useEffect(() => {
    const LastUpdateType = DeliveryDetails?.LastUpdateType

    if (LastUpdateType) {
      setMessage(Menu.filter(i => i.MenuTypeID === LastUpdateType).map(i => i.MenuTypeID))
    }
  }, [DeliveryDetails, Menu])

  console.log('message', message);

  return (
    <Delivery>
      <Delivery.Row>
        <div className='col'>
          <Delivery.Text
            _bold
            ContentTextColor={ContentTextColor}
            dangerouslySetInnerHTML={{__html: intl.formatMessage(
              {
                id: 'delivery_details_consignor',
                defaultMessage: 'Consignor'
              },
            )}}
          />
          <Delivery.Text
            ContentTextColor={ContentTextColor}
            dangerouslySetInnerHTML={{ __html: DeliveryDetails?.Consignor || '&nbsp;' }}
          />
          <Delivery.Text ContentTextColor={ContentTextColor}>{DeliveryDetails?.TrackingNumber || '1234567897821'}</Delivery.Text>
        </div>
        <div className='col'>
          <Delivery.Text
            _bold
            ContentTextColor={ContentTextColor}
            dangerouslySetInnerHTML={{__html: intl.formatMessage(
              {
                id: 'delivery_details_time',
                defaultMessage: 'Delivery time'
              },
            )}}
          />
          {DeliveryDetails?.ScheduledDate && <Delivery.Text ContentTextColor={ContentTextColor}>{moment(DeliveryDetails?.ScheduledDate).format('dddd')}, {moment(DeliveryDetails?.ScheduledDate).format('D/M/YYYY')}</Delivery.Text>}
          {DeliveryDetails?.ScheduledStartTime && <Delivery.Text ContentTextColor={ContentTextColor}>{moment(DeliveryDetails?.ScheduledStartTime).format('HH:mm')} - {moment(DeliveryDetails?.ScheduledEndTime).format('HH:mm')}</Delivery.Text>}
        </div>
      </Delivery.Row>
      <Delivery.Row _marginBottom='.7rem'>
        <div className='col col--delivery'>
          <StepsDelivery
            step={DeliveryDetails?.StatusID}
            core={core}
          />
        </div>
        <div className='col col--delivery' style={{ transform: 'translateY(-.26rem)' }}>
          <Delivery.Text
            _bold
            _width='17.5rem'
            _size='1.1rem'
            ContentTextColor={ContentTextColor}
            dangerouslySetInnerHTML={{__html: intl.formatMessage(
              {
                id: message.length ? UPDATE_MESSAGES[message] : 'no_message',
                defaultMessage: message.length ? UPDATE_MESSAGES[message] : '&nbsp;'
              },
            )}}
          />
          <Delivery.Link
            to={`/${tid}/${pid}/details`}
            ContentIconsColor={ContentIconsColor}
            dangerouslySetInnerHTML={{__html: intl.formatMessage(
              {
                id: 'delivery_details_view',
                defaultMessage: 'View details >'
              },
            )}}
          />
        </div>
      </Delivery.Row>
    </Delivery>
  )
}
