import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { MenuItem } from './styles'
import { ArrowIcon } from '../../../../assets/svg'
import history from '../../../../history'
import { ThemeContext } from '../../../../utils'

export default ({
  children,
  icon = '',
  column = false,
  paddingLeft = false,
  borderRight = false,
  smallText = false,
  link = false,
  id = null,
  menuTypeID = null,
  saveMenuTypeId,
  match: {
    params: {
      tid = '1',
      pid = '1'
    } = {}
  } = {},
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()

  const onClick = () => {
    if (link && menuTypeID !== 13) {
      window.open(link, '_self')
    } else {
      saveMenuTypeId(menuTypeID)
      history.push(`/${tid}/${pid}/${id}`)
    }
  }

  return (
    <MenuItem
      _column={column}
      _paddingLeft={paddingLeft}
      _borderRight={borderRight}
      onClick={onClick}
    >
      <MenuItem.Icon
        ContentIconsColor={ContentIconsColor}
      >{icon}</MenuItem.Icon>
      <MenuItem.Text
        _column={column}
        _smallText={smallText}
        ContentTextColor={ContentTextColor}
        dangerouslySetInnerHTML={{__html: intl.formatMessage(
          {
            id: children,
            defaultMessage: children
          }
        )}}
      />
      <MenuItem.Arrow _hide={column}>
        <ArrowIcon />
      </MenuItem.Arrow>
    </MenuItem>
  )
}
