import React from "react";

import { Layout } from "./styles";

export default ({ home = false, children }) => {
  return (
    <Layout _home={home}>
      <Layout.Container _home={home}>{children}</Layout.Container>
    </Layout>
  );
};
