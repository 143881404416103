import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { StepsDelivery } from './styles'
import { ThemeContext } from '../../../utils'

const STEPS = Array.from({ length: 4 }, () => undefined)

const STATUS = {
  0: '',
  1: 'Picked up',
  2: 'Ready for delivery',
  3: 'On the way',
  4: 'Delivered',
}

export default ({
  step = 0
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()

  return (
    <StepsDelivery>
      <StepsDelivery.StepContainer>
        {STEPS.map((item, key) => (
          <StepsDelivery.Step
            key={key}
            _done={key <= step - 1}
            _hide={key === 0}
            ContentIconsColor={ContentIconsColor}
          >
            <div className='line' />
            <div className='circle' />
          </StepsDelivery.Step>
        ))}
      </StepsDelivery.StepContainer>
      <StepsDelivery.Status ContentTextColor={ContentTextColor}>
        {intl.formatMessage(
          {
            id: STATUS[step],
            defaultMessage: STATUS[step]
          },
        )}
      </StepsDelivery.Status>
    </StepsDelivery>
  )
}
