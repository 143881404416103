import axios from 'axios'

import {
  log,
  token,
  connection
} from './interceptors'

import { config } from '../../../constants'

const instance = axios.create({
  baseURL: config.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  data: {},
  params: {},
  timeout: 180000
})

token(instance)
log(instance)
connection(instance)

export default instance
