import React, { useContext } from "react";
import { Form as FormAntd } from "antd";
import { useIntl } from "react-intl";

import { PlacesAutocomplete } from "../..";
import { ThemeContext } from "../../../../utils";

export default ({
  searchStreetParam,
  setActivePanel,
  number,
  setNumber,
  onChangeNumber,
  isDisabledStreen,
  city,
  address,
  name = ["Address", "StreetNumber"],
}) => {
  const intl = useIntl();
  const { Language } = useContext(ThemeContext);

  return (
    <FormAntd.Item
      label={
        intl.formatMessage({
          id: "controls_label_house",
          defaultMessage: "House No.",
        }) + " *"
      }
      name={name}
      className="number-input"
      style={{ width: "8rem" }}
      onClick={() => setActivePanel(false)}
    >
      <PlacesAutocomplete
        withSessionToken={true}
        apiKey={`${process.env.REACT_APP_GOOGLE_KEY}&language=${Language}`}
        style={{ width: "8rem" }}
        autocompletionRequest={{
          bounds: [
            {
              lat: searchStreetParam?.latitude,
              lng: searchStreetParam?.longitude,
            },
          ],
          types: ["address"],
          country: searchStreetParam?.countryCode,
          input: `${city} ${address} ${number}`,
        }}
        selectProps={{
          onFocus: () => setActivePanel("number"),
          isDisabled: !isDisabledStreen,
          placeholder: number,
          inputValue: number,
          defaultInputValue: number,
          onInputChange: (v, { action }) =>
            action === "input-change" && setNumber(v),
          onChange: (data) => {
            onChangeNumber(data);
          },
          noOptionsMessage: () => "",
          filterOption: ({ label, value, data }) => {
            if (data.value.types.includes("street_address")) {
              return true;
            }
            return false;
          },
          formatOptionLabel: (v) => {
            return v.value.structured_formatting.main_text
              .split(address)
              .join("");
          },
          styles: {
            input: (provided, state) => ({
              ...provided,
              margin: 0,
              padding: 0,
              lineHeight: "3.8rem",
            }),
            menu: (provided, state) => {
              const isOptions = state.options
                .map((i) => i.value)
                .reduce((acc, val) => {
                  if (val.types.find((i) => i === "street_address")) {
                    return [...acc, val];
                  }
                  return acc;
                }, []);

              return {
                ...provided,
                display: isOptions <= 0 ? "none" : "block",
              };
            },
            indicatorsContainer: (provided, state) => ({
              ...provided,
              display: "none !important",
            }),
            singleValue: (provided, state) => ({
              ...provided,
              opacity: 0,
            }),
          },
        }}
      />
    </FormAntd.Item>
  );
};
