import React, { useEffect } from 'react'
import { Spin } from 'antd'

export default ({
  size = 'default', // small, default and large
  isActive = false,
  isAuthenticated = false,
  core: {
    AppConfiguration = {}
  },
  children,
  style,
}) => {
  useEffect(() => {
    if (isActive) {
      setTimeout(() => window.scrollTo(0, 0), 10)
    }
  }, [isActive])

  return (
    <Spin
      spinning={isActive || Object.keys(AppConfiguration).length === 0}
      size={size}
      style={{ transform: 'translateY(-5rem)', minHeight: '100vh', ...style }}
    >
      {(isActive || Object.keys(AppConfiguration).length === 0) ? <div style={{ width: '100vw', height: '100vh' }} /> : children}
    </Spin>
  )
}
