import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { convertColor } from '../../../utils'

export const MoreOptionsBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.3rem 1rem 1.6rem;
  margin-top: ${({ _marginTop }) => _marginTop};
  margin-bottom: ${({ _marginBottom }) => _marginBottom};
  background-color: ${({ theme }) => theme.colors.white};
`

MoreOptionsBlock.Link = styled(Link)`
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.black};
  padding-bottom: .5rem;
`

MoreOptionsBlock.LinkIcon = styled.div`
  width: 2rem;
  height: 2rem;
  fill: #979797;

  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`

MoreOptionsBlock.ActionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

MoreOptionsBlock.Action = styled.div`
  width: 50%;
  /* height: 2rem; */
  padding-left: 3rem;
  padding-top: 1.5rem;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: ${({ theme }) => theme.transition};

  &:hover {
    opacity: .7;
  }

  span {
    font-size: 1.2rem;
    line-height: 1.8rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
  }

  .icon {
    position: absolute;
    left: 0;

    font-family: IconFont;
    font-size: 2rem;
    color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};

    svg {
      width: 2rem;
    }
  }
`
