import styled from 'styled-components'

import { convertColor } from '../../../utils'

export const Form = styled.div`
  margin-top: .5rem;
  /* height: calc(100% - 6rem); */
  /* padding-bottom: 4rem; */

  .title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.black};
    padding: 1.5rem 0 0;
    margin-bottom: -1rem;
    display: block;
  }

  #react-select-2-input {
    opacity: 1 !important;
  }

  & input {
    opacity: 1 !important;
  }

  .ant-form-item-label
  {
    padding-bottom: .25rem;
    text-align: left;
    white-space: nowrap;

    & > label {
      font-family: 'OpenSans';
      font-size: 1.2rem;
      line-height: 1.8rem;
      font-weight: 600;
      color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
      padding: 0;
      height: unset;

      [dir='rtl'] & {
        font-family: 'OpenSansHebrew';
      }
    }
  }

  .ant-form-item-required::before {
    display: none !important;
  }

  .ant-form-item {
    margin-bottom: 0;

    @media screen and (min-width: 570px) {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  .ant-form-item-explain-error {
    display: none !important;
  }

  .ant-form-item-explain, .ant-form-item-extra {
    display: none;
  }

  .ant-form-item-has-error .ant-input {
    border-color: #979797;
  }

  .input-option {
    width: 100%;
    height: 4rem;
    border-bottom: .1rem solid #e5e5e5;
    display: flex;
    align-items: center;
    padding: 0 1.4rem;

    &:last-child {
      border-bottom: none;
    }

    &__icon {
      margin-right: 1rem;
    }

    &__text {
      &-bold {
        font-weight: 600;
      }
    }
  }

  .bung {
    /* height: 6.45rem; */
    width: 100%;
    visibility: hidden;
    opacity: 0;
    display: none;

    &--visible {
      display: block
    }
  }

  .in-street-name {
    width: 100%;
    margin-right: 2.8rem;

    input {
      width: 100% !important;
    }
  }

  .in-33 {
    width: 33.3336%;
  }

  .in-margin-28 {
    margin-right: 2.8rem;
  }

  .number-input {
    input {
      width: 100% !important;
    }
  }
`

Form.Item = styled.div`
  margin-top: 1rem;
  transition: all .25s ease-in-out;

  ${({ _flex }) => _flex && `
    display: flex;
  `}

  .flex {
    display: flex;
  }

  ${({ _isActive, theme }) => _isActive && `
    transition: all .25s ease-in-out;

    .active {
      transition: all .25s ease-in-out;
      position: absolute;
      top: 0;
      left: 0;
      padding: 1.6rem;
      z-index: 1;
      width: 100%;
      height: 100%;
      background-color: #e2e2e2;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      overflow: hidden;
    }

    .ant-form-item {
      &-label {
        flex: 0 0 3rem !important;
        min-width: 7rem;
      }
    }
  `}

  ${({ _marginBottom }) => _marginBottom && `
    margin-bottom: 6.4rem;
  `}
`

Form.Buttons = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1.5rem;

  ${({ _margin }) => _margin && `
    margin-top: calc(100% - 20rem);
  `}
`
