import styled from 'styled-components'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

import { convertColor } from '../../../utils'

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export const ClearImg = styled.img`
  position: absolute;
  width: 3.8rem;
  height: 100%;
  padding: 1rem;
  top: 0;
  right: 0;
  /* display: none; */
  opacity: 0;
  z-index: 0;

  &.show {
    opacity: 1;
    z-index: 100;
  }

  ${({ _show }) => _show && `
    opacity: 1;
    z-index: 100;
  `}

  ${({ _prioroty }) => _prioroty && `
    opacity: 1;
    z-index: 100;
  `}
`

export const BackImg = styled.img`
  position: absolute;
  width: 3.8rem;
  height: 3.8rem;
  padding: 1rem;
  top: .1rem;
  left: 0;
  display: none;

  [dir='rtl'] & {
    transform: scaleX(-1);
  }

  ${({ _show }) => _show && `
    display: block;
  `}
`

export const PlacesAutocomplete = styled.div`
  margin-right: 2.4rem;
  position: relative;

  ${({ _width }) => _width && `
    width: ${_width};
    margin: 0;
  `}

  ${({ _isBackIcon }) => _isBackIcon && `
    input {
      padding-left: 3rem !important;
    }
  `}

  .css-yk16xz-control, .css-1pahdxg-control {
    height: 4rem !important;
    padding: 1.1rem;
    font-family: 'OpenSans';
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
    border-radius: .5rem;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #979797;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;

    ${({ _padding }) => _padding && `
      ${iOS() && `
        padding: .5rem;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 0;
      `}
    `}

    [dir='rtl'] & {
      font-family: 'OpenSansHebrew';
    }

    &:hover {
      border-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    }

    &:focus, &:active {
      border-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
      box-shadow: none !important;
    }
  }

  .css-1pahdxg-control {
    border-color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
  }

  .css-g1d714-ValueContainer {
    padding: 0;
    height: 100%;
  }

  .css-1hb7zxy-IndicatorsContainer {
    /* display: none; */
  }

  & span, & svg {
    /* display: none; */
  }

  .css-1wa3eu0-placeholder {
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  input {
    width: 100% !important;

    [dir='rtl'] & {
      font-family: 'OpenSansHebrew';
    }
  }

  .react-select__input {
    width: 100%;
  }
`

export const PlacesAutocompleteComponent = styled(GooglePlacesAutocomplete)`
`
