import React, { useEffect, Fragment } from 'react'

import { Separator, Item } from '../../blocks'

export default ({
  core: {
    AppConfiguration = {},
    Menu = [],
    HeaderText,
    FooterText,
  } = {},
  match: {
    path = '/'
  }
}) => {
  const isSelectMenu = !path.split('/').find(i => i === 'selectOption')

  useEffect(() => {
    document.body.style.overflow = 'auto'
  }, [])

  return (
    <Fragment>
      {isSelectMenu && HeaderText && <span
        className='title'
        style={{ paddingTop: '1rem' }}
        dangerouslySetInnerHTML={{__html: HeaderText}}
      />}
      {Menu && Menu.map((item, key) => (
        item.MenuTypeID === 2
          ? (
            <Separator
              key={key}
              color={AppConfiguration?.ContentTextColor}
              text={item.Text}
              _marginTop='1.7rem'
            />
          )
          : (
            <Item.Menu
              key={key}
              icon={item.Icon}
              iconColor={AppConfiguration?.ContentIconsColor}
              textColor={AppConfiguration?.ContentTextColor}
              id={item.ID}
              menuTypeID={item.MenuTypeID}
              link={item.Link}
            >
              {item.Text}
            </Item.Menu>
          )
      ))}
      {isSelectMenu && FooterText && <span
        className='title title--bottom'
        style={{ margin: '1rem 0' }}
        dangerouslySetInnerHTML={{__html: FooterText}}
      />}
    </Fragment>
  )
}
