import styled from 'styled-components'

import { convertColor } from '../../../../utils'

export const PickupItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.4rem 0 1.5rem;
  margin: 0;
  transition: ${({ theme }) => theme.transition};
  border-bottom: .1rem solid ${({ theme }) => theme.colors.border};
  border-top: ${({ _borderTop, theme }) => _borderTop ? `.1rem solid ${theme.colors.border}` : 'none'};
  cursor: pointer;
  position: relative;
  height: 100%;

  &:hover, &:active {
    opacity: .8;
  }

  ${props => props._paddingLeft && `
    padding-left: 1.2rem;
  `}
`

PickupItem.Container = styled.div`
  height: 8rem;
`

PickupItem.Icon = styled.span`
  font-family: IconFont;
  font-size: 2rem;
  position: absolute;
  color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
  display: flex;
  justify-content: center;
  align-items: center;
`

PickupItem.Text = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 4.3rem;

  .name {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 600;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : '#46474c'};
  }

  .address {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : '#46474c'};
  }

  .distance {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
  }
`

PickupItem.Arrow = styled.div`
  width: 2rem;
  transform: translate(5px, 2px);
  fill: #979797;
  display: ${({ _hide }) => _hide ? 'none' : 'block'};

  [dir='rtl'] & {
    transform: translate(-5px, 2px) scaleX(-1);
  }
`
