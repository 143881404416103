import styled from 'styled-components'

import { convertColor } from '../../../utils'

export const PickupPoint = styled.div`
  /* padding: 1rem 0; */
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: ${({ _titleSize }) => _titleSize ? `calc(100vh - 6rem - ${_titleSize}px)` : 'calc(100vh - 6rem)'};
  overflow: hidden;

  .title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.black};
    padding: 0 .3rem .5rem;
  }

  ${({ _details }) => _details && `
    overflow: auto;
    display: block;
    margin-bottom: 4rem;
    min-height: unset;
  `}

  .contact-btn-container {
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: fixed;
    bottom: 1.5rem;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    max-width: 48rem;
    padding: 0 1.5rem;
    background-color: #f5f5f5;
  }
`

PickupPoint.FormContainer = styled.div`
  width: 100%;
  padding: 0 .3rem;
  display: flex;
  flex-wrap: wrap;
`

PickupPoint.FormItem = styled.div`
  display: flex;
  flex-direction: column;
`

PickupPoint.FormLabel = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 600;
  color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
  padding-bottom: .5rem;
`

PickupPoint.ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1.3rem;
  overflow-y: auto;
  /* padding-bottom: 7.3rem; */

  height: calc(100vh - 34.2rem);
  height: ${({ _titleSize }) => _titleSize ? `calc(100vh - 34.2rem - ${_titleSize}px)` : 'calc(100vh - 34.2rem)'};
  overflow-y: auto;
`

PickupPoint.ButtonPanel = styled.div`
  width: calc(100% + 2rem);
  /* height: 8rem; */
  display: flex;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.darkWhite};
  border-top: .1rem solid #d3d2d2;
  padding: 1.3rem 1.3rem;
  margin-left: -1rem;
  position: relative;
  overflow: hidden;
`


PickupPoint.SelectPanel = styled.div`
  width: 100%;
  position: fixed;
  height: 7.4rem;
  top: calc(100vh - 7rem);
  background-color: ${({ theme }) => theme.colors.darkWhite};
  border-top: .1rem solid #d3d2d2;
  padding: 0 2rem;
  left: 0;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: ${({ theme }) => theme.transition};
  transform: translateY(7.5rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${({ theme }) => theme.maxWidth};
  left: 50%;
  transform: translate(-50%, 15rem);

  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;

  ${({ _show }) => _show && `
    transform: translate(-50%, 0);
  `}

  .button {
    color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 600;
    cursor: pointer;
  }
`

PickupPoint.SelectPanelText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  .name {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 600;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : '#46474c'};
  }

  .address {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : '#46474c'};
  }

  .distance {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
  }

  ${({ _noMaxHeight }) => _noMaxHeight && `
    flex: 0;
  `}
`

PickupPoint.Opening = styled.div`
  display: flex;
  align-items: center;

  span {
    color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

  .icon {
    font-family: IconFont;
    margin-right: 1rem;
    font-size: 2rem;
  }

  .text {

  }
`
