import React, { useState, useEffect, useContext, Fragment } from 'react'
import { useIntl } from 'react-intl'

import { Feedback } from './styles'
import { Rate } from '../../Rate/styles'
import { ThemeContext } from '../../../../utils'

import star from '../../../../assets/images/star.svg'
import starYellow from '../../../../assets/images/yellow-star.svg'

const STARS = Array.from({ length: 5 }, () => undefined)

export default ({
  core: {
    FeedbackForm
  },
  callbackRate = () => console.log('@components/block/Feedback () => callbackRate'),
  callbackSliders = () => console.log('@components/block/Feedback () => callbackSliders'),
}) => {
  const [sliders, changeClider] = useState(FeedbackForm)

  const intl = useIntl()

  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const onChangeSlider = (index, value) => {
    changeClider(sliders.map((item, key) => key === index ? {...item, value} : item))
  }

  useEffect(() => {
    callbackSliders(sliders)
  }, [sliders, callbackSliders])

  return (
    <Feedback>
      <Feedback.Title style={{ marginBottom: '3rem' }}>
        {intl.formatMessage(
          {
            id: 'feedback_label_share',
            defaultMessage: 'Share your experience with us'
          },
        )}
      </Feedback.Title>
      {sliders.map((item, key) => (
        item.IsTotalRating
          ? (
            <Fragment
              key={key}
            >
              <Feedback.SliderTitle>
                {item.Text}
              </Feedback.SliderTitle>
              <Rate>
                <Rate.Container>
                  {STARS.map((it, i) => (
                    <Rate.Star
                      key={i}
                      src={i + 1 <= item?.value ? starYellow : star}
                      _noMargin={i + 1 === STARS.length}
                      onClick={() => onChangeSlider(key, i + 1)}
                    />
                  ))}
                </Rate.Container>
              </Rate>
            </Fragment>
          )
          : (
            <Feedback.SliderItem
              key={key}
            >
              <Feedback.SliderTitle>
                {item.Text}
              </Feedback.SliderTitle>
              <Feedback.Slider
                min={-1}
                max={1}
                defaultValue={0}
                value={item.value}
                tipFormatter={null}
                onChange={value => onChangeSlider(key, value)}
                ContentIconsColor={ContentIconsColor}
                _firstDot={item.value === 1}
                _lastDot={item.value === 3}
              />
              <Feedback.SliderTextContainer ContentTextColor={ContentTextColor}>
                <span>{intl.formatMessage(
                  {
                    id: 'feedback_label_improved',
                    defaultMessage: 'Can be improved'
                  },
                )}</span>
                <span>{intl.formatMessage(
                  {
                    id: 'feedback_label_like',
                    defaultMessage: 'Like'
                  },
                )}</span>
              </Feedback.SliderTextContainer>
            </Feedback.SliderItem>
          )
      ))}
    </Feedback>
  )
}
