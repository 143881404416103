import React from 'react'
import moment from 'moment'
import { useIntl } from 'react-intl'

import { DeliveryStatus, Item } from '../../blocks'

export default ({
  core: {
    AppConfiguration = {},
    DeliveryDetails = {},
  } = {}
}) => {
  const intl = useIntl()

  return (
    <div style={stylesContainer}>
      <DeliveryStatus
        sender={DeliveryDetails?.Consignor}
        trackingNumber={DeliveryDetails?.TrackingNumber}
        step={DeliveryDetails?.StatusID}
        _textColor={AppConfiguration?.ContentTextColor}
        _color={AppConfiguration?.ContentIconsColor}
      />
      <div style={stylesContainerItems}>
        <Item.DeliveryDetails
          _textColor={AppConfiguration?.ContentTextColor}
          _color={AppConfiguration?.ContentIconsColor}
          icon='&#xe90c;'
          title={intl.formatMessage(
            {
              id: 'delivery_details_time',
              defaultMessage: 'Delivery time'
            },
          )}
          text={`
            <span>${DeliveryDetails?.ScheduledDate ? moment(DeliveryDetails?.ScheduledDate).format('dddd') + ',' : ''} ${DeliveryDetails?.ScheduledDate ? moment(DeliveryDetails?.ScheduledDate).format('D/M/YYYY') : ''}</span>
            <span>${DeliveryDetails?.ScheduledStartTime ? moment(DeliveryDetails?.ScheduledStartTime).format('HH:mm') + ' - ' : ''} ${DeliveryDetails?.ScheduledEndTime ? moment(DeliveryDetails?.ScheduledEndTime).format('HH:mm') : ''}</span>
          `}
        />

        <Item.DeliveryDetails
          _textColor={AppConfiguration?.ContentTextColor}
          _color={AppConfiguration?.ContentIconsColor}
          icon='&#xe905;'
          title={intl.formatMessage(
            {
              id: 'delivery_details_consignee',
              defaultMessage: 'Consignee'
            },
          )}
          text={DeliveryDetails?.Consignee}
        />

        {DeliveryDetails?.LastUpdateType === 3
          ? (
            <Item.DeliveryDetails
              _textColor={AppConfiguration?.ContentTextColor}
              _color={AppConfiguration?.ContentIconsColor}
              icon='&#xe904;'
              title={intl.formatMessage(
                {
                  id: 'change_drop_address_label',
                  defaultMessage: 'Drop off address'
                },
              )}
              text={`${DeliveryDetails?.LastUpdateData?.Address?.City || ''} ${DeliveryDetails?.LastUpdateData?.Address?.StreetName || ''} ${DeliveryDetails?.LastUpdateData?.Address?.StreetNumber || ''}`}
            />
          )
          : (
            <Item.DeliveryDetails
              _textColor={AppConfiguration?.ContentTextColor}
              _color={AppConfiguration?.ContentIconsColor}
              icon='&#xe904;'
              title={intl.formatMessage(
                {
                  id: 'change_drop_address_label',
                  defaultMessage: 'Drop off address'
                },
              )}
              text={`${DeliveryDetails?.Address?.City || ''} ${DeliveryDetails?.Address?.StreetName || ''} ${DeliveryDetails?.Address?.StreetNumber || ''}`}
            />
          )
        }

        {DeliveryDetails?.ConsignorAddress && <Item.DeliveryDetails
          _textColor={AppConfiguration?.ContentTextColor}
          _color={AppConfiguration?.ContentIconsColor}
          icon='&#xe916;'
          title={intl.formatMessage(
            {
              id: 'delivery_details_sender_address',
              defaultMessage: 'Sender address'
            },
          )}
          text={`${DeliveryDetails?.ConsignorAddress?.City || ''} ${DeliveryDetails?.ConsignorAddress?.StreetName || ''} ${DeliveryDetails?.ConsignorAddress?.StreetNumber || ''}`}
        />}

        {DeliveryDetails?.LastUpdateType === 13 && <Item.DeliveryDetails
          _textColor={AppConfiguration?.ContentTextColor}
          _color={AppConfiguration?.ContentIconsColor}
          icon='&#xe90f;'
          title={intl.formatMessage(
            {
              id: 'delivery_details_payment',
              defaultMessage: 'Payment'
            },
          )}
          text={`
            ${intl.formatMessage(
              {
                id: 'delivery_details_paid_date',
                defaultMessage: "{0} were paid on the {1} at {2}",
              },
              {
                0: DeliveryDetails?.LastUpdateData?.Value || '',
                1: moment(DeliveryDetails?.LastUpdateData?.Time || '2020-12-04T15:22:89.32').format('D/M/YYYY'),
                2: moment(DeliveryDetails?.LastUpdateData?.Time || '2020-12-04T15:22:89.32').format('HH:mm')
              }
            )}
          `}
        />}

        {DeliveryDetails?.LastUpdateType === 6 && <Item.DeliveryDetails
          _textColor={AppConfiguration?.ContentTextColor}
          _color={AppConfiguration?.ContentIconsColor}
          icon='&#xe90e;'
          title={intl.formatMessage(
            {
              id: 'delivery_details_instructions',
              defaultMessage: 'Task instructions'
            },
          )}
          text={`
              ${intl.formatMessage(
                {
                  id: 'delivery_details_location',
                  defaultMessage: 'Leave in location'
                },
              )}: ${DeliveryDetails?.LastUpdateData?.Location || ''}. ${DeliveryDetails?.LastUpdateData?.EntryCode ? `${intl.formatMessage(
                {
                  id: 'delivery_details_code',
                  defaultMessage: 'Access code'
                },
              )}: ${DeliveryDetails?.LastUpdateData?.EntryCode}` : ''}
              ${DeliveryDetails?.LastUpdateData?.Remarks ? `
                <div class='small'>
                  ${intl.formatMessage(
                    {
                      id: 'controls_label_comment',
                      defaultMessage: 'Comment'
                    },
                  )}: ${DeliveryDetails?.LastUpdateData?.Remarks}
                </div>
              ` : ''}
          `}
        />}

        {DeliveryDetails?.LastUpdateType === 4 && <Item.DeliveryDetails
          _textColor={AppConfiguration?.ContentTextColor}
          _color={AppConfiguration?.ContentIconsColor}
          icon='&#xe90a;'
          title={intl.formatMessage(
            {
              id: 'delivery_details_neighbor',
              defaultMessage: 'Deliver to a neighbor'
            },
          )}
          text={`
            ${DeliveryDetails?.LastUpdateData?.Address?.City || ''} ${DeliveryDetails?.LastUpdateData?.Address?.StreetName || ''} ${DeliveryDetails?.LastUpdateData?.Address?.StreetNumber || ''}
            <br/>
            <div class='small'>${DeliveryDetails?.LastUpdateData?.Contact?.FirstName || ''} ${DeliveryDetails?.LastUpdateData?.Contact?.LastName || ''} ${DeliveryDetails?.LastUpdateData?.Contact?.PhoneNumber || ''}</div>
          `}
        />}

        {DeliveryDetails?.LastUpdateType === 5 && <Item.DeliveryDetails
          _textColor={AppConfiguration?.ContentTextColor}
          _color={AppConfiguration?.ContentIconsColor}
          icon='&#xe91b;'
          title={intl.formatMessage(
            {
              id: 'delivery_details_date_changed',
              defaultMessage: 'Delivery date changed'
            },
          )}
          text={`${intl.formatMessage(
            {
              id: 'delivery_details_date',
              defaultMessage: 'Selected date'
            },
          )}: ${DeliveryDetails?.LastUpdateData?.ScheduledDate || ''}`}
        />}

        {DeliveryDetails?.LastUpdateType === 11 && <Item.DeliveryDetails
          _textColor={AppConfiguration?.ContentTextColor}
          _color={AppConfiguration?.ContentIconsColor}
          icon='&#xe91d;'
          title={intl.formatMessage(
            {
              id: 'delivery_details_vacation',
              defaultMessage: 'Store during vacation'
            },
          )}
          text={`${intl.formatMessage(
            {
              id: 'delivery_details_till',
              defaultMessage: 'Till'
            },
          )}  ${DeliveryDetails?.LastUpdateData?.ScheduledDate || ''}`}
        />}

        {DeliveryDetails?.LastUpdateType === 7 && <Item.DeliveryDetails
          _textColor={AppConfiguration?.ContentTextColor}
          _color={AppConfiguration?.ContentIconsColor}
          icon='&#xe910;'
          title={intl.formatMessage(
            {
              id: 'delivery_details_point',
              defaultMessage: 'Pickup point'
            },
          )}
          text={`
            ${DeliveryDetails?.LastUpdateData?.Address?.City || ''} ${DeliveryDetails?.LastUpdateData?.Address?.StreetName || ''} ${DeliveryDetails?.LastUpdateData?.Address?.StreetNumber || ''}
            <br/>
            <div class='small'>${DeliveryDetails?.LastUpdateData?.PickupPoint || ''}</div>
          `}
        />}
      </div>
    </div>
  )
}

const stylesContainer = {
  display: 'flex',
  flexDirection: 'column',
  padding: '.4rem .6rem'
}

const stylesContainerItems = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '1.4rem',
  borderTop: `.1rem solid #46474c`
}
