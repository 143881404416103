import React, { useContext } from 'react'
// import { useIntl } from 'react-intl'

import { DeliveryNeighbor } from './styles'
import { Form } from '../../blocks'
import { ThemeContext } from '../../../utils'

export default ({
  tid,
  pid,
  core: {
    HeaderText
  },
  core,
  setDeliveryDeliverToNeighbor
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  // const intl = useIntl()

  const callbackFormData = data => setDeliveryDeliverToNeighbor(tid, pid, data)

  React.useEffect(() => {
    document.body.classList.add('feed')
    document.body.style.overflow = 'hidden'
    window.scrollTo(0, 0)

    return () => {
      document.body.classList.remove('feed')
      document.body.style.overflow = 'auto'
      window.scrollTo(0, 0)
    }
  }, [])

  const [paddingBottom, setPaddingBottom] = React.useState(40)
  const createRef = r => {
    setPaddingBottom(r?.querySelector('.contact-btn-container')?.clientHeight || 4)
  }

  return (
    <DeliveryNeighbor _paddingBottom={paddingBottom} ref={createRef} >
      <DeliveryNeighbor.Text
        ContentIconsColor={ContentIconsColor}
        ContentTextColor={ContentTextColor}
      >
        {HeaderText && <span
          className='title'
          dangerouslySetInnerHTML={{__html: HeaderText}}
        />}
      </DeliveryNeighbor.Text>
      <Form.AddressNeighbor
        callbackFormData={callbackFormData}
        core={core}
      />
    </DeliveryNeighbor>
  )
}
