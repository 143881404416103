import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { ChangeDelivery } from '../../blocks'

export default ({
  core: {
    AvailableDates = []
  } = {},
  core,
  tid,
  pid,
  setDeliveryDate
}) => {
  const [selectedDate, changeDate] = useState(false)

  const _confirm = (data = false) => {
    const Date = selectedDate || data
    setDeliveryDate(tid, pid, { Date })
  }

  return (
    <Route
      render={({ location, match: { params: { tid, pid, id } } }) => (
        <Switch location={location}>
          <Route
            exact
            path={`/:tid/:pid/${id}`}
            component={() => AvailableDates.length > 1
              ? (
                <ChangeDelivery.List
                  core={core}
                  tid={tid}
                  pid={pid}
                  id={id}
                  changeDate={changeDate}
                />
              )
              : (
                <ChangeDelivery.NoList
                core={core}
                changeDate={changeDate}
                confirm={_confirm}
                />
              )
            }
          />
          <Route path={`/:tid/:pid/${id}/confirm`} component={() => selectedDate ? <ChangeDelivery.Confirm
            core={core}
            selectedDate={selectedDate}
            confirm={_confirm}
            changeDate={changeDate}
          /> : <Redirect to={`/${tid}/${pid}/${id}`} />} />
        </Switch>
      )}
    />
  )
}
