import React, { useState, useContext } from 'react'
import { Form as FormAntd } from 'antd'
import { useIntl } from 'react-intl'

import { Form } from './styles'
import { Message } from './internal'
import { ThemeContext } from '../../../utils'
import { Button } from '..'


export default ({
  callbackFormData,
  core: {
    FooterText
  },
}) => {
  const [showError, changeShowError] = useState(false)

  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()

  const onFinish = values => {
    callbackFormData(values)
  }

  const onFinishFailed = errorInfo => {
    changeShowError(true)
  }

  return (
    <Form
      ContentIconsColor={ContentIconsColor}
      ContentTextColor={ContentTextColor}
      onClick={() => changeShowError(false)}
    >
      <FormAntd
        name='basic'
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div style={{ marginTop: '2rem' }}>
          <Message
            name='Message'
          />

          <span
            className='title'
            style={{ fontSize: '1.2rem', lineHeight: '1.8rem', marginTop: '1rem', marginBottom: '2rem' }}
            dangerouslySetInnerHTML={{ __html: FooterText }}
          />
        </div>

        <div className='contact-btn-container'>
          {showError && <div className='error-message-form' style={{ margin: '1rem 0' }}>{
            intl.formatMessage(
              {
                id: 'message_failed_form',
                defaultMessage: '* Some info is missing'
              },
            )
          }</div>}

          <Button
            // loading={isUpdating}
            htmlType='submit'
            text={intl.formatMessage(
              {
                id: 'controls_label_send',
                defaultMessage: 'Send'
              },
            )}
            type='primary'
          />
        </div>
      </FormAntd>
    </Form>
  )
}
