import React, { useContext, useEffect } from 'react'
import moment from 'moment'
import { useIntl } from 'react-intl'

import { ChangeDelivery } from './styles'
import { Button } from '..'
import history from '../../../history'
import { ThemeContext } from '../../../utils'

export default ({
  core: {
    AppConfiguration = {},
    FooterText = '',
    AvailableDates = ['2020-11-03T00:00:00.000'],
  } = {},
  changeDate,
  confirm
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    window.scrollTo(0, 0)

    return () => document.body.style.overflow = 'auto'
  }, [])

  return (
    <ChangeDelivery>
      <ChangeDelivery.Text
        className='title'
        _marginBottom='1.2rem'
        ContentTextColor={ContentTextColor}
        ContentIconsColor={ContentIconsColor}
      >{intl.formatMessage(
        {
          id: 'change_delivery_no_item',
          defaultMessage: 'You may update the delivery date to the following business day'
        },
      )}, <span>{moment(AvailableDates?.[0]).format('MMMM DD')}</span>.</ChangeDelivery.Text>
      <ChangeDelivery.Text
        className='date'
        _marginBottom='1.8rem'
        ContentTextColor={ContentTextColor}
        ContentIconsColor={ContentIconsColor}
      >
        {intl.formatMessage(
          {
            id: 'change_delivery_no_item2',
            defaultMessage: 'Click the “Approve” button to update the date or “Cancel” to return to the options menu.'
          },
        )}
      </ChangeDelivery.Text>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
        <ChangeDelivery.Text
          className='date'
          _marginBottom='1.8rem'
          ContentTextColor={ContentTextColor}
          ContentIconsColor={ContentIconsColor}
          dangerouslySetInnerHTML={{__html: FooterText}}
        />
        <div style={{ display: 'flex' }}>
          <Button
            type='primary'
            text={intl.formatMessage(
              {
                id: 'controls_label_approve',
                defaultMessage: 'Approve'
              },
            )}
            textColor={AppConfiguration?.ActionButtonsTextColor}
            backgroundColor={AppConfiguration?.ActionButtonsBackgroundColor}
            noBorderRight={true}
            textCenter={true}
            onClick={() => {
              // changeDate((moment(ScheduledDate).add(1, 'days')).toJSON())
              confirm(AvailableDates?.[0])
            }}
          />
          <Button
            type='default'
            text={intl.formatMessage(
              {
                id: 'controls_label_cancel',
                defaultMessage: 'Cancel'
              },
            )}
            noBorderLeft={true}
            textCenter={true}
            onClick={() => history.goBack()}
          />
        </div>
      </div>
    </ChangeDelivery>
  )
}
