import React, { useContext } from 'react'

import { PlacesAutocomplete, PlacesAutocompleteComponent, ClearImg, BackImg } from './styles'
import { ThemeContext } from '../../../utils'

import icon from '../../../assets/images/x.svg'
import arrowBack from '../../../assets/images/arrowBack.svg'

export default ({
  clearIcon,
  isBackIcon = false,
  clearCallback = () => console.log('@components/blocks/PlacesAutocomplete/clearCallback'),
  backCallback = () => console.log('@components/blocks/PlacesAutocomplete/backCallback'),
  ...props
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const [clickOn, setClickOn] = React.useState(false)

  const clear = React.useCallback(() => {
    clearCallback()
  }, [clearCallback])

  const onMouseDown = () => {
    setClickOn(true)
  }

  const onMouseUp = () => {
    if (clickOn) {
      clear()
    }
    setClickOn(false)
  }

  return (
    <PlacesAutocomplete
      ContentIconsColor={ContentIconsColor}
      ContentTextColor={ContentTextColor}
      _isBackIcon={isBackIcon}
      {...props}
    >
      <PlacesAutocompleteComponent
        ContentIconsColor={ContentIconsColor}
        ContentTextColor={ContentTextColor}
        {...props}
      />
      <ClearImg
        _show={clearIcon}
        _prioroty={clickOn}
        src={icon}
        onClick={clear}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
      />
      <BackImg
        _show={isBackIcon}
        src={arrowBack}
        onClick={backCallback}
      />
    </PlacesAutocomplete>
  )
}
