import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'

import { PickupPoint } from './styles'
import { Map, Item, Button } from '..'
import { StorePin, PudoPin, LockerPin, HomePin } from '../../../assets/svg'
import history from '../../../history'
import { ThemeContext, convertDistance } from '../../../utils'

const CATEGORY_ICON = {
  1: StorePin,
  2: PudoPin,
  3: LockerPin,
}

export default ({
  core: {
    FooterText,
    AppConfiguration = {},
    DeliveryDetails = {},
    Title,
  } = {},
  onSelect,
  filters = {},
  selectedPoint,
  setSelectedPoint,
  setTitle,
}) => {
  const [places, setPlaces] = useState([])

  const intl = useIntl()

  const { ContentIconsColor, ContentTextColor, Language } = useContext(ThemeContext)

  const onCancel = () => {
    setTitle(Title)
    history.goBack()
    setSelectedPoint(null)
  }

  useEffect(() => {
    setPlaces([
      {
        lat: selectedPoint?.Address?.GeoLatitude,
        lng: selectedPoint?.Address?.GeoLongitude,
        Icon: CATEGORY_ICON[selectedPoint?.TypeID],
      },
      {
        lat: filters?.latitude || DeliveryDetails?.Address?.GeoLatitude,
        lng: filters?.longitude|| DeliveryDetails?.Address?.GeoLongitude,
        Icon: HomePin,
        address: true,
      }
    ])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    window.scrollTo(0, 0)

    return () => document.body.style.overflow = 'hidden'
  }, [])

  return (
    <PickupPoint style={{ padding: '1rem .3rem 0' }} _details>
      <PickupPoint.SelectPanelText
        _noMaxHeight
        ContentTextColor={ContentTextColor}
        ContentIconsColor={ContentIconsColor}
      >
        <span className='name'>{selectedPoint?.Name}</span>
        <span className='address'>{selectedPoint?.Address?.City} {selectedPoint?.Address?.StreetName} {selectedPoint?.Address?.StreetNumber}</span>
        <span className='distance'>{convertDistance(selectedPoint?.Distance || 0, Language)}</span>
      </PickupPoint.SelectPanelText>
      <Map.PickupDetails
        language={AppConfiguration?.language}
        marginTop='.5rem'
        marginBottom='2rem'
        places={places}
        ContentIconsColor={ContentIconsColor}
      />
      {selectedPoint?.OpeningTimes && <PickupPoint.Opening
        ContentIconsColor={ContentIconsColor}
      >
        <span className='icon'>&#xe90C;</span>
        <span
          className='text'
          dangerouslySetInnerHTML={{__html: intl.formatMessage(
            {
              id: 'pickup_point_details_hours_label',
              defaultMessage: 'Opening hours'
            },
          )}}
        />
      </PickupPoint.Opening>}
      <PickupPoint.ListContainer style={{ height: 'calc(100vh - 47rem)' }}>
        {selectedPoint?.OpeningTimes?.map((item, key) => (
          <Item.Opening
            key={key}
            data={item}
          />
        ))}
      </PickupPoint.ListContainer>

      {FooterText && <span
          className='title title--bottom'
          dangerouslySetInnerHTML={{__html: FooterText}}
        />}

      <div className='contact-btn-container'>

        <div style={{ display: 'flex', marginTop: '1rem' }}>
          <Button
            type='primary'
            text={intl.formatMessage(
              {
                id: 'controls_label_select',
                defaultMessage: 'Select'
              },
            )}
            noBorderRight={true}
            textCenter={true}
            onClick={() => onSelect({ PickupPointID: selectedPoint?.ID })}
          />
          <Button
            type='default'
            text={intl.formatMessage(
              {
                id: 'controls_label_cancel',
                defaultMessage: 'Cancel'
              },
            )}
            noBorderLeft={true}
            textCenter={true}
            onClick={onCancel}
          />
        </div>
      </div>
    </PickupPoint>
  )
}
