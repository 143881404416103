import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import ViewComponent from './ViewComponent'
import actions from '../../store/combineActions'

const mapStateToProps = state => ({
  isFetching: state.core.isFetching,
  core: state.core,
  menuTypeId: state.core.menuTypeId,
})

const mapDispatchToProps = dispatch => ({
  getPageInfo: (tid, pid, id) => dispatch(actions.core.getPageInfo(tid, pid, id)),
  setAddress: (tid, pid, data) => dispatch(actions.core.setAddress(tid, pid, data)),
  setDeliveryDate: (tid, pid, data) => dispatch(actions.core.setDeliveryDate(tid, pid, data)),
  setDeliveryStoreForVacation: (tid, pid, data) => dispatch(actions.core.setDeliveryStoreForVacation(tid, pid, data)),
  setDeliveryDeliverToNeighbor: (tid, pid, data) => dispatch(actions.core.setDeliveryDeliverToNeighbor(tid, pid, data)),
  setPickupPoint: (tid, pid, data) => dispatch(actions.core.setPickupPoint(tid, pid, data)),
  setTitle: (title) => dispatch(actions.core.setTitle(title)),
  setTitleDefault: (title) => dispatch(actions.core.setTitleDefault(title)),
  setDeliveryLeaveOutside: (tid, pid, data) => dispatch(actions.core.setDeliveryLeaveOutside(tid, pid, data)),
  getPickupPoints: (tid, pid, filters) => dispatch(actions.core.getPickupPoints(tid, pid, filters)),
  setDeliveryCourierMessage: (tid, pid, data) => dispatch(actions.core.setDeliveryCourierMessage(tid, pid, data)),
  setInvoiceContactDetails: (tid, pid, data) => dispatch(actions.core.setInvoiceContactDetails(tid, pid, data)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewComponent))

