import dotenv from 'dotenv'

dotenv.config()

export default {
  oauth2: {
    grant_type: process.env.REACT_APP_GRANT_TYPE,
    client_id: process.env.REACT_APP_CLIENT_ID,
    scope: process.env.REACT_APP_SCOPE,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    resource: "https://prod.applicat.com",
  },

  baseUrl: process.env.REACT_APP_BASE_URL,
}
