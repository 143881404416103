import React from 'react'

export const PaymentIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 27 27"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
      <circle
        cx="12.5"
        cy="12.5"
        r="12.5"
        stroke="#07AAEF"
        strokeWidth="1.5"
      ></circle>
      <text
        fill="#07AAEF"
        fontFamily="Aileron-Light, Aileron"
        fontSize="18"
        fontWeight="300"
      >
        <tspan x="7.994" y="18.087">
          $
        </tspan>
      </text>
    </g>
  </svg>
)

export const HoursIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#07AAEF"
      transform="translate(1 1)"
    >
      <circle cx="11.5" cy="11.5" r="11.5" strokeWidth="1.5"></circle>
      <path d="M10.733 4.6v7.34l6.134 3.393"></path>
    </g>
  </svg>
)

export const ChatIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="22"
    viewBox="0 0 23 22"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <path
        strokeWidth="1.5"
        d="M4.021 20.083l4.674-5.208h10.399a2.25 2.25 0 002.25-2.25V3a2.25 2.25 0 00-2.25-2.25H3A2.25 2.25 0 00.75 3v9.625A2.25 2.25 0 003 14.875h1.021v5.208z"
      ></path>
      <path strokeLinecap="round" d="M4 5.311h12.82M4 9.389h10.113"></path>
    </g>
  </svg>
)

export const ChangeAddressIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="24"
    viewBox="0 0 27 24"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#07AAEF"
      transform="translate(1 1)"
    >
      <path
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M19.097 22.16H4.098v-9.462H.008L11.503.558l5.969 6.267"
      ></path>
      <path strokeLinejoin="round" d="M8.856 21.951v-7.818h5.086"></path>
      <path d="M14.019 21.858v-7.004"></path>
      <path
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M19.167 21.658c3.833-4.016 5.75-7.083 5.75-9.2a5.75 5.75 0 00-11.5 0c0 2.117 1.916 5.184 5.75 9.2z"
      ></path>
      <circle cx="19.167" cy="12.458" r="1.917"></circle>
    </g>
  </svg>
)

export const ConsigneerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="24"
    viewBox="0 0 23 24"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <g strokeWidth="1.5" transform="translate(0 1)">
        <circle cx="11.5" cy="6.9" r="6.9"></circle>
        <path d="M.783 22.25h21.434a8.451 8.451 0 00-8.417-7.7H9.2a8.45 8.45 0 00-8.417 7.7z"></path>
      </g>
      <path
        strokeLinecap="round"
        d="M9.2 10.2c.728.767 1.494 1.15 2.3 1.15.806 0 1.572-.383 2.3-1.15"
      ></path>
    </g>
  </svg>
)

export const RecallIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="24"
    viewBox="0 0 26 24"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <path
        strokeLinecap="round"
        d="M23.554 9.33C21.804 3.225 15.435-.305 9.33 1.446S-.306 9.564 1.445 15.67a11.464 11.464 0 005.324 6.8"
      ></path>
      <path
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.66 6.634l1.308 1.399a1.69 1.69 0 01-.007 2.314l-.707.749c-.245.259-.333.63-.232.971.422 1.42 1.099 2.525 2.03 3.316.903.767 1.99 1.472 3.263 2.114h0a1 1 0 001.14-.17l.768-.733a1.78 1.78 0 012.498.043l1.612 1.646a2 2 0 01.084 2.708l-.863.997c-.42.486-1.049.74-1.689.683-3.403-.302-6.5-2.024-9.29-5.165-2.66-2.992-4.175-5.654-4.55-7.988h0a2.002 2.002 0 01.56-1.73l1.2-1.201a1.999 1.999 0 012.828-.002l.048.05z"
      ></path>
      <path
        strokeLinecap="round"
        d="M19.232 7.921l4.465 1.531L25.43 4.81"
      ></path>
    </g>
  </svg>
)

export const UpdateDayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="26"
    viewBox="0 0 25 26"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <path d="M3.26 12.413h18.13M3.26 15.674h18.13M3.26 18.935h18.13"></path>
      <path strokeLinecap="round" strokeWidth="1.5" d="M5.578 1v4.521"></path>
      <path
        strokeLinecap="square"
        d="M7.901 9.696v12.15m4.509-12.15v12.15m4.263-12.15v12.15"
      ></path>
      <path
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M10.496 1v4.521M15.004 1v4.521M19.922 1v4.521"
      ></path>
      <path strokeWidth="1.5" d="M.75 3.389h23.5V25.25H.75z"></path>
      <path d="M3.761 9.109h17.478v13.113H3.761z"></path>
    </g>
  </svg>
)

export const SenderIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="21"
    viewBox="0 0 25 21"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <circle cx="14.253" cy="10.168" r="9.418" strokeWidth="1.5"></circle>
      <path
        strokeLinecap="round"
        d="M9.371 8.235l5.215-2.998 5.157 2.998"
      ></path>
      <path
        strokeLinejoin="round"
        d="M10.383 7.735v6.596h8.479V7.735L14.673 5.19z"
      ></path>
      <path
        strokeLinejoin="round"
        d="M13.127 14.244V9.761h2.844v4.483"
      ></path>
      <path
        strokeLinecap="round"
        d="M.5 8.19H5m-3.5 3.262h2.817M3.5 14.507h1.833"
      ></path>
    </g>
  </svg>
)

export const CallIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <path
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M6.547 3.976l1.898 2.03c.72.772.717 1.971-.008 2.74l-.962 1.018a1 1 0 00-.234.961c.506 1.775 1.335 3.151 2.486 4.13 1.123.954 2.483 1.828 4.078 2.621h0a1 1 0 001.135-.172l1.138-1.086a2 2 0 012.81.048l2.316 2.365a2 2 0 01.084 2.708l-1.43 1.652a2.003 2.003 0 01-1.655.686c-4.207-.303-8.03-2.388-11.467-6.258-3.314-3.729-5.158-7.035-5.533-9.917h0a2 2 0 01.568-1.671l1.9-1.903a2 2 0 012.828-.002l.048.05z"
      ></path>
      <path
        strokeLinecap="round"
        d="M13.253 5.475c1.64.494 2.884 1.22 3.734 2.175.85.955 1.649 2.426 2.396 4.411M13.066 1c2.373.19 4.629 1.282 6.767 3.276 2.137 1.994 3.429 4.528 3.874 7.603"
      ></path>
    </g>
  </svg>
)

export const NeighbourIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="24"
    viewBox="0 0 27 24"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <path
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M1 5.773L9.601 1l8.503 4.773"
      ></path>
      <path
        strokeWidth="1.5"
        d="M3.004 4.856v18.363h21.048V12.995m-7.122-7.26v17.53"
      ></path>
      <path
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M17.25 12.868L20.587 11l5 2.757"
      ></path>
      <path
        strokeLinejoin="round"
        d="M6.888 23.08V14.5h5.972v8.58m6.012.141v-6.049h2.719v6.049"
      ></path>
    </g>
  </svg>
)

export const DayIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="24"
    viewBox="0 0 23 24"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF" strokeWidth="1.5">
      <path
        strokeLinecap="round"
        d="M5.152 1v4.16M9.676 1v4.16M13.824 1v4.16M18.348 1v4.16"
      ></path>
      <path d="M.75 3.258h21.5V23.25H.75z"></path>
    </g>
  </svg>
)

export const DigitalPersonalAssistantIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="23"
    viewBox="0 0 21 23"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#07AAEF"
      transform="translate(1)"
    >
      <rect
        width="11.656"
        height="8.995"
        x="3.602"
        y="4.924"
        strokeWidth="1.5"
        rx="4.497"
        transform="rotate(-90 9.43 9.422)"
      ></rect>
      <path d="M4.531 7.693H2.216A2.216 2.216 0 000 9.91h0c0 1.224.992 2.216 2.216 2.216H4.53"></path>
      <path d="M4.531 7.693H2.216A2.216 2.216 0 000 9.91h0c0 1.224.992 2.216 2.216 2.216H4.53M14.375 7.75h2.315c1.224 0 2.216.992 2.216 2.216h0a2.216 2.216 0 01-2.216 2.216h-2.315M17.188 7.5c0-3.797-3.428-6.875-7.657-6.875-4.228 0-7.656 3.078-7.656 6.875"></path>
      <path
        strokeWidth="1.5"
        d="M1.252 22.908v-1.09a5.785 5.785 0 015.786-5.785m10.622 6.842v-1.09A5.785 5.785 0 0011.875 16m-4.683-1.432v2.166A2.266 2.266 0 009.458 19h0a2.266 2.266 0 002.266-2.266v-2.166"
      ></path>
      <path strokeLinecap="round" d="M1.377 12.125v1.75"></path>
    </g>
  </svg>
)

export const OutsideDoorIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#07AAEF"
      transform="translate(1 1.25)"
    >
      <path
        strokeLinejoin="round"
        d="M3.072 23.131v-7.298h7.635v7.298"
      ></path>
      <path
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M0 23.391h23.978"
      ></path>
      <path strokeWidth="1.5" d="M8.333 15.453V0h14.063v22.919"></path>
      <path
        strokeLinejoin="round"
        d="M5.678 16.061v3.343l1.279-.908 1.195.908v-3.343"
      ></path>
      <path d="M10.417 15.769V2.065h10.104v20.992"></path>
      <circle cx="17.695" cy="11.875" r="1"></circle>
      <path d="M16.819 11.957h-1.715"></path>
    </g>
  </svg>
)

export const StoreIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="23"
    viewBox="0 0 25 23"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <path
        strokeWidth="1.5"
        d="M4.355 1.508h16.56c1.258 1.852 2.08 3.737 2.464 5.656.198.984-.042 2.001-.72 3.053v11.278H2.753V10.217c-1.151-.795-1.438-1.812-.863-3.053.575-1.241 1.397-3.126 2.465-5.656z"
      ></path>
      <path d="M8.519 21.451v-6.942h8.278v6.942m-4.192.138v-7.091"></path>
      <path
        strokeLinejoin="round"
        d="M12.722 1.908v7.059c-.402 1.446-1.214 2.17-2.437 2.17-1.222 0-2.12-.724-2.692-2.17-.691 1.042-1.591 1.563-2.7 1.563-1.11 0-1.928-.249-2.457-.747"
      ></path>
      <path d="M8.124 1.635l-.702 7.564"></path>
      <path
        strokeLinejoin="round"
        d="M12.5 8.919c.402 1.447 1.214 2.17 2.437 2.17 1.222 0 2.12-.723 2.692-2.17.634 1.447 1.505 2.17 2.614 2.17 1.11 0 1.995-.723 2.656-2.17"
      ></path>
      <path d="M16.923 1.861l.702 7.563"></path>
    </g>
  </svg>
)

export const TrackingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#07AAEF"
      transform="translate(1 1)"
    >
      <path
        strokeLinejoin="round"
        d="M1.704 19.9l5.119-6.309 6.171 6.309 4.419-5.304"
      ></path>
      <g transform="translate(13.59)">
        <path
          strokeWidth="1.5"
          d="M4.286 12.247C7.144 8.34 8.573 5.63 8.573 4.115 8.573 1.842 6.653 0 4.286 0S0 1.842 0 4.115C0 5.63 1.429 8.34 4.286 12.247z"
        ></path>
        <circle cx="4.286" cy="4.286" r="1.837"></circle>
      </g>
      <circle cx="1.568" cy="21.432" r="1.568"></circle>
      <circle cx="13.068" cy="21.432" r="1.568"></circle>
      <circle cx="6.795" cy="12.023" r="1.568"></circle>
      <path d="M16.883 12.509a1.568 1.568 0 002.017 2.402c.663-.556.663-1.783 0-2.402"></path>
    </g>
  </svg>
)

export const PickupPointIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="28"
    viewBox="0 0 23 28"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#07AAEF"
      transform="translate(1 1)"
    >
      <path
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.46 25.524c6.973-5.61 10.46-10.382 10.46-14.316 0-5.9-4.683-10.684-10.46-10.684C4.683.524 0 5.308 0 11.208c0 3.934 3.487 8.706 10.46 14.316z"
      ></path>
      <rect width="10.801" height="10.801" x="5.129" y="5.812" rx="1"></rect>
      <path
        strokeLinejoin="round"
        d="M8.723 5.618v4.859l1.805-1.366 1.796 1.366V5.618"
      ></path>
    </g>
  </svg>
)

export const PudoIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="21"
    viewBox="0 0 23 21"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#07AAEF"
      transform="translate(0 .009)"
    >
      <rect
        width="20.055"
        height="19.25"
        x="1.57"
        y="0.938"
        strokeWidth="1.5"
        rx="1"
      ></rect>
      <path
        strokeLinejoin="round"
        d="M8.298.726v8.543l3.297-2.402 3.28 2.402V.726"
      ></path>
    </g>
  </svg>
)

export const RealTimeMessagesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20"
    viewBox="0 0 24 20"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <path
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.836 17.513c5.984 0 10.836-3.696 10.836-8.256C22.672 4.697 17.82 1 11.836 1 5.85 1 1 4.697 1 9.257c0 1.976.911 3.79 2.43 5.211.471.44-1.195 4.81-1.195 4.81s3.863-2.997 4.461-2.75c1.53.628 3.28.985 5.14.985z"
      ></path>
      <path
        strokeLinecap="round"
        d="M5.711 8.776h11.352M5.711 11.558h11.352M5.952 6.059h6.654"
      ></path>
    </g>
  </svg>
)

export const VacationIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="25"
    viewBox="0 0 26 25"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <path
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M1.41 24.16h23.61"
      ></path>
      <path
        strokeWidth="1.5"
        d="M7.687 24.223c1.744-3.051 4.312-4.577 7.702-4.577 3.391 0 5.934 1.526 7.627 4.577"
      ></path>
      <path
        strokeLinejoin="round"
        d="M15.045 19.566c-1.26-5.325-2.89-8.654-4.886-9.987-1.996-1.333-3.574.62-4.734 5.86-1.459-2.335-1.755-4.289-.89-5.86 1.3-2.357-2.044 0-2.872 1.813C.834 13.205 1.114 6.75 5.06 6.75c.365 0 .126-.73 0-1.05-.265-.67-.782-1.487-.524-1.963.253-.467 1.194-.253 2.822.642.065-.764.237-1.35.515-1.761 1.024-1.512 2.99-1.512 3.412-1.512.422 0-.388.596-1.126 1.86-.594 1.017-1.062 1.769-.497 1.412 1.014-.642 3.793-2.157 6.361 0 2.569 2.156-5.863-.638-5.863 5.201"
      ></path>
      <path
        strokeLinejoin="round"
        d="M15.18 19.451l3.11-7.684c1.017-.616 2.026-.616 3.028 0 1 .617 1.908 1.452 2.721 2.507-.052-2.4-.821-4.023-2.309-4.868-.934-.53-.085-.657 1.36-.382.864.164 2.215 1.907 1.982 1.196-1.231-3.76-3.97-2.514-4.276-2.514-.307 0 0-2.404-.307-2.404-.204 0-.937.641-2.2 1.924-.437-.853-.898-1.279-1.38-1.279"
      ></path>
      <path d="M14.327 9.963c1.875-.32 3.171.302 3.888 1.868"></path>
    </g>
  </svg>
)

export const LockerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="23"
    viewBox="0 0 21 23"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <rect
        width="18.625"
        height="21.5"
        x="0.75"
        y="0.75"
        strokeWidth="1.5"
        rx="1"
      ></rect>
      <path d="M5.031 6.11h10.063M5.031 9.044h10.063m-6.709 8.642h4.529"></path>
      <circle cx="5.63" cy="14.255" r="1"></circle>
    </g>
  </svg>
)

export const ServiceLevelIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="20"
    viewBox="0 0 23 20"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <path
        strokeWidth="1.5"
        d="M21.083 14.95c0-6.812-4.287-11.117-9.576-11.117-5.29 0-9.873 4.495-9.577 11.117h19.153z"
      ></path>
      <path d="M11.487 3.942V1.067"></path>
      <path strokeWidth="1.5" d="M12.011 4.025V1.15"></path>
      <path d="M2.875 17.25v-1.917m17.25 1.917v-1.917"></path>
      <rect width="4.175" height="1" x="9.7" y="0.5" rx="0.5"></rect>
      <rect width="22" height="1" x="0.5" y="17.75" rx="0.5"></rect>
    </g>
  </svg>
)

export const ContactIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 27 27"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <path
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.583 15.52c5.293 0 9.584-3.25 9.584-7.26S15.877 1 10.583 1C5.291 1 1 4.25 1 8.26c0 1.738.806 3.333 2.15 4.582.416.387-1.058 4.23-1.058 4.23s3.416-2.636 3.946-2.42c1.353.554 2.9.868 4.545.868z"
      ></path>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M21.076 13.034c2.618.896 4.567 3.407 4.567 5.892 0 1.393-.73 2.675-1.949 3.679-.377.31.959 3.395.959 3.395s-3.097-2.116-3.577-1.942c-1.226.444-5.225.274-7.298-.59-2.073-.863-4.897-3.015-4.897-5.42"
      ></path>
      <path
        strokeLinecap="round"
        d="M6 7.59h10.04M6 10.09h10.04M6.213 5.211h5.885"
      ></path>
    </g>
  </svg>
)

export const CreditcardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="19"
    viewBox="0 0 23 19"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <rect
        width="21.23"
        height="17.393"
        x="0.75"
        y="0.75"
        strokeWidth="1.5"
        rx="2"
      ></rect>
      <path d="M.517 7.675h21.705M.517 4.383h21.705"></path>
      <rect width="2.286" height="2.286" x="2.964" y="13.643" rx="1"></rect>
    </g>
  </svg>
)

export const OtherIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#07AAEF"
      transform="translate(1 1)"
    >
      <circle cx="11.5" cy="11.5" r="11.5" strokeWidth="1.5"></circle>
      <path d="M11.5 5.75v12.047m6.024-6.023H5.476"></path>
    </g>
  </svg>
)

export const ResponseTimesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="24"
    viewBox="0 0 22 24"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#07AAEF"
      transform="translate(1 1)"
    >
      <circle cx="10" cy="12.5" r="6.5"></circle>
      <circle cx="10" cy="12.5" r="9.5" strokeWidth="1.5"></circle>
      <path
        strokeWidth="1.5"
        d="M2.196 6.497l-.627-.78a1.002 1.002 0 01.083-1.344l.7-.68a1 1 0 011.41.015l.67.681"
      ></path>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10 10v3h2.196"
      ></path>
      <path strokeLinecap="round" d="M3.523 12.563h1.5M15 12.25h1.5"></path>
      <path strokeWidth="1.5" d="M10.25 2.699V.5"></path>
      <path strokeLinecap="round" strokeWidth="1.5" d="M8.5.25H12"></path>
      <path strokeLinecap="round" d="M9.906 6.018v1.5m.313 9.977v1.5"></path>
    </g>
  </svg>
)

export const InvoiceIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="23"
    viewBox="0 0 17 23"
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#07AAEF"
        strokeWidth="1.5"
        d="M.75.75v19.042l1.621 1.466 1.864-2.266 2.123 2.246 2.044-2.248 1.908 2.241 2.172-2.205 1.95 2.207 1.35-1.41V.75H.75z"
      ></path>
      <path stroke="#07AAEF" d="M4.511 15.991h7.704"></path>
      <path
        fill="#0FACEF"
        d="M7.842 12.659a.233.233 0 01-.069-.18v-.737c-.492-.043-.915-.157-1.27-.343-.355-.186-.626-.418-.812-.696a1.708 1.708 0 01-.3-.89c0-.057.02-.106.063-.148a.215.215 0 01.158-.063h.506c.134 0 .225.07.274.21.063.296.244.55.543.765.299.214.726.321 1.28.321.598 0 1.044-.105 1.34-.316.295-.21.442-.503.442-.875a.83.83 0 00-.216-.59c-.144-.155-.358-.29-.643-.406a14.827 14.827 0 00-1.28-.427c-.542-.155-.974-.32-1.297-.496-.324-.175-.566-.391-.728-.648-.161-.256-.242-.581-.242-.975 0-.527.193-.97.58-1.328.386-.359.92-.57 1.602-.633v-.727c0-.077.023-.137.069-.18a.244.244 0 01.173-.063h.38a.22.22 0 01.169.069.248.248 0 01.063.174v.738c.45.056.836.182 1.16.38.323.196.567.426.732.69.165.263.255.529.269.796a.215.215 0 01-.221.221h-.527a.293.293 0 01-.164-.053.235.235 0 01-.1-.158c-.042-.302-.21-.552-.506-.748-.295-.197-.664-.295-1.107-.295-.478 0-.857.093-1.138.279-.282.186-.422.462-.422.827 0 .246.065.447.195.601.13.155.33.292.6.411.271.12.663.257 1.176.412.598.168 1.068.337 1.413.506.344.168.6.377.77.627.168.25.252.57.252.964 0 .598-.212 1.08-.637 1.445-.426.365-1.007.576-1.745.632v.727c0 .07-.021.129-.063.174a.22.22 0 01-.169.069h-.38a.244.244 0 01-.173-.063z"
      ></path>
    </g>
  </svg>
)

export const TrackIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="18"
    viewBox="0 0 26 18"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#07AAEF"
      transform="translate(0 1)"
    >
      <path d="M0 .25h5.772"></path>
      <circle cx="8.476" cy="13.238" r="2.524"></circle>
      <ellipse cx="20.382" cy="13.238" rx="2.525" ry="2.524"></ellipse>
      <path d="M1.37 2.982h3.572M2.525 5.343h2.381"></path>
      <path
        strokeWidth="1.5"
        d="M5.744 13.238H3.571L5.744 0h12.257l-1.934 13.238h-5.008m6.798-10.857h4.292c1.468.468 2.202 1.457 2.202 2.966 0 1.509-.142 4.14-.425 7.891h-1.777"
      ></path>
      <path d="M16.667 6.313h7.215"></path>
      <path strokeWidth="1.5" d="M11.31 13.354h7.142"></path>
    </g>
  </svg>
)

export const UserExpIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="25"
    viewBox="0 0 21 25"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M8.041 18.14V8.989c-.062-1.595.496-2.392 1.676-2.392s1.709.797 1.588 2.392v6.066c0-1.745.422-2.618 1.266-2.618.844 0 1.345.457 1.503 1.372.105-.915.62-1.372 1.547-1.372.926 0 1.389.457 1.389 1.372v1.246c.009-.83.467-1.246 1.375-1.246.908 0 1.362.621 1.362 1.863a15.258 15.258 0 01-.466 4.876c-.417 1.59-.716 2.635-.896 3.134H6.607L5.456 19.37c-.395-2.575.466-4.012 2.585-4.314"
      ></path>
      <path d="M1 3.988h12.916M1 18.284h4.45"></path>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M5.257 20.845H1V1h13.006v14.032"
      ></path>
    </g>
  </svg>
)

export const CashIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="23"
    viewBox="0 0 24 23"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#07AAEF"
      transform="translate(1 1)"
    >
      <path
        strokeWidth="1.5"
        d="M1.68 21.027v-6.02L.509 9.921 1.68 4.276c.164-1.28.719-1.786 1.664-1.516"
      ></path>
      <path
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M3.604 11.858V.197h18.481v11.211H9.305c.041-.45-.085-1.152-.379-2.104-.294-.952-.776-1.51-1.448-1.672l-2.782 4.226H3.604z"
      ></path>
      <path strokeWidth="1.5" d="M6.609 20.876v-6.363l2.662-4.134"></path>
      <circle cx="13.11" cy="5.75" r="2.07"></circle>
      <path
        strokeLinejoin="round"
        d="M6.681 8.517c-.115-.29-.219-.476-.31-.558-.092-.083-.264-.145-.516-.186V3.349c.735.032 1.216-.07 1.443-.308.227-.237.341-.488.341-.754h10.832c-.01.708.164 1.062.522 1.062h1.067v4.424c-.363-.22-.719-.22-1.067 0s-.522.695-.522 1.427H9.404"
      ></path>
    </g>
  </svg>
)

export const PlaneIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="22"
    viewBox="0 0 27 22"
  >
    <g fill="none" fillRule="evenodd" stroke="#07AAEF">
      <path
        strokeLinejoin="round"
        d="M3.656 8.777L2.5 4.368h1.103c1.044 0 2.013.543 2.558 1.434l1.822 2.975"
      ></path>
      <path
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M9.751 16.213c-3.557-.583-6.392-2.18-8.507-4.79v-.806a2 2 0 012-2h16.848c1.834.169 3.205.826 4.111 1.972.907 1.146 1.387 2.243 1.44 3.292-.25 1.555-.867 2.332-1.854 2.332h-9.157l2.872-2.332h-4.632a.995.995 0 00-.654.244l-7.3 6.316h2.893c.879 0 2.022-.514 3.43-1.543 1.41-1.028 2.54-1.923 3.391-2.685"
      ></path>
      <path d="M23.862 10.128h-2.998c-1.243-.09-1.864.355-1.864 1.335s.68 1.41 2.04 1.293h4.136"></path>
      <path
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16 8.59l7-7.473v2.33a5.999 5.999 0 01-1.659 4.142c-.315.331-.647.665-.995 1.001"
      ></path>
    </g>
  </svg>
)

export const Illustration = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="198"
    height="174"
    viewBox="0 0 198 174"
  >
    <g fill="none" fillRule="evenodd" transform="translate(1)">
      <g fill="#07AAEF" transform="translate(3 88)">
        <circle cx="7.5" cy="34.5" r="1"></circle>
        <circle cx="11.5" cy="36.5" r="1"></circle>
        <circle cx="11.5" cy="31.5" r="1"></circle>
        <circle cx="5.5" cy="36.5" r="1"></circle>
        <circle cx="3.5" cy="23.5" r="1"></circle>
        <circle cx="4.5" cy="27.5" r="1"></circle>
        <circle cx="0.5" cy="22.5" r="1"></circle>
        <circle cx="2.5" cy="19.5" r="1"></circle>
        <circle cx="1.5" cy="25.5" r="1"></circle>
        <circle cx="26.5" cy="19.5" r="1"></circle>
        <circle cx="24.5" cy="26.5" r="1"></circle>
        <circle cx="24.5" cy="26.5" r="1"></circle>
        <circle cx="26.5" cy="23.5" r="1"></circle>
        <circle cx="27.5" cy="27.5" r="1"></circle>
        <circle cx="21.5" cy="19.5" r="1"></circle>
        <circle cx="23.5" cy="16.5" r="1"></circle>
        <circle cx="23.5" cy="22.5" r="1"></circle>
        <circle cx="8.5" cy="38.5" r="1"></circle>
        <circle cx="7.5" cy="46.5" r="1"></circle>
        <circle cx="8.5" cy="52.5" r="1"></circle>
        <circle cx="3.5" cy="48.5" r="1"></circle>
        <circle cx="13.5" cy="50.5" r="1"></circle>
        <circle cx="19.5" cy="60.5" r="1"></circle>
        <circle cx="18.5" cy="68.5" r="1"></circle>
        <circle cx="26.5" cy="67.5" r="1"></circle>
        <circle cx="14.5" cy="55.5" r="1"></circle>
        <circle cx="3.5" cy="53.5" r="1"></circle>
        <circle cx="8.5" cy="58.5" r="1"></circle>
        <circle cx="29.5" cy="39.5" r="1"></circle>
        <circle cx="31.5" cy="44.5" r="1"></circle>
        <circle cx="30.5" cy="57.5" r="1"></circle>
        <circle cx="31.5" cy="64.5" r="1"></circle>
        <circle cx="24.5" cy="42.5" r="1"></circle>
        <circle cx="36.5" cy="43.5" r="1"></circle>
        <circle cx="35.5" cy="48.5" r="1"></circle>
        <circle cx="25.5" cy="58.5" r="1"></circle>
        <circle cx="25.5" cy="58.5" r="1"></circle>
        <circle cx="29.5" cy="62.5" r="1"></circle>
        <circle cx="32.5" cy="34.5" r="1"></circle>
        <circle cx="33.5" cy="39.5" r="1"></circle>
        <circle cx="28.5" cy="35.5" r="1"></circle>
        <circle cx="38.5" cy="37.5" r="1"></circle>
        <circle cx="26.5" cy="46.5" r="1"></circle>
        <circle cx="38.5" cy="19.5" r="1"></circle>
        <circle cx="41.5" cy="24.5" r="1"></circle>
        <circle cx="34.5" cy="22.5" r="1"></circle>
        <circle cx="35.5" cy="11.5" r="1"></circle>
        <circle cx="43.5" cy="19.5" r="1"></circle>
        <circle cx="38.5" cy="15.5" r="1"></circle>
        <circle cx="42.5" cy="13.5" r="1"></circle>
        <circle
          cx="38.5"
          cy="7.5"
          r="1"
          transform="rotate(-8 38.5 7.5)"
        ></circle>
        <circle
          cx="39.5"
          cy="3.5"
          r="1"
          transform="rotate(-8 39.5 3.5)"
        ></circle>
        <circle
          cx="41.5"
          cy="7.5"
          r="1"
          transform="rotate(-8 41.5 7.5)"
        ></circle>
        <circle
          cx="44.5"
          cy="1.5"
          r="1"
          transform="rotate(-8 44.5 1.5)"
        ></circle>
        <circle
          cx="45.5"
          cy="10.5"
          r="1"
          transform="rotate(-8 45.5 10.5)"
        ></circle>
        <circle cx="35.5" cy="25.5" r="1"></circle>
        <circle cx="31.5" cy="51.5" r="1"></circle>
      </g>
      <circle
        cx="63"
        cy="36"
        r="19"
        stroke="#07AAEF"
        strokeWidth="1.5"
      ></circle>
      <path
        stroke="#07AAEF"
        strokeWidth="1.5"
        d="M10.117 161c0-5.818 1.206-8.727 3.619-8.727 2.122 0-9.179 0-11.158-6.773C.6 138.727 3.845 130 10.117 130m-4.385 1c-1.543-.701-2.315-3.071-2.315-7.11 0-3.016 2.39-4.997 2.315-4.997-2.267 0-5.732-5.327-5.732-10.365 0-5.256 4.46-5.538 6.497-2.878S9.384 113.818 8.84 116"
      ></path>
      <path
        stroke="#07AAEF"
        strokeWidth="1.5"
        d="M8 116.794c2.1-2.007 4.184-2.33 6.253-.97 3.104 2.042 3.8 6.231 2.953 8.344-.847 2.112-1.967 4.837-2.953 5.506-.986.67 3.727.796 5.686 5.307 1.306 3.007 1.75 6.034 1.33 9.08 6.989-2.632 12.006-2.927 15.052-.885 3.045 2.043 3.494 5.613 1.347 10.708L32.522 160"
      ></path>
      <path
        stroke="#07AAEF"
        strokeWidth="1.5"
        d="M28.173 143c-3.963-5.493-5.104-10.27-3.42-14.33 2.524-6.091 6.085-8.87 10.595-8.87 0 0-3.832-11.786 1.475-23.577 5.308-11.791 8.398-11.625 10.738-10.99 2.341.634 5.404 12.055 1.745 22.512-2.44 6.97-5.141 11.938-8.105 14.902"
      ></path>
      <path
        stroke="#07AAEF"
        strokeWidth="1.5"
        d="M39.209 121c3.989 3.634 5.487 7.84 4.494 12.615-.992 4.776-3.227 8.238-6.703 10.385m-8.126-22c-3.795-1.951-6.003-5.19-6.625-9.714-.933-6.787.884-10.547 3.312-11.2 2.43-.651 7.046 2.352 8.045 9.45.667 4.73.487 7.66-.539 8.785"
      ></path>
      <path
        stroke="#07AAEF"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M4 160.5h92.73"
      ></path>
      <path
        stroke="#07AAEF"
        d="M24 34h15M22 60l20-13M29 8l15 12M103 34H88M44.066.244l7.35 12.729m32.092 55.182L76.16 55.426M83.45.541L76.1 13.27M44.359 68.655l7.349-12.73M106 60L86 47M101 8L86 20M63 14V4m0 54v12m44 96h45"
      ></path>
      <path stroke="#07AAEF" strokeWidth="1.5" d="M94 172.833h102.624"></path>
      <path stroke="#07AAEF" d="M47 165.25h45m-64 5h45"></path>
      <g stroke="#07AAEF">
        <path
          strokeWidth="1.5"
          d="M160 172V58.035c0-10.51.304-32.035 7.069-32.035 4.51 0 6.983 11.735 7.42 35.204L176 172"
        ></path>
        <path d="M165 172l-1-102m3-24l2 127m2-114l3 114"></path>
        <path
          strokeWidth="1.5"
          d="M160 108.973c-11.083.41-17.651-3.819-19.705-12.687-3.08-13.304-4.192-37.486-2.515-41.822 1.677-4.338 6.7-2.886 8.755 3.87C148.59 65.09 150.57 87.232 160 89.3"
        ></path>
        <path d="M143 60c1.583 15.132 3.916 24.132 7 27m-9-16c2.499 15.523 8.832 24.523 19 27m-18-11c1.319 8.19 5.652 13.19 13 15"></path>
        <path
          strokeWidth="1.5"
          d="M176 91.778c2.553.124 3.83-6.036 3.83-18.48 0-18.668 1.093-25.298 6.74-25.298 5.649 0 7.43 28.152 7.43 35.006 0 6.854-.868 29.741-18 35.994"
        ></path>
        <path d="M177 113c5.753-.265 9.753-4.932 12-14m-5 1c3.556-6.276 4.74-17.61 3.556-34M177 100c6.48-2.224 9.024-11.558 7.63-28"></path>
      </g>
    </g>
  </svg>
)

export const ArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="21" viewBox="0 0 11 21">
    <g fill="none" fillRule="evenodd" strokeLinecap="round">
        <g stroke="#979797" strokeWidth="2">
            <path d="M40 41L48 50.957 40 60" transform="translate(-39 -40)"/>
        </g>
    </g>
  </svg>
)

export const ArrowIconSelect = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 16">
    <g fill="none" fillRule="evenodd" strokeLinecap="round">
        <g stroke="#46474c" strokeWidth="2">
            <path d="M24 46L32 53.337 24 60" transform="translate(-22 -45) rotate(-180 28 53)"/>
        </g>
    </g>
  </svg>
)

export const ArrowHeaderIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="11" height="16" viewBox="0 0 11 16">
    <g fill="none" fillRule="evenodd" strokeLinecap="round">
        <g stroke="#46474C" strokeWidth="2">
            <path d="M24 46L32 53.337 24 60" transform="translate(-22 -45) rotate(-180 28 53)"/>
        </g>
    </g>
  </svg>
)

export const PlusIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    fill="currentColor"
    enableBackground="new 0 0 512 512"
    version="1.1"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <path d="M492 236H276V20c0-11.046-8.954-20-20-20s-20 8.954-20 20v216H20c-11.046 0-20 8.954-20 20s8.954 20 20 20h216v216c0 11.046 8.954 20 20 20s20-8.954 20-20V276h216c11.046 0 20-8.954 20-20s-8.954-20-20-20z"></path>
  </svg>
)

export const TrackPin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="38"
    height="52"
    viewBox="0 0 38 52"
  >
    <defs>
      <filter
        id="e5l2t190sa"
        width="146.7%"
        height="131.6%"
        x="-23.3%"
        y="-11.3%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        ></feGaussianBlur>
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.426177536 0"
        ></feColorMatrix>
      </filter>
      <path
        id="7521w6kjfb"
        d="M15 44.269C25 30.279 30 20.523 30 15c0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 5.523 5 15.28 15 29.269z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(4 2)">
        <use
          fill="#000"
          filter="url(#e5l2t190sa)"
          xlinkHref="#7521w6kjfb"
        ></use>
        <use fill="currentColor" xlinkHref="#7521w6kjfb"></use>
      </g>
      <g stroke="#FFF" transform="translate(8 10)">
        <path d="M0 .25h4.848"></path>
        <circle cx="7.12" cy="11.12" r="2.12"></circle>
        <ellipse cx="17.121" cy="11.12" rx="2.121" ry="2.12"></ellipse>
        <path d="M1.152 2.505h3M2.121 4.488h2m.704 6.632H3L4.825 0h10.296l-1.625 11.12H9.289M15 2h3.605c1.233.393 1.85 1.224 1.85 2.491 0 1.268-.12 3.477-.357 6.629h-1.493M14 5.303h6.06M9.5 11.217h6"></path>
      </g>
    </g>
  </svg>
)

export const PudoPin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="33"
    height="45"
    viewBox="0 0 33 45"
  >
    <defs>
      <filter
        id="kddtz1voza"
        width="156%"
        height="137.5%"
        x="-28%"
        y="-13.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        ></feGaussianBlur>
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.426177536 0"
        ></feColorMatrix>
      </filter>
      <path
        id="4b4c2o2x0b"
        d="M12.5 37.383C20.833 25.569 25 17.33 25 12.667 25 5.67 19.404 0 12.5 0S0 5.671 0 12.667c0 4.663 4.167 12.902 12.5 24.716z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(4 2)">
        <use
          fill="#000"
          filter="url(#kddtz1voza)"
          xlinkHref="#4b4c2o2x0b"
        ></use>
        <use fill="currentColor" xlinkHref="#4b4c2o2x0b"></use>
      </g>
      <g stroke="#FFF" transform="translate(9 8.756)">
        <rect
          width="12.558"
          height="12.033"
          x="1.285"
          y="0.873"
          strokeWidth="1.5"
          rx="1"
        ></rect>
        <path
          strokeLinejoin="round"
          d="M5.411.474v5.571l2.151-1.566 2.139 1.566V.474"
        ></path>
      </g>
    </g>
  </svg>
)

export const LockerPin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="33"
    height="45"
    viewBox="0 0 33 45"
  >
    <defs>
      <filter
        id="eq41379vpa"
        width="156%"
        height="137.5%"
        x="-28%"
        y="-13.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        ></feGaussianBlur>
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.426177536 0"
        ></feColorMatrix>
      </filter>
      <path
        id="ia2dr2pyrb"
        d="M12.5 37.383C20.833 25.569 25 17.33 25 12.667 25 5.67 19.404 0 12.5 0S0 5.671 0 12.667c0 4.663 4.167 12.902 12.5 24.716z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(4 2)">
        <use
          fill="#000"
          filter="url(#eq41379vpa)"
          xlinkHref="#ia2dr2pyrb"
        ></use>
        <use fill="currentColor" xlinkHref="#ia2dr2pyrb"></use>
      </g>
      <g stroke="#FFF" transform="translate(10 7)">
        <rect
          width="12.5"
          height="14.5"
          x="0.75"
          y="0.75"
          strokeWidth="1.5"
          rx="1"
        ></rect>
        <path d="M3.5 4.25h7m-7 2.042h7m-4.667 6.011h3.15"></path>
        <circle cx="3.917" cy="9.917" r="1"></circle>
      </g>
    </g>
  </svg>
)

export const StorePin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="33"
    height="45"
    viewBox="0 0 33 45"
  >
    <defs>
      <filter
        id="zqd6q1ftoa"
        width="156%"
        height="137.5%"
        x="-28%"
        y="-13.4%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        ></feGaussianBlur>
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.426177536 0"
        ></feColorMatrix>
      </filter>
      <path
        id="x3v2n171hb"
        d="M12.5 37.383C20.833 25.569 25 17.33 25 12.667 25 5.67 19.404 0 12.5 0S0 5.671 0 12.667c0 4.663 4.167 12.902 12.5 24.716z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(4 2)">
        <use
          fill="#000"
          filter="url(#zqd6q1ftoa)"
          xlinkHref="#x3v2n171hb"
        ></use>
        <use fill="currentColor" xlinkHref="#x3v2n171hb"></use>
      </g>
      <g stroke="#FFF">
        <path
          strokeWidth="1.5"
          d="M11.188 9.352h10.8c.82 1.183 1.356 2.388 1.607 3.614.129.63-.028 1.28-.47 1.951v7.209H10.144v-7.209c-.751-.507-.938-1.157-.564-1.95.374-.794.91-1.999 1.607-3.615z"
        ></path>
        <path d="M13.904 22.098v-4.437h5.398v4.437m-2.734.088v-4.532"></path>
        <path
          strokeLinejoin="round"
          d="M16.645 9.607v4.513c-.262.924-.792 1.386-1.59 1.386-.797 0-1.382-.462-1.755-1.386-.451.666-1.038.999-1.761.999-.723 0-1.258-.16-1.603-.477"
        ></path>
        <path d="M13.646 9.433l-.458 4.834"></path>
        <path
          strokeLinejoin="round"
          d="M16.5 14.088c.262.925.792 1.387 1.59 1.387.796 0 1.382-.462 1.755-1.387.414.925.982 1.387 1.705 1.387.723 0 1.3-.462 1.732-1.387"
        ></path>
        <path d="M19.385 9.577l.457 4.834"></path>
      </g>
    </g>
  </svg>
)

export const AddressPin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="28"
    height="37"
    viewBox="0 0 28 37"
  >
    <defs>
      <filter
        id="5a2bp0uppa"
        width="170%"
        height="147.4%"
        x="-35%"
        y="-16.9%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        ></feGaussianBlur>
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        ></feColorMatrix>
      </filter>
      <path
        id="dfvey8i5sb"
        d="M10 29.513C16.667 20.186 20 13.682 20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 3.682 3.333 10.186 10 19.513z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(4 2)">
        <use
          fill="#000"
          xlinkHref="#dfvey8i5sb"
        ></use>
        <use fill="#25262A" xlinkHref="#dfvey8i5sb"></use>
      </g>
      <g stroke="#FFF">
        <path
          strokeLinecap="round"
          d="M7.587 9.277l6.528-3.753 6.454 3.753"
        ></path>
        <path
          strokeLinejoin="round"
          d="M8.854 8.651v8.256h10.612V8.651l-5.242-3.185z"
        ></path>
        <path
          strokeLinejoin="round"
          d="M12.288 16.798v-5.612h3.56v5.612"
        ></path>
      </g>
    </g>
  </svg>
)

export const HomePin = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="28"
    height="37"
    viewBox="0 0 28 37"
  >
    <defs>
      <filter
        id="ahet3zgxda"
        width="170%"
        height="147.4%"
        x="-35%"
        y="-16.9%"
        filterUnits="objectBoundingBox"
      >
        <feOffset
          dy="2"
          in="SourceAlpha"
          result="shadowOffsetOuter1"
        ></feOffset>
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="2"
        ></feGaussianBlur>
        <feColorMatrix
          in="shadowBlurOuter1"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
        ></feColorMatrix>
      </filter>
      <path
        id="5w66bslvlb"
        d="M10 29.513C16.667 20.186 20 13.682 20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 3.682 3.333 10.186 10 19.513z"
      ></path>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g transform="translate(4 2)">
        <use
          fill="#000"
          filter="url(#ahet3zgxda)"
          xlinkHref="#5w66bslvlb"
        ></use>
        <use fill="#25262A" xlinkHref="#5w66bslvlb"></use>
      </g>
      <g stroke="#FFF">
        <path
          strokeLinecap="round"
          d="M7.587 9.277l6.528-3.753 6.454 3.753"
        ></path>
        <path
          strokeLinejoin="round"
          d="M8.854 8.651v8.256h10.612V8.651l-5.242-3.185z"
        ></path>
        <path
          strokeLinejoin="round"
          d="M12.288 16.798v-5.612h3.56v5.612"
        ></path>
      </g>
    </g>
  </svg>
)

export const CheckIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
  >
    <path
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M1 5.195l5.106 5.307L11.775 1"
    ></path>
  </svg>
)
