import styled from 'styled-components'
import { Tabs as TabsAntd } from 'antd'

import { convertColor } from '../../../utils'

export const Tabs = styled(TabsAntd)`
  margin-top: 1.6rem;
  width: calc(100% + 2rem);
  margin-left: -1rem;

  .ant-tabs-nav {
    border-top: .1rem solid ${({ theme }) => theme.colors.border};
    border-bottom: .1rem solid ${({ theme }) => theme.colors.border};
    margin-bottom: 0;
  }

  .ant-tabs-nav-list {
    width: 100%;
    justify-content: center;
  }

  .ant-tabs-tab:hover {
    color: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue};
  }

  .ant-tabs-ink-bar {
    /* background: ${({ theme, ContentIconsColor }) => ContentIconsColor ? convertColor(ContentIconsColor) : theme.colors.blue}; */
    background: #46474c;
  }

  .ant-tabs-tab {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
  }

  .ant-tabs-tab-btn {
    font-size: 1.4rem;
    font-weight: 600;
    line-height: 2.4rem;
    color: #979797;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-family: OpenSans;
    color: #46474c;
    font-weight: 600;

    [dir='rtl'] & {
      font-family: 'OpenSansHebrew';
    }
  }
`
