const colors = {
  white: '#FFFFFF',
  darkWhite: '#f5f5f5',
  gray: '#46474c',
  lightGray: '#89888d',
  black: '#25262a',
  blue: '#07aaef',
  border: '#e5e5e5',
}

const media = {
  mobile: '(min-width: 480px)',
  tablet: '(max-width: 768px)',
  laptops: '(max-width: 992px)',
  dekstop: '(max-width: 1200px)',
}

const maxWidth = '48rem'

const boxShadow = '0 2px 4px 0 rgba(0, 0, 0, 0.2)'

const transition = '.2s cubic-bezier(.25, .1, .25, 1)'

export const theme = {
  colors,
  media,
  boxShadow,
  transition,
  maxWidth,
}
