import React, { Fragment, useState, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { PickupPoint } from '../../blocks'

export default ({
  ...props
}) => {
  const [selectedPoint, setSelectedPoint] = useState(null)

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    window.scrollTo(0, 0)

    return () => document.body.style.overflow = 'auto'
  }, [])
  return (
    <Fragment>
      <Route
        render={({ location, match: { params: { tid, pid, id } } }) => (
          <Switch location={location}>
            <Route path={`/:tid/:pid/${id}`} component={() => <PickupPoint.Select
              setSelectedPoint={setSelectedPoint}
              link={location.pathname}
              {...props}
            />} exact />
            <Route path={`/:tid/:pid/${id}/details`} component={() => selectedPoint ? <PickupPoint.Details
              selectedPoint={selectedPoint}
              setSelectedPoint={setSelectedPoint}
              {...props}
            /> : <Redirect to={`/${tid}/${pid}/${id}`} />} />
          </Switch>
        )}
      />
    </Fragment>
  )
}
