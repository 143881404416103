import styled from 'styled-components'

import { convertColor } from '../../../../utils'

export const OpeningItem = styled.div`
  width: 100%;
  min-height: 4.6rem;
  display: flex;
  justify-content: space-between;
  margin-top: .6rem;
  border-bottom: .1rem solid ${({ theme }) => theme.colors.border};

  .day {
    color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-weight: 600;
  }

  .times {
    display: flex;
    flex-direction: column;

    span {
      color: ${({ theme, ContentTextColor }) => ContentTextColor ? convertColor(ContentTextColor) : theme.colors.gray};
      font-size: 1.2rem;
      line-height: 1.8rem;
      text-align: right;
    }
  }
`