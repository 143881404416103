import React, { useContext } from 'react'
import { useIntl } from 'react-intl'

import { DeliveryStatus } from './styles'
import { ThemeContext } from '../../../utils'

const STEPS = Array.from({ length: 4 }, () => undefined)

const STATUS = {
  0: 'Picked up',
  1: 'Ready for delivery',
  2: 'On the way',
  3: 'Delivered',
}

export default ({
  step = 2,
  sender = '',
  trackingNumber = '',
  ...props
}) => {
  const { ContentIconsColor, ContentTextColor } = useContext(ThemeContext)

  const intl = useIntl()

  return (
    <DeliveryStatus>
      <DeliveryStatus.Sender
        ContentIconsColor={ContentIconsColor}
        ContentTextColor={ContentTextColor}
        {...props}
      >
        <div className='icon'>&#xe912;</div>
        <div className='info'>
          <div className='name'>{sender}</div>
          <div className='number'>{trackingNumber}</div>
        </div>
      </DeliveryStatus.Sender>

      <DeliveryStatus.Step>
        {STEPS.map((item, key) => (
          <DeliveryStatus.StepItem
            key={key}
            _done={key <= step - 1}
            _active={key === step - 1}
            _first={key === 0}
            _last={key + 1 === STEPS.length}
            ContentIconsColor={ContentIconsColor}
            ContentTextColor={ContentTextColor}
            {...props}
          >
            <div className='dot' />
            <div className='status'>
              {intl.formatMessage(
                {
                  id: STATUS[key],
                  defaultMessage: STATUS[key]
                },
              )}
            </div>
          </DeliveryStatus.StepItem>
        ))}
      </DeliveryStatus.Step>
    </DeliveryStatus>
  )
}
