import React from 'react'

import { Separator } from './styles'

export default ({
  color = false,
  text = '',
  _marginTop = 0,
  _marginBottom = 0,
  ...props
}) => {
  return (
    <Separator
      _marginTop={_marginTop}
      _marginBottom={_marginBottom}
      _color={color}
      {...props}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  )
}
